import { useSelector } from "react-redux";
import "./style.css";
import { useEffect, useState } from "react";
import Header from "../../components/header";
import HelpContent from "./HelpContent";
import HelpExploreSearch from "./HelpExploreSearch";
import NotLoggedInExploreSearch from "./NotLoggedInExploreSearch";
import NotLoggedInHeader from "../../components/header/NotLoggedInHeader";
import CircleMenu from "../../components/navigation/CircleMenu";
import BottomMenu from "../../components/navigation/BottomMenu";
import { Helmet } from "react-helmet";

export default function Help({ setVisible }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);
  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };
  return (
    <div>
      <Helmet>
        <title>Namrolink - Help</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Help"
        />
      </Helmet>
      {user ? (
        <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      ) : (
        <NotLoggedInHeader />
      )}
      <div className="help-page-main">
        <div className="help-page-element_container">
          <div className="help-page-main-center">
            {user ? <HelpExploreSearch /> : <NotLoggedInExploreSearch />}
            <HelpContent />
          </div>
        </div>
      </div>
      <div className="extras-options-home-page">
        <BottomMenu />
      </div>
      <div className="extras-options-circle-home-page">
        <CircleMenu />
      </div>
    </div>
  );
}
