import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MessageUpIcon from "../../components/icons/MessageUpIcon";
import AboutConnectHelp from "./AboutConnectHelp";
import FAQServices from "./FAQServices";
import HelpBottomSection from "./HelpBottomSection";
import ImagesPreviewBoxes from "./ImagesPreviewBoxes";

export default function HelpContent() {
  return (
    <div>
      <AboutConnectHelp />
      <FAQServices />
      <ImagesPreviewBoxes />
      <HelpBottomSection />
    </div>
  );
}
