import { useState } from "react";
import Moment from "react-moment";

export default function Comment({ comment, userId, postUserId, onDelete }) {
  const isOwner = comment.commentBy._id === userId;
  return (
    <div className="u-comment">
      <img src={comment.commentBy.picture} alt="" className="comment_img" />
      <div className="comment_col">
        <div className="comment_wrap">
          <div className="comment_name">
            {comment.commentBy.first_name} {comment.commentBy.last_name}
          </div>
          <div className="comment_text">{comment.comment}</div>
        </div>
        {comment.image && (
          <img src={comment.image} alt="" className="comment_image" />
        )}
        <div className="comment_actions">
          <span>
            <Moment fromNow interval={30}>
              {comment.commentAt}
            </Moment>
          </span>
          {isOwner && (
            <span className="text-muted delete-comment-s" onClick={onDelete}>
              Delete
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
