import "../../styles/mediaquery.css";
import "../../styles/theme.css";
import { useEffect, useState } from "react";
import CloseIcon from "../icons/CloseIcon";

export default function ThemeCustomization({ onClose }) {
  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontSize") || "16px"
  );
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primaryColor") || "252"
  );
  const [buttonColor, setButtonColor] = useState(
    localStorage.getItem("buttonColor") || "252"
  );
  const [background, setBackground] = useState(
    JSON.parse(localStorage.getItem("background")) || [
      ["--color-card", "100%"],
      ["--color-bg", "95%"],
      ["--color-font", "17%"],
    ]
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className === "customize-theme") {
        onClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
    localStorage.setItem("fontSize", fontSize);
  }, [fontSize]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color-hue",
      primaryColor
    );
    localStorage.setItem("primaryColor", primaryColor);
  }, [primaryColor]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--btn-primary-color-hue",
      buttonColor
    );
    localStorage.setItem("buttonColor", buttonColor);
  }, [buttonColor]);

  useEffect(() => {
    background.forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
    localStorage.setItem("background", JSON.stringify(background));
  }, [background]);

  const handleFontSizeChange = (size) => {
    setFontSize(`${size}px`);
  };

  const handlePrimaryColorChange = (hue) => {
    setPrimaryColor(hue);
  };

  const handleButtonColorChange = (hue) => {
    setButtonColor(hue);
  };

  const handleBackgroundChange = (rootVars, colors) => {
    const newBackground = rootVars.map((variable, idx) => [
      variable,
      colors[idx],
    ]);
    setBackground(newBackground);
  };

  return (
    <div className="customize-theme">
      <style jsx>{`
        .customize-theme {
          display: grid;
        }
        /* =============================================
MEDIA QUERY FOR SMALL LAPTOPS AND BIG TABLETS
================================================ */

        @media screen and (max-width: 1200px) {
          .element_container {
            width: 96%;
          }

          main .element_container {
            grid-template-columns: 5rem auto 30vw;
            gap: 1rem;
          }

          .left {
            width: 5rem;
            z-index: 5;
          }

          main .element_container .left .profile {
            display: none;
          }

          .sidebar h3 {
            display: none;
          }

          .left .btn {
            display: none;
          }

          .customize-theme .card-container {
            width: 65%;
          }
        }

        /* =============================================
MEDIA QUERY FOR SMALL TABLETS AND MOBILE PHONES
================================================ */
        @media screen and (max-width: 992px) {
          nav .search-bar {
            display: none;
          }

          main .element_container {
            grid-template-columns: 0 auto 5rem;
            gap: 0;
          }

          main .element_container .left {
            grid-column: 3/4;
            position: fixed;
            bottom: 0;
            right: 0;
          }

          #explore-search.activeitem {
            display: grid;
          }

          /* ----------NOTIFICATION POPUP------------ */
          .left .notifications-popup {
            position: absolute;
            left: -21rem;
            width: 20rem;
          }

          .left .notifications-popup:before {
            position: absolute;
            top: 1.3rem;
            left: calc(20rem - 0.6rem);
            display: block;
          }

          main .element_container .middle {
            display: none;
          }

          main .element_container .middle.activeitem {
            display: block;
            grid-column: 1/3;
          }

          main .element_container .right {
            display: none;
          }

          main .element_container .right.activeitem {
            display: block;
            grid-column: 1/3;
          }

          .customize-theme .card-container {
            width: 80%;
          }
        }

        @media screen and (max-width: 580px) {
          main .element_container {
            display: block;
          }

          main .element_container .left {
            translate: 5rem 0;
            transition: translate 0.5s ease;
          }

          main .element_container .left.activeitem {
            translate: 0;
          }

          main .element_container .left .menu-toggle {
            display: flex;
          }

          .left .notifications-popup {
            left: -17rem;
            width: 16rem;
          }

          .left .notifications-popup:before {
            left: calc(16rem - 0.6rem);
          }

          .customize-theme .card-container {
            width: 90%;
            padding: 1.5rem;
          }

          .customize-theme .card-container .font-size {
            margin-top: 2rem;
          }

          .customize-theme .background .choose-bg {
            margin-top: 0.3rem;
          }

          .customize-theme .background .choose-bg > div {
            flex-direction: column;
          }

          .customize-theme .background .choose-bg > div > span {
            margin: 0;
            margin-bottom: 0.4rem;
          }
        }
      `}</style>
      <div className="card-container">
        <div className="close-theme" onClick={onClose}>
          <i className="uil uil-multiply close-theme-window">
            <CloseIcon />
          </i>
        </div>
        <h2>Customize your view</h2>
        <p className="text-muted">
          Manage your font size, color, and background.
        </p>

        <div className="font-size">
          <h4>Font Size</h4>
          <div>
            <h6>Aa</h6>
            <div className="choose-size">
              {[14, 16, 18, 20, 22].map((size) => (
                <span
                  key={size}
                  onClick={() => handleFontSizeChange(size)}
                  className={`font-size-${size / 2} ${
                    fontSize === `${size}px` ? "activeitem" : ""
                  }`}
                ></span>
              ))}
            </div>
            <h3>Aa</h3>
          </div>
        </div>

        <div className="color">
          <h4>Page Color</h4>
          <div className="choose-color" id="choose-primary-color">
            {[252, 52, 352, 152, 202].map((hue, idx) => (
              <span
                key={hue}
                onClick={() => handlePrimaryColorChange(hue)}
                className={`color-${idx + 1} ${
                  primaryColor === `${hue}` ? "activeitem" : ""
                }`}
              ></span>
            ))}
          </div>
        </div>

        <div className="color" id="btnPrimaryColor">
          <h4>Button Color</h4>
          <div className="choose-color" id="choose-btn-color">
            {[252, 52, 352, 152, 202].map((hue, idx) => (
              <span
                key={hue}
                onClick={() => handleButtonColorChange(hue)}
                className={`color-${idx + 1} ${
                  buttonColor === `${hue}` ? "activeitem" : ""
                }`}
              ></span>
            ))}
          </div>
        </div>

        <div className="background">
          <h4>Background</h4>
          <div className="choose-bg">
            {[
              ["100%", "95%", "17%"],
              ["20%", "15%", "90%"],
              ["10%", "0%", "90%"],
            ].map((colors, idx) => (
              <div
                key={idx}
                onClick={() =>
                  handleBackgroundChange(
                    ["--color-card", "--color-bg", "--color-font"],
                    colors
                  )
                }
                className={`bg-${idx + 1} ${
                  JSON.stringify(background.map((item) => item[1])) ===
                  JSON.stringify(colors)
                    ? "activeitem"
                    : ""
                }`}
              >
                <span></span>
                <h5>{idx === 0 ? "Light" : idx === 1 ? "Dim" : "Dark"}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
