export default function NewspaperSolidIcon({ className = "w-6 h-6" }) {
  return (
    <svg viewBox="0 0 512 512" fill="currentColor" className={className}>
      <path
        fill="none"
        d="M112 112 H176 A16 16 0 0 1 192 128 V192 A16 16 0 0 1 176 208 H112 A16 16 0 0 1 96 192 V128 A16 16 0 0 1 112 112 z"
      />
      <path d="M468 112h-52v304a32 32 0 0032 32 32 32 0 0032-32V124a12 12 0 00-12-12z" />
      <path d="M431.15 477.75A64.11 64.11 0 01384 416V44a12 12 0 00-12-12H44a12 12 0 00-12 12v380a56 56 0 0056 56h342.85a1.14 1.14 0 00.3-2.25zM96 208v-96h96v96zm224 192H96v-32h224zm0-64H96v-32h224zm0-64H96v-32h224zm0-64h-96v-32h96zm0-64h-96v-32h96z" />
    </svg>
  );
}
