import { useRef, useState } from "react";
import ProfilePicture from "../../components/profielPicture";
import Friendship from "./Friendship";
import { Link } from "react-router-dom";
import CameraSolidIcon from "../../components/icons/CameraSolidIcon";
import UserTileCard from "../../components/UserTiles/UserTileCard";

export default function ProfielPictureInfos({
  profile,
  visitor,
  photos,
  othername,
}) {
  const [show, setShow] = useState(false);
  const pRef = useRef(null);
  const [userTileVisible, setUserTileVisible] = useState(false);

  return (
    <div className="profile-image-wrap">
      {show && <ProfilePicture setShow={setShow} pRef={pRef} photos={photos} />}
      <div className="profile-w-left">
        <div className="profile-w-img">
          <div
            className="profile-w-bg"
            ref={pRef}
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${profile.picture})`,
            }}
          >
            {!visitor && (
              <div className="profile-circle" onClick={() => setShow(true)}>
                <i className="camera-filled-icon">
                  <CameraSolidIcon />
                </i>
              </div>
            )}
          </div>
        </div>
        <div className="profile-w-col">
          <div className="profile-name">
            {profile.first_name} {profile.last_name}
          </div>
          <div className="othername othername-a-s padding-b-p-p-s">
            {othername && `(${othername})`}
          </div>

          {!visitor && (
            <div className="profile-page-count-p-s">
              <div className="profile_friend_count">
                {profile?.followers && (
                  <Link to="/discover/followers">
                    {profile?.followers.length === 0 ? (
                      ""
                    ) : profile?.followers.length === 1 ? (
                      <div className="profile_card_f_count">
                        1 follower&nbsp;
                      </div>
                    ) : (
                      <div className="profile_card_f_count">
                        {profile?.followers.length} followers&nbsp;
                      </div>
                    )}
                  </Link>
                )}
              </div>

              <div className="profile_friend_count">
                {profile?.following && (
                  <Link to="/discover/following">
                    {profile?.following.length === 0 ? (
                      ""
                    ) : profile?.following.length === 1 ? (
                      <div className="profile_card_f_count">
                        &nbsp;1 following
                      </div>
                    ) : (
                      <div className="profile_card_f_count">
                        &nbsp;{profile?.following.length} followings
                      </div>
                    )}
                  </Link>
                )}
              </div>

              <div className="profile_friend_count">
                {profile?.friends && (
                  <Link to="/friends/all">
                    {profile?.friends.length === 0 ? (
                      ""
                    ) : profile?.friends.length === 1 ? (
                      <div className="profile_card_f_count">1 friend</div>
                    ) : (
                      <div className="profile_card_f_count">
                        {profile?.friends.length} friends
                      </div>
                    )}
                  </Link>
                )}
              </div>
            </div>
          )}

          {visitor && (
            <div className="profile-page-count-p-s">
              <div className="profile_friend_count">
                {profile?.followers && (
                  <div>
                    {profile?.followers.length === 0 ? (
                      ""
                    ) : profile?.followers.length === 1 ? (
                      <div className="profile_card_f_count">
                        1 follower&nbsp;
                      </div>
                    ) : (
                      <div className="profile_card_f_count">
                        {profile?.followers.length} followers&nbsp;
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="profile_friend_count">
                {profile?.following && (
                  <div>
                    {profile?.following.length === 0 ? (
                      ""
                    ) : profile?.following.length === 1 ? (
                      <div className="profile_card_f_count">
                        &nbsp;1 following
                      </div>
                    ) : (
                      <div className="profile_card_f_count">
                        &nbsp;{profile?.following.length} followings
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="profile_friend_count">
                {profile?.friends && (
                  <div>
                    {profile?.friends.length === 0 ? (
                      ""
                    ) : profile?.friends.length === 1 ? (
                      <div className="profile_card_f_count">1 friend</div>
                    ) : (
                      <div className="profile_card_f_count">
                        {profile?.friends.length} friends
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="profile_friend_imgs">
            {profile?.friends &&
              profile.friends.slice(0, 6).map((friend, i) => (
                <Link to={`/profile/${friend.username}`} key={i}>
                  <img src={friend.picture} alt="" />
                </Link>
              ))}
          </div>
          {visitor ? (
            <Friendship
              friendshipp={profile?.friendship}
              profileid={profile._id}
            />
          ) : (
            <div className="profile-w-right">
              <div className="profile-c-buttons">
                <button
                  className="btn btn-primary mr-p-b-b-s"
                  onClick={() => {
                    setUserTileVisible(true);
                  }}
                >
                  Info
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    window.open("https://namronet.com/", "_blank");
                  }}
                >
                  Shop
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {userTileVisible && (
        <UserTileCard
          userTileFirstName={profile?.first_name}
          userTileLastName={profile?.last_name}
          userTileImgCover={profile?.cover}
          userTileFriendsCount={profile?.friends.length}
          userTileFollowingCount={profile?.following.length}
          userTileFollowersCount={profile?.followers.length}
          userTileImgPic={profile?.picture}
          userJob={profile?.details?.job}
          userTileUsername={profile?.username}
          setUserTileVisible={setUserTileVisible}
        />
      )}
    </div>
  );
}
