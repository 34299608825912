import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import UserTileCard from "../UserTiles/UserTileCard";
import TileCard from "../UserTiles/TileCard";
import { followstatus } from "../../functions/reducers";
import { getAllUsers } from "../../functions/user";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const messages = [
  "Welcome to Namrolink!",
  "Namrolink warmly greets you.",
  "Namrolink awaits you.",
  "Enter Namrolink's world.",
  "Explore Namrolink's wonders.",
];

export default function UserTiles() {
  const { user } = useSelector((user) => ({ ...user }));
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [userTileVisible, setUserTileVisible] = useState(false);
  const [tileVisible, setTileVisible] = useState(false);
  const [visibleTileId, setVisibleTileId] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingUserDetails(true); // Start loading
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUserDetails(false); // Stop loading
      }
    };

    fetchUserDetails();
  }, [user.token]);

  const [{ loading, error, data }, dispatch] = useReducer(followstatus, {
    loading: true, // Set initial loading state to true
    data: {},
    error: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      dispatch({ type: "FOLLOWSTATUS_REQUEST" });
      const allUsersData = await getAllUsers(user.token);
      if (allUsersData.status === "ok") {
        const shuffledUsers = allUsersData.data.sort(() => 0.5 - Math.random());
        dispatch({
          type: "FOLLOWSTATUS_SUCCESS",
          payload: {
            allUsers: shuffledUsers,
          },
        });
      } else {
        dispatch({
          type: "FOLLOWSTATUS_ERROR",
          payload: allUsersData.data,
        });
      }
    } catch (error) {
      dispatch({ type: "FOLLOWSTATUS_ERROR", payload: error.message });
    }
  };

  return (
    <div>
      <div className="user-stories">
        <h3 className="mini-headign">
          <span className="w-m-h-m-s-s">{messages[currentMessageIndex]}</span>
          <span className="invisible-span">.</span>
        </h3>
        <div className="stories-wrapper">
          {loadingUserDetails ? ( // Display the loader if loading is true
            <div className="single-stories story-text-control">
              <label className="story-img-fit">
                <Skeleton
                  height="100%"
                  width="100%"
                  containerClassName="avatar-skeleton"
                />
              </label>
              <div className="story-back-container-home">
                <Skeleton
                  height="100%"
                  width="100%"
                  containerClassName="avatar-skeleton"
                  style={{ borderRadius: "16px" }}
                />
              </div>
            </div>
          ) : (
            <>
              {userTileVisible && (
                <UserTileCard
                  userTileFirstName={userDetails?.firstName}
                  userTileLastName={userDetails?.lastName}
                  userTileImgCover={userDetails?.coverImage}
                  userTileFriendsCount={userDetails?.friendsCount}
                  userTileFollowingCount={userDetails?.followingCount}
                  userTileFollowersCount={userDetails?.followersCount}
                  userTileImgPic={userDetails?.picture}
                  userJob={userDetails?.job}
                  userTileUsername={userDetails?.userName}
                  setUserTileVisible={setUserTileVisible}
                />
              )}
              <div
                className="single-stories story-text-control"
                onClick={() => {
                  setUserTileVisible(true);
                }}
              >
                <label className="story-img-fit">
                  <img src={user?.picture} alt="user" />
                </label>
                <div className="story-back-container-home">
                  <img
                    src={userDetails?.coverImage}
                    alt="user"
                    className="home-story-background"
                  />
                  <i className="fa-solid fa-circle-plus"></i>
                  <b>
                    {user?.first_name} {user?.last_name}
                  </b>
                </div>
              </div>
            </>
          )}

          {loading
            ? Array.from({ length: 4 }).map((_, index) => (
                <div className="single-stories story-text-control" key={index}>
                  <label className="story-img-fit">
                    <Skeleton
                      height="100%"
                      width="100%"
                      containerClassName="avatar-skeleton"
                    />
                  </label>
                  <div className="story-back-container-home">
                    <Skeleton
                      height="100%"
                      width="100%"
                      containerClassName="avatar-skeleton"
                      style={{ borderRadius: "16px" }}
                    />
                  </div>
                </div>
              ))
            : data.allUsers &&
              data.allUsers.slice(0, 4).map((user) => (
                <div
                  className="single-stories story-text-control"
                  key={user._id}
                >
                  <label
                    className="story-img-fit"
                    onClick={() => setVisibleTileId(user._id)}
                  >
                    <img src={user.picture} alt="sp" />
                  </label>
                  <div className="story-back-container-home">
                    <img
                      src={user.cover}
                      alt="ss"
                      className="home-story-background"
                      onClick={() => setVisibleTileId(user._id)}
                    />
                    <b>
                      {user.first_name} {user.last_name}
                    </b>
                    {visibleTileId === user._id && (
                      <TileCard
                        tileFirstName={user.first_name}
                        tileLastName={user.last_name}
                        tileImgCover={user.cover}
                        tileFriendsCount={user.friends.length}
                        tileFollowingCount={user.following.length}
                        tileFollowersCount={user.followers.length}
                        tileImgPic={user.picture}
                        tileJob={user.details?.job}
                        tileUsername={user.username}
                        setTileVisible={() => setVisibleTileId(null)}
                      />
                    )}
                  </div>
                </div>
              ))}

          {!loading && data.allUsers && data.allUsers.length < 4 && (
            <>
              {data.allUsers.length === 3 && (
                <div className="single-stories story-text-control">
                  <label className="story-img-fit">
                    <img
                      src="../../../images/postBackgrounds/1.jpg"
                      alt="user"
                    />
                  </label>
                  <div className="story-back-container-home">
                    <img
                      src="../../../images/postBackgrounds/1.jpg"
                      alt="user"
                      className="home-story-background"
                    />
                    <i className="fa-solid fa-circle-plus"></i>
                    <b>Namrolink Admin</b>
                  </div>
                </div>
              )}
              {data.allUsers.length === 2 && (
                <>
                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Admin</b>
                    </div>
                  </div>
                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Official</b>
                    </div>
                  </div>
                </>
              )}
              {data.allUsers.length === 1 && (
                <>
                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Admin</b>
                    </div>
                  </div>

                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Official</b>
                    </div>
                  </div>

                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/3.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/3.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namronet Admin</b>
                    </div>
                  </div>
                </>
              )}
              {data.allUsers.length === 0 && (
                <>
                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/1.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Admin</b>
                    </div>
                  </div>

                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/2.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namrolink Official</b>
                    </div>
                  </div>

                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/3.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/3.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namronet Admin</b>
                    </div>
                  </div>

                  <div className="single-stories story-text-control">
                    <label className="story-img-fit">
                      <img
                        src="../../../images/postBackgrounds/4.jpg"
                        alt="user"
                      />
                    </label>
                    <div className="story-back-container-home">
                      <img
                        src="../../../images/postBackgrounds/4.jpg"
                        alt="user"
                        className="home-story-background"
                      />
                      <i className="fa-solid fa-circle-plus"></i>
                      <b>Namronet Official</b>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
