import { useEffect, useState } from "react";
import { submitFeedback } from "../../functions/feedback";
import { Link } from "react-router-dom";
import MessageUpIcon from "../../components/icons/MessageUpIcon";
import { useSelector } from "react-redux";
import LoggedInFeedback from "./LoggedInFeedback";

export default function AboutConnectHelp() {
  const { user } = useSelector((state) => ({ ...state }));
  const [activeTab, setActiveTab] = useState("About");
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [feedbackStatus, setFeedbackStatus] = useState("");

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const { fullName, email, phoneNumber, subject, message } = formData;

    if (
      !fullName.trim() ||
      !email.trim() ||
      !phoneNumber.trim() ||
      !subject.trim() ||
      !message.trim()
    ) {
      setFeedbackStatus("Please fill in the missing fields");
      setTimeout(() => setFeedbackStatus(""), 5000);
      return;
    }

    const feedbackData = {
      fullName,
      email,
      phoneNumber,
      subject,
      message,
    };

    const response = await submitFeedback(feedbackData);
    setFeedbackStatus(response.message);

    if (response.message === "Message sent successfully") {
      setFormData({
        fullName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    }

    setTimeout(() => setFeedbackStatus(""), 5000);
  };

  useEffect(() => {
    setActiveTab("About");
  }, []);

  return (
    <div className="col-wrapper">
      <div>
        <div className="tabs-wrapper">
          <div
            className={activeTab === "About" ? "top-tab-active" : "top-tab"}
            onClick={() => setActiveTab("About")}
          >
            About
          </div>
          <div
            className={activeTab === "Connect" ? "top-tab-active" : "top-tab"}
            onClick={() => setActiveTab("Connect")}
          >
            Connect
          </div>
        </div>
        <div className="h-c-help-box">
          <div>
            <h2>{activeTab}</h2>
            {activeTab === "About" && (
              <div>
                <p>
                  Namrolink is a social media platform that connects people
                  across the globe, forging meaningful bonds and vibrant
                  connections. Discover and engage with diverse communities from
                  every corner of the world. Create lasting relationships and
                  share your unique experiences with others. Join us in building
                  a global network where every connection matters.
                </p>
              </div>
            )}
            {activeTab === "Connect" && (
              <div>
                <p
                  style={{
                    fontSize: "0.85rem",
                    textAlign: "center",
                    color: "var(--color-dark)",
                  }}
                >
                  Click the links below to get in touch with us more.
                </p>
                <p
                  style={{
                    fontSize: "0.85rem",
                    textAlign: "center",
                    color: "var(--color-dark)",
                  }}
                >
                  Never miss an update.
                </p>
                <div className="centered-help-p-div">
                  <a
                    href="tel:+256789980531"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-contact-img-icon-link"
                  >
                    <img
                      src="../../../icons/phoneimg.png"
                      className="help-contact-img-icon"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://whatsapp.com/channel/0029VahD78aEawe08lhy6y3q"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-contact-img-icon-link"
                  >
                    <img
                      src="../../../icons/whatsappimg.png"
                      className="help-contact-img-icon"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://t.me/+UrHoA_rW4bc1MDM0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-contact-img-icon-link"
                  >
                    <img
                      src="../../../icons/telegramimg.png"
                      className="help-contact-img-icon"
                      alt=""
                    />
                  </a>
                  <a
                    href="mailto:support@namrolink.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-contact-img-icon-link"
                  >
                    <img
                      src="../../../icons/gmailimg.png"
                      className="help-contact-img-icon"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://facebook.com/groups/1012456953684853/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-contact-img-icon-link"
                  >
                    <img
                      src="../../../icons/facebookimg.png"
                      className="help-contact-img-icon"
                      alt=""
                    />
                  </a>
                </div>
                <p
                  style={{
                    fontSize: "0.9rem",
                    textAlign: "center",
                    color: "var(--color-dark)",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("tel:+256789980531");
                  }}
                >
                  +256789980531
                </p>
                <p
                  style={{
                    fontSize: "0.9rem",
                    textAlign: "center",
                    color: "var(--color-dark)",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.location.href = "mailto:support@namrolink.com";
                  }}
                >
                  support@namrolink.com
                </p>
                <p style={{ textAlign: "center" }}>
                  Contact us via any platform you feel like using.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {user ? (
          <LoggedInFeedback />
        ) : (
          <div className="h-c-help-box">
            <h2>Contact Us</h2>
            <input
              type="text"
              name="fullName"
              placeholder="Full Names"
              className="help-contact-us-input"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="email"
              placeholder="Contact email"
              className="help-contact-us-input"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Mobile Number"
              className="help-contact-us-input"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="subject"
              placeholder="Message subject"
              className="help-contact-us-input"
              value={formData.subject}
              onChange={handleInputChange}
            />
            <textarea
              type="text"
              name="message"
              className="help-contact-us-textarea"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            {feedbackStatus && (
              <p
                style={{
                  color: feedbackStatus.includes("success") ? "green" : "red",
                }}
              >
                {feedbackStatus}
              </p>
            )}
            <button
              className="help-contact-us-submit-button"
              onClick={handleSubmit}
            >
              <div className="help-contact-us-submit-button-div">
                <i>
                  <MessageUpIcon />
                </i>
                Submit
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
