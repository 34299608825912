import { useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";
import { comment } from "../../functions/post";
import { uploadImages } from "../../functions/uploadImages";
import dataURItoBlob from "../../helpers/dataURItoBlob";
import { ClipLoader } from "react-spinners";
import PhotoIcon from "../icons/PhotoIcon";
import EmojiIcon from "../icons/EmojiIcon";
import SendOutlineIcon from "../icons/SendOutlineIcon";
import CloseIcon from "../icons/CloseIcon";

export default function CreateComment({ user, postId, setComments, setCount }) {
  const [picker, setPicker] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [commentImage, setCommentImage] = useState("");
  const [cursorPosition, setCursorPosition] = useState();
  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);
  const imgInput = useRef(null);

  useEffect(() => {
    textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const handleEmoji = (e, { emoji }) => {
    const ref = textRef.current;
    ref.focus();
    const start = text.substring(0, ref.selectionStart);
    const end = text.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setText(newText);
    setCursorPosition(start.length + emoji.length);
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return; // Exit the function if no file is selected
    }
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`${file.name} format is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError(`${file.name} is too large. The maximum allowed size is 5MB.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCommentImage(event.target.result);
    };
  };

  const handleComment = async () => {
    if (text.trim() === "" && commentImage === "") {
      setError("Cannot make a blank comment");
      return;
    }

    setLoading(true);

    if (commentImage !== "") {
      const img = dataURItoBlob(commentImage);
      const path = `namrolink-social-media/${user.username}/post_images/${postId}`;
      let formData = new FormData();
      formData.append("path", path);
      formData.append("file", img);
      const imgComment = await uploadImages(formData, path, user.token);

      const comments = await comment(
        postId,
        text,
        imgComment[0].url,
        user.token
      );
      setComments(comments);
      setCount((prev) => ++prev);
      setText("");
      setCommentImage("");
    } else {
      const comments = await comment(postId, text, "", user.token);
      setComments(comments);
      setCount((prev) => ++prev);
      setText("");
      setCommentImage("");
    }
    setLoading(false);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      handleComment();
    }
  };

  return (
    <div>
      <div className="create-comment-wrap">
        <div className="profile-photo-c-comment-s">
          <img src={user?.picture} />
        </div>
        <div className="create-comment">
          <div className="comment-input-container">
            {picker && (
              <div className="comment-emoji-picker-p">
                <Picker onEmojiClick={handleEmoji} />
              </div>
            )}
            <input
              type="file"
              hidden
              ref={imgInput}
              accept="image/jpeg,image/png,image/gif,image/webp"
              onChange={handleImage}
            />
            {error && (
              <div className="postError comment_error u-f-comment_error">
                <div className="postError_error">{error}</div>
                <button className="p-p-blue_btn" onClick={() => setError("")}>
                  Try again
                </button>
              </div>
            )}
            <input
              type="text"
              ref={textRef}
              value={text}
              placeholder="Write a comment ..."
              onChange={(e) => setText(e.target.value)}
              onKeyUp={handleEnterKey}
            />
            <label className="comment-add-s-l-s">
              <ClipLoader
                size={18}
                color="var(--color-primary)"
                loading={loading}
              />
            </label>
            <label className="comment-add-s">
              <i
                className="uil uil-image-plus imported_icon-c-s"
                onClick={() => setPicker((prev) => !prev)}
              >
                <EmojiIcon />
              </i>
            </label>
            <label className="comment-add-s">
              <i
                className="uil uil-image-plus imported_icon-c-s"
                onClick={() => imgInput.current.click()}
              >
                <PhotoIcon />
              </i>
            </label>
            <button
              className="btn btn-primary comment-s-btn-s"
              onClick={handleComment}
            >
              <SendOutlineIcon />
            </button>
          </div>
        </div>
      </div>
      {commentImage && (
        <div className="comment_img_preview">
          <img src={commentImage} />
          <div
            className="small_white_circle"
            onClick={() => setCommentImage("")}
          >
            <i className="close-icon-s-comment-img-s">
              <CloseIcon />
            </i>
          </div>
        </div>
      )}
    </div>
  );
}
