import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import { Link } from "react-router-dom";
import { HashLoader } from "react-spinners";
import CloseIcon from "../icons/CloseIcon";
export default function SearchMenu({
  setShowSearchMenu,
  results,
  searchHistory,
  addToSearchHistoryHandler,
  handleRemove,
  loading,
  noResults,
  searchTerm,
}) {
  const menu = useRef(null);
  useClickOutside(menu, () => {
    setShowSearchMenu(false);
  });

  const handleUserClick = (userId) => {
    addToSearchHistoryHandler(userId);
    setShowSearchMenu(false);
  };

  return (
    <div className="u-search-menu" ref={menu}>
      <div className="u-search-menu-c-c">
        {loading && searchTerm.trim() !== "" && (
          <div className="seach-menu-loader-container">
            <HashLoader color="#1876f2" size={50} />
          </div>
        )}
        {results.length === 0 && !noResults && (
          <div className="u-search-menu-heading">
            <div className="u-search-menu-title">Recent searches</div>
            <div
              className="u-search-menu-close"
              onClick={() => {
                setShowSearchMenu(false);
              }}
            >
              Close
            </div>
          </div>
        )}
        <div>
          {searchHistory &&
            results.length === 0 &&
            !noResults &&
            searchHistory
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((user) => (
                <div
                  className="recent-u-search-h-item hover1-m-p"
                  key={user?._id}
                >
                  <Link
                    className="recent-u-search-h-l"
                    to={`/profile/${user.user?.username}`}
                    onClick={() => handleUserClick(user.user?._id)}
                  >
                    <img src={user.user?.picture} alt="" />
                    <div>
                      <h5>
                        {user.user?.first_name} {user.user?.last_name}{" "}
                      </h5>
                      <p className="text-muted">
                        <small>@{user.user?.username}</small>
                      </p>
                    </div>
                  </Link>
                  <i
                    className="search-m-exit-i-s"
                    onClick={() => {
                      handleRemove(user.user?._id);
                    }}
                  >
                    <CloseIcon />
                  </i>
                </div>
              ))}
        </div>
        <div>
          {results.length > 0 &&
            results.map((user) => (
              <div className="u-search-r-h-item-c-s hover1-m-p" key={user?._id}>
                <Link
                  to={`/profile/${user?.username}`}
                  className="u-search-r-h-item"
                  onClick={() => handleUserClick(user?._id)}
                >
                  <img src={user?.picture} alt="" />
                  <div>
                    <h5>
                      {user?.first_name} {user?.last_name}
                    </h5>
                    <p className="text-muted">
                      <small>@{user?.username}</small>
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          {noResults && searchTerm.trim() !== "" && <h4>No user found</h4>}
        </div>
        <div className="search-menu-l-s-s"></div>
      </div>
    </div>
  );
}
