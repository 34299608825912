import React, { useEffect, useState } from "react";
import DeveloperProfileCard from "../../components/developer/DeveloperProfileCard";
import DeveloperOther from "./DeveloperOther";
import { useSelector } from "react-redux";
import DeveloperContent from "./DeveloperContent";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import { Helmet } from "react-helmet";

export default function Developer() {
  const { user } = useSelector((state) => ({ ...state }));
  const [themeVisible, setThemeVisible] = useState(false);

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  // Check the current URL
  const currentUrl = window.location.href;

  // Render components based on the URL
  if (currentUrl.includes("sayiganormanhaq")) {
    return <DeveloperProfileCard />;
  } else if (currentUrl.includes("external")) {
    return <DeveloperOther />;
  } else {
    return (
      <div>
        <Helmet>
          <title>Namrolink - Developer</title>
          <link rel="icon" href="../../../icons/icon.png" />
          <meta
            name="description"
            content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
          />
          <meta name="author" content="Sayiga Norman Haq" />
          <meta
            name="keywords"
            content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan software developer, Developer"
          />
        </Helmet>
        <style jsx>{`
          /* ==================== GOOGLE FONTS ====================*/
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

          /*==================== VARIABLES CSS ====================*/
          :root {
            --header-height: 3rem;

            /*========== Colors ==========*/
            /* Change favorite color */
            --hue-color: 190; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

            /* HSL color mode */
            --first-color: hsl(var(--hue-color), 69%, 61%);
            --first-color-second: hsl(var(--hue-color), 69%, 61%);
            --first-color-alt: hsl(var(--hue-color), 57%, 53%);
            --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
            --title-color: hsl(var(--hue-color), 8%, 15%);
            --text-color: hsl(var(--hue-color), 8%, 45%);
            --text-color-light: hsl(var(--hue-color), 8%, 65%);
            --input-color: hsl(var(--hue-color), 70%, 96%);
            --body-color: hsl(var(--hue-color), 60%, 99%);
            --container-color: #fff;
            --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
            --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

            /*========== Font and typography ==========*/
            --body-font: "Poppins", sans-serif;

            /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
            --big-font-size: 2rem;
            --h1-font-size: 1.5rem;
            --h2-font-size: 1.25rem;
            --h3-font-size: 1.125rem;
            --normal-font-size: 0.938rem;
            --small-font-size: 0.813rem;
            --smaller-font-size: 0.75rem;

            /*========== Font weight ==========*/
            --font-medium: 500;
            --font-semi-bold: 600;

            /*========== Margenes Bottom ==========*/
            /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
            --mb-0-25: 0.25rem;
            --mb-0-5: 0.5rem;
            --mb-0-75: 0.75rem;
            --mb-1: 1rem;
            --mb-1-5: 1.5rem;
            --mb-2: 2rem;
            --mb-2-5: 2.5rem;
            --mb-3: 3rem;

            /*========== z index ==========*/
            --z-tooltip: 10;
            --z-fixed: 100;
            --z-modal: 1000;
          }

          /* Font size for large devices */
          @media screen and (min-width: 968px) {
            :root {
              --big-font-size: 3rem;
              --h1-font-size: 2.25rem;
              --h2-font-size: 1.5rem;
              --h3-font-size: 1.25rem;
              --normal-font-size: 1rem;
              --small-font-size: 0.875rem;
              --smaller-font-size: 0.813rem;
            }
          }

          /*========== Variables Dark theme ==========*/
          body.dark-theme {
            /* HSL color mode */
            --first-color-second: hsl(var(--hue-color), 30%, 8%);
            --title-color: hsl(var(--hue-color), 8%, 95%);
            --text-color: hsl(var(--hue-color), 8%, 45%);
            --text-color-light: hsl(var(--hue-color), 8%, 75%);
            --input-color: hsl(var(--hue-color), 29%, 16%);
            --body-color: hsl(var(--hue-color), 28%, 12%);
            --container-color: hsl(var(--hue-color), 29%, 16%);
            --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
            --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
          }

          /*========== Button Dark/Light ==========*/
          .nav__btns {
            display: flex;
            align-items: center;
          }

          .change-theme {
            font-size: 1.25rem;
            color: var(--color-dark);
            margin-right: var(--mb-1);
            cursor: pointer;
          }

          .change-theme:hover {
            color: var(--color-dark);
          }

          /*==================== BASE ====================*/

          * {
            box-sizing: border-box;
            padding: 0;
            margin: 0;
          }

          html {
            scroll-behavior: smooth;
          }

          body {
            margin: 0 0 var(--header-height) 0;
            font-family: var(--body-font);
            font-size: var(--normal-font-size);
            background-color: var(--color-light);
            color: var(--color-dark);
          }

          h1,
          h2,
          h3,
          h4 {
            color: var(--color-dark);
            font-weight: var(--font-semi-bold);
          }

          ul {
            list-style: none;
          }

          a {
            text-decoration: none;
          }

          img {
            width: 100%;
            height: auto;
          }

          /*==================== REUSABLE CSS CLASSES ====================*/
          .section {
            padding: 2rem 0 4rem;
            margin-bottom: 40px;
          }

          .section__title {
            font-size: var(--h1-font-size);
          }

          .section__subtitle {
            display: block;
            font-size: var(--small-font-size);
            margin-bottom: var(--mb-2);
          }

          .section__title,
          .section__subtitle {
            text-align: center;
          }

          /*==================== LAYOUT ====================*/

          .container-p {
            max-width: 768px;
            margin-left: var(--mb-1-5);
            margin-right: var(--mb-1-5);
          }

          .grid {
            display: grid;
            gap: 1.5rem;
          }

          .header-p {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: var(--z-fixed);
            background-color: var(--color-white);
            box-shadow: 1px 8px 15px -7px rgba(0, 0, 0, 0.1);
          }

          /*==================== NAV ====================*/
          .header-p .container-p i svg {
            height: 1.5rem;
            width: 1.5rem;
          }
          .nav-p {
            max-width: 968px;
            height: var(--header-height);
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .nav__logo,
          .nav__toggle {
            color: var(--color-dark);
            font-weight: var(--font-medium);
          }

          .nav__logo:hover {
            color: var(--color-primary);
          }

          .nav__toggle {
            font-size: 1.1rem;
            cursor: pointer;
          }

          .nav__toggle:hover {
            color: var(--color-primary);
          }

          @media screen and (max-width: 767px) {
            .nav__menu {
              position: fixed;
              bottom: -100%;
              left: 0;
              width: 100%;
              background-color: var(--color-white);
              padding: 2rem 1.5rem 4rem;
              box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
              border-radius: 1.5rem 1.5rem 0 0;
              transition: 0.3s;
            }
          }

          .nav__list {
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
          }
          .nav__link {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: var(--small-font-size);
            color: var(--color-dark);
            font-weight: var(--font-medium);
          }

          .nav__link:hover {
            color: var(--color-primary);
          }

          .nav__icon {
            font-size: 1.2rem;
          }

          .nav__close {
            position: absolute;
            right: 1.3rem;
            bottom: 0.5rem;
            font-size: 1.5rem;
            cursor: pointer;
            color: var(--color-dark);
          }

          .nav__close:hover {
            color: var(--color-primary);
          }

          /* show menu */
          .show-menu {
            bottom: 0;
          }

          /* Active link */
          .active-link {
            color: var(--color-primary);
          }

          /* Change background header */
          .scroll-header {
            box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
          }

          /*==================== HOME ====================*/

          .home__container {
            gap: 1rem;
          }

          .home__content {
            grid-template-columns: 0.5fr 3fr;
            padding-top: 3.5rem;
            align-items: center;
          }

          .home__social {
            display: grid;
            grid-template-columns: max-content;
            row-gap: 1rem;
          }

          .home__social-icon {
            font-size: 1.25rem;
            color: var(--color-dark);
          }

          .home__social-icon:hover {
            color: var(--color-primary);
          }

          .home__blob {
            width: 200px;
            fill: var(--color-primary);
          }

          .home__blob-img {
            width: 220px;
            transition: transform 0.4s;
            border-radius: 14px;
            object-fit: cover;
          }
          .home__blob-img:hover {
            transform: scale(1.1);
          }

          .home__data {
            grid-column: 1/3;
          }

          .home__title {
            font-size: var(--big-font-size);
          }

          .home__subtitle {
            font-size: var(--h3-font-size);
            color: var(--color-gray);
            font-weight: var(--font-medium);
            margin-bottom: var(--mb-0-75);
          }

          .home__description {
            margin-bottom: var(--mb-2);
          }

          .home__scroll {
            display: none;
          }

          .home__scroll-button {
            color: var(--color-primary);
            transition: 0.3s;
          }

          .home__scroll-button:hover {
            transform: translateY(0.25rem);
          }

          .home__scroll-mouse {
            font-size: 2rem;
          }

          .home__scroll-name {
            font-size: var(--small-font-size);
            color: var(--color-primary);
            font-weight: var(--font-medium);
            margin-right: var(--mb-0-25);
          }

          .home__scroll-arrow {
            font-size: 1.25rem;
          }
          /*==================== BUTTONS ====================*/

          .button {
            display: inline-block;
            background-color: var(--color-primary);
            color: #ffff;
            padding: 1rem;
            border-radius: 0.5rem;
            font-weight: var(--font-medium);
          }

          .button:hover {
            opacity: 0.8;
          }

          .button__icon {
            font-size: 1.2rem;
            margin-left: var(--mb-0-5);
            transition: 0.3s;
          }

          .button--white {
            background-color: #fff;
            color: var(--color-primary);
          }

          .button--white:hover {
            background-color: #fff;
          }

          .button--flex {
            display: inline-flex;
            align-items: center;
            transition: 0.3s;
          }

          .button--small {
            padding: 0.75rem 1rem;
          }

          .button--link {
            padding: 0;
            background-color: transparent;
            color: var(--color-primary);
          }

          .button--link:hover {
            background-color: transparent;
            color: var(--color-primary);
          }

          /*==================== ABOUT ====================*/
          .about__img {
            width: 200px;
            border-radius: 0.5rem;
            justify-self: center;
            align-items: center;
          }

          .about__description {
            text-align: center;
            margin-bottom: var(--mb-2-5);
          }

          .about__info {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: var(--mb-2-5);
          }

          .about__info-title {
            font-size: var(--h2-font-size);
            font-weight: var(--font-semi-bold);
            color: var(--color-primary);
          }

          .about__info-name {
            font-size: var(--smaller-font-size);
          }

          .about__info-title,
          .about__info-name {
            display: block;
            text-align: center;
          }

          .about__buttons {
            display: flex;
            justify-content: center;
          }

          /*==================== SKILLS ====================*/

          .skills__container {
            row-gap: 0;
          }

          .skills__header {
            display: flex;
            align-items: center;
            margin-bottom: var(--mb-2-5);
            cursor: pointer;
          }

          .skills__icon,
          .skills__arrow {
            font-size: 2rem;
            color: var(--color-primary);
          }

          .skills__icon {
            margin-right: var(--mb-0-75);
          }

          .skills__title {
            font-size: var(--h3-font-size);
          }

          .skills__subtitle {
            font-size: var(--small-font-size);
            color: var(--color-gray);
          }

          .skills__arrow {
            margin-left: auto;
            transition: 0.4s;
          }

          .skills__list {
            row-gap: 1.5rem;
            padding-left: 2.7rem;
          }

          .skills__titles {
            display: flex;
            justify-content: space-between;
            margin-bottom: var(--mb-0-5);
          }

          .skills__name {
            font-size: var(--normal-font-size);
            font-weight: var(--font-medium);
          }

          .skills__bar,
          .skills__percentage {
            height: 5px;
            border-radius: 0.25rem;
          }

          .skills__bar {
            background-color: var(--color-gray);
          }

          .skills__percentage {
            display: block;
            background-color: var(--color-primary);
          }

          .skills__html {
            width: 90%;
          }

          .skills__css {
            width: 80%;
          }

          .skills__js {
            width: 60%;
          }

          .skills__wordpress {
            width: 70%;
          }

          .skills__python {
            width: 60%;
          }

          .skills__tab {
            width: 70%;
          }

          .skills__sql {
            width: 50%;
          }

          .skills__google {
            width: 70%;
          }

          .skills__bank {
            width: 80%;
          }

          .skills__google {
            width: 70%;
          }

          .skills__counter {
            width: 80%;
          }

          .skills__office {
            width: 90%;
          }

          .skills__close .skills__list {
            height: 0;
            overflow: hidden;
          }

          .skills__open .skills__list {
            height: max-content;
            margin-bottom: var(--mb-2-5);
          }

          .skills__open .skills__arrow {
            transform: rotate(-180deg);
          }

          /*==================== QUALIFICATION ====================*/
          .qualification__tabs {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: var(--mb-2);
          }

          .qualification__button {
            font-size: var(--h3-font-size);
            font-weight: var(--font-medium);
            cursor: pointer;
          }

          .qualification__button:hover {
            color: var(--color-primary);
          }

          .qualification__icon {
            font-size: 1.8rem;
            margin-right: var(--mb-0-25);
          }

          .qualification__data {
            display: grid;
            grid-template-columns: 1fr max-content 1fr;
            column-gap: 1.5rem;
          }

          .qualification__title {
            font-size: var(--normal-font-size);
            font-weight: var(--font-medium);
          }

          .qualification__subtitle {
            display: inline-block;
            font-size: var(--small-font-size);
            margin-bottom: var(--mb-1);
          }

          .qualification__calendar {
            font-size: var(--smaller-font-size);
            color: var(--color-gray);
            /* margin-bottom: var(--mb-1) */
          }

          .qualification__rounder {
            display: inline-block;
            width: 13px;
            height: 13px;
            background-color: var(--color-primary);
            border-radius: 50%;
          }

          .qualification__line {
            display: block;
            width: 1px;
            height: 100%;
            background-color: var(--color-primary);
            transform: translate(6px, -7px);
          }

          .qualification__content[data-content] {
            display: none;
          }

          .qualification__active[data-content] {
            display: block;
          }

          .qualification__button.qualification__active {
            color: var(--color-primary);
          }

          /*==================== SERVICES ====================*/
          .services__container {
            gap: 1.5rem;
            grid-template-columns: repeat(2, 1fr);
          }

          .services__content {
            position: relative;
            background-color: var(--color-white);
            padding: 3.5rem 0.5rem 1.25rem 1.5rem;
            border-radius: 14px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
            transition: 0.3s;
          }

          .services__content:hover {
            box-shadow: 0 4px 8px var(--color-primary);
          }

          .services__icon {
            display: block;
            font-size: 1.5rem;
            color: var(--color-primary);
            margin-bottom: var(--mb-1);
          }

          .services__title {
            font-size: var(--h3-font-size);
            margin-bottom: var(--mb-1);
            font-weight: var(--font-medium);
          }

          .services__button {
            cursor: pointer;
            font-size: var(--small-font-size);
          }

          .services__button:hover .button__icon {
            transform: translateX(0.25rem);
          }

          .services__modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1rem;
            z-index: var(--z-modal);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
          }

          .services__modal-content {
            position: relative;
            background-color: var(--color-white);
            padding: 1.5rem;
            border-radius: 0.5rem;
            box-shadow: 0 0 0.4rem var(--color-primary);
          }

          .services__modal-services {
            row-gap: 1rem;
          }

          .services__modal-service {
            display: flex;
          }

          .services__modal-title {
            font-size: var(--h3-font-size);
            font-weight: var(--font-medium);
            margin-bottom: var(--mb-1-5);
          }

          .services__modal-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            font-size: 1.5rem;
            color: var(--color-primary);
            cursor: pointer;
          }

          .services__modal-icon {
            color: var(--color-primary);
            margin-right: var(--mb-0-25);
          }

          /* Active Modal */
          .active-modal {
            opacity: 1;
            visibility: visible;
          }

          /*==================== PORTFOLIO ====================*/
          .portfolio__container {
            overflow: initial;
          }

          .portfolio__content {
            padding: 0 1.5rem;
          }

          .portfolio__img {
            width: 265px;
            border-radius: 0.5rem;
            justify-self: center;
          }

          .portfolio__title {
            font-size: var(--h3-font-size);
            margin-bottom: var(--mb-0-5);
          }

          .portfolio__description {
            margin-bottom: var(--mb-0-75);
          }

          .portfolio__button:hover .button__icon {
            transform: translateX(0.25rem);
          }

          .swiper-button-prev::after,
          .swiper-button-next::after {
            content: "";
          }

          .swiper-portfolio-icon {
            font-size: 2rem;
            color: var(--color-primary);
          }

          .swiper-button-prev {
            left: -0.5rem;
          }

          .swiper-button-next {
            right: -0.5rem;
          }

          .swiper-container-horizontal > .swiper-pagination-bullets {
            bottom: -2.5rem;
          }

          .swiper-pagination-bullet-active {
            background-color: var(--color-primary);
          }

          .swiper-button-prev,
          .swiper-button-next,
          .swiper-pagination-bullet {
            outline: none;
          }
          /*==================== PROJECT IN MIND ====================*/
          .project {
            text-align: center;
          }

          .project__bg {
            background-color: var(--color-primary);
            padding-top: 3rem;
          }

          .project__title {
            font-size: var(--h2-font-size);
            margin-bottom: var(--mb-0-75);
          }

          .project__description {
            margin-bottom: var(--mb-1-5);
          }

          .project__title,
          .project__description {
            color: #fff;
          }

          .project__img {
            width: 232px;
            justify-self: center;
          }

          /*==================== TESTIMONIAL ====================*/
          .testimonial__data,
          .testimonial__header {
            display: flex;
          }

          .testimonial__data {
            justify-content: space-between;
            margin-bottom: var(--mb-1);
          }

          .testimonial__img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: var(--mb-0-75);
          }

          .testimonial__name {
            font-size: var(--h3-font-size);
            font-weight: var(--font-medium);
          }

          .testimonial__client {
            font-size: var(--small-font-size);
            color: var(--color-dark);
          }

          .testimonial__description {
            margin-bottom: var(--mb-2-5);
          }

          .testimonial__icon-star {
            color: var(--color-dark);
          }

          .swiper-container .swiper-pagination-testimonial {
            bottom: 0;
          }

          /*==================== CONTACT ME ====================*/
          .contact__container {
            row-gap: 3rem;
          }

          .contact__information {
            display: flex;
            margin-bottom: var(--mb-2);
          }

          .contact__icon {
            font-size: 1.5rem;
            color: var(--color-primary);
            margin-right: var(--mb-0-75);
          }

          .content__title {
            font-size: var(--h3-font-size);
            font-weight: var(--font-medium);
          }

          .contact__subtitle {
            font-size: var(--small-font-size);
            color: var(--color-gray);
          }

          .contact__content {
            background-color: var(--color-white);
            border-radius: 0.5rem;
            padding: 0.75rem 1rem 0.25rem;
          }

          .contact__label {
            font-size: var(--smaller-font-size);
            color: var(--color-dark);
          }

          .contact__input {
            width: 100%;
            background-color: var(--color-white);
            color: var(--color-dark);
            font-family: var(--body-font);
            font-size: var(--normal-font-size);
            border: none;
            outline: none;
            padding: 0.25rem 0.5rem 0.5rem 0;
          }

          /*==================== FOOTER ====================*/
          .footer {
            padding-top: 2rem;
          }

          .footer__container {
            row-gap: 3.5rem;
          }

          .footer__bg {
            background-color: var(--color-primary);
            padding: 2rem 0 3rem;
          }

          .footer__title {
            font-size: var(--h1-font-size);
            margin-bottom: var(--mb-0-25);
          }

          .footer__subtitle {
            font-size: var(--small-font-size);
          }

          .footer__links {
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
          }

          .footer__link:hover {
            color: var(--color-dark);
          }

          .footer__social {
            font-size: 1.25rem;
            margin-right: var(--mb-1-5);
          }

          .footer__social:hover {
            color: var(--color-dark);
          }

          .footer__copy {
            font-size: var(--smaller-font-size);
            text-align: center;
            color: white;
            margin-top: var(--mb-3);
          }

          .footer__title,
          .footer__subtitle,
          .footer__link,
          .footer__social {
            color: #fff;
          }

          /*========== SCROLL UP ==========*/
          .scrollup {
            position: fixed;
            right: 1rem;
            bottom: -20%;
            background-color: var(--color-primary);
            opacity: 0.8;
            padding: 0 0.3rem;
            border-radius: 0.4rem;
            z-index: var(--z-tooltip);
            transition: 0.4s;
          }

          .scrollup:hover {
            background-color: var(--color-primary);
          }

          .scrollup__icon {
            font-size: 1.5rem;
            color: #fff;
          }

          /* Show scroll */
          .show-scroll {
            bottom: 5rem;
          }

          /*========== SCROLL BAR ==========*/
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-thumb {
            background: #aeaeafa1;
            border-radius: 5px;
          }

          /*==================== MEDIA QUERIES ====================*/
          /* For small devices */
          @media screen and (max-width: 380px) {
            .container-p {
              margin-left: var(--mb-1);
              margin-right: var(--mb-1);
            }

            .nav__menu {
              padding: 2rem 0.25rem 4rem;
            }

            .nav__list {
              column-gap: 0;
            }

            .home__content {
              grid-template-columns: 0.35fr 3fr;
            }

            .home__blob {
              width: 250px;
            }

            .home__social-icon {
              width: 30px;
            }

            .home__subtitle {
              font-size: 24px;
            }

            .home__description {
              font-size: 18px;
            }

            .home__button {
              font-size: 20px;
            }

            .skills__title {
              font-size: var(--normal-font-size);
            }
            .qualification__data {
              gap: 0.5rem;
            }

            .services__container {
              grid-template-columns: max-content;
              justify-content: center;
            }

            .services__content {
              padding-right: 3.5rem;
            }
            .services__modal {
              padding: 0 0.5rem;
            }

            .project__img {
              width: 200px;
            }

            .testimonial__header,
            .testimonial__data {
              flex-direction: column;
              align-items: center;
            }

            .testimonial__img {
              margin-right: 0;
              margin-bottom: var(--mb-0-25);
            }

            .testimonial__data,
            .testimonial__description {
              text-align: center;
            }
          }

          /* For medium devices */
          @media screen and (min-width: 568px) {
            .home__content {
              grid-template-columns: max-content 1fr 1fr;
            }

            .home__data {
              grid-column: initial;
            }
            .home__img {
              order: 1;
              justify-self: center;
            }

            .about__container,
            .skills_container,
            .portfolio__content,
            .project__container,
            .contact__container,
            .footer__container {
              grid-template-columns: repeat(2, 1fr);
            }

            .qualification__sections {
              display: grid;
              grid-template-columns: 0.6fr;
              justify-content: center;
            }

            @media screen and (min-width: 768px) {
              .container-p {
                margin-left: auto;
                margin-right: auto;
              }

              .body {
                margin: 0;
              }
              .section {
                padding: 6rem 0 2rem;
              }
              .section__subtitle {
                margin-bottom: 4rem;
              }
              .header-p {
                top: 0;
                bottom: initial;
              }

              .header-p,
              .main-p,
              .footer__container {
                padding: 0 1rem;
              }

              .nav-p {
                height: calc(var(--header-height) + 1.5rem);
                column-gap: 1rem;
              }
              .nav__icon,
              .nav__close,
              .nav__toggle {
                display: none;
              }

              .nav__list {
                display: flex;
                column-gap: 2rem;
              }

              .nav__menu {
                margin-left: auto;
              }
              .change-theme {
                margin: 0;
              }

              .home__container {
                row-gap: 5rem;
              }

              .home__content {
                padding-top: 5.5rem;
                column-gap: 2rem;
              }
              .home__blob {
                width: 270px;
              }
              .home__scroll {
                display: block;
              }
              .home__scroll-button {
                margin-left: 3rem;
              }
              .about__container {
                column-gap: 5rem;
              }
              .about__img {
                width: 350px;
              }
              .about__description {
                text-align: initial;
              }
              .about__info {
                justify-content: space-between;
              }
              .about__buttons {
                justify-content: initial;
              }
              .qualification__tabs {
                justify-content: center;
              }
              .qualification__button {
                margin: 0 var(--mb-1);
              }

              .qualification__sections {
                grid-template-columns: 0.5fr;
              }
              .services__container {
                grid-template-columns: repeat(3, 218px);
                justify-content: center;
              }
              .services__icon {
                font-size: 2rem;
              }
              .services__content {
                padding: 6rem 0 2rem 2.5rem;
              }

              .services__modal-content {
                width: 450px;
              }
              .portfolio__img {
                width: 320px;
              }
              .portfolio__content {
                align-items: center;
              }
              .project {
                text-align: initial;
              }
              .project__bg {
                background: none;
              }
              .project__container {
                background-color: var(--color-primary);
                border-radius: 1rem;
                padding: 3rem 2.5rem 0;
                grid-template-columns: 1fr max-content;
                column-gap: 3rem;
              }
              .project__data {
                padding-top: 0.8rem;
              }
              .footer__container {
                grid-template-columns: repeat(3, 1fr);
              }
              .footer__bg {
                padding: 3rem 0 3.5rem;
              }

              .footer__links {
                flex-direction: row;
                column-gap: 2rem;
              }
              .footer__socials {
                justify-self: flex-end;
              }
              .footer__copy {
                margin-top: 4.5rem;
              }
            }
          }

          /* For large devices */
          @media screen and (min-width: 1024px) {
            body {
              margin: 0;
            }

            .header-p,
            .main-p,
            .footer__container {
              padding: 0;
            }
            .home__blob {
              width: 320px;
            }
            .home__social {
              transform: translateX(-6rem);
            }
            .services__container {
              grid-template-columns: repeat(3, 238px);
            }
            .portfolio__content {
              column-gap: 5rem;
            }

            .project__container {
              padding-bottom: 30px;
            }

            .swiper-portfolio-icon {
              font-size: 3.5rem;
            }
            .swiper-button-prev {
              left: -3.5rem;
            }
            .swiper-button-next {
              right: -3.5rem;
            }
            .swiper-container-horizontal > .swiper-pagination-bullets {
              bottom: -4.5rem;
            }

            .contact__form {
              width: 460px;
            }
            .contact__inputs {
              grid-template-columns: repeat(2, 1fr);
            }
          }
          @media screen and (min-width: 900px) {
            .important-about-part {
              display: flex;
              justify-content: left !important;
            }
          }
          .important-about-part {
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            color: var(--color-primary);
            justify-content: center;
          }
          .idea-home-section {
            padding: 15px;
          }
          .developer-u-p-i-s svg {
            width: 1.3rem;
            height: 1.3rem;
          }
          .home__data .home__button .developer-u-p-i-s {
            transform: translateY(2px);
          }
          .about__data .about__buttons .button {
            cursor: pointer;
          }
          .project-preview-s-d-s {
            display: flex !important;
            flex-direction: column;
            gap: 90px !important;
          }
          .project-preview-s-d-s .portfolio__content .portfolio__img {
            border: 0.2px solid var(--color-dark);
          }
          .skills__icon svg {
            width: 2rem;
            height: 2rem;
          }
          .qualification__button .qualification__icon svg {
            width: 1.5rem;
            height: 1.5rem;
          }
          .services__content .services__icon svg {
            width: 1.6rem;
            height: 1.6rem;
          }
          .contact__container .contact__form .developer-c-s-m-i-s svg {
            width: 1.3rem;
            height: 1.3rem;
            transform: translateY(4px);
          }
          .project
            .project__bg
            .project__container
            .project__data
            .button
            .project__icon
            svg {
            width: 1.3rem;
            height: 1.3rem;
            transform: translateY(4px);
          }
          .contact .contact__container .contact__information svg {
            width: 1.4rem;
            height: 1.4rem;
          }
          .footer__socials .footer__social svg {
            width: 1.4rem;
            height: 1.4rem;
          }
          .qualification__section
            .qualification__data
            .qualification__calendar
            svg {
            width: 1rem;
            height: 1rem;
          }
          .qualification__calendar {
            display: flex;
            align-items: center;
            gap: 6px;
          }
          .home-p .home__container .home__scroll .home__scroll-button {
            display: flex;
            align-items: center;
            gap: 3px;
          }
          .services__modal .services__modal-content .services__modal-close {
            width: 1.3rem;
            height: 1.3rem;
          }
          .skills__arrow svg {
            max-width: 1.5rem;
            max-height: 1.5rem;
          }
        `}</style>
        <DeveloperContent onThemeClick={() => handleThemeVisibility(true)} />
        {themeVisible && (
          <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
        )}
      </div>
    );
  }
}
