import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ExploreIcon from "../../components/icons/ExploreIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import HomeIcon from "../../components/icons/HomeIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";
import { useEffect, useState } from "react";
import ArrowUpLeftIcon from "../../components/icons/ArrowUpLeftIcon";
import SearchUsers from "./SearchUsers";
import ThemeIcon from "../../components/icons/ThemeIcon";
import FriendsIcon from "../../components/icons/FriendsIcon";
import ChatFriends from "./ChatFriends";
import VideoCameraIcon from "../../components/icons/VideoCameraIcon";
import PhotoIcon from "../../components/icons/PhotoIcon";
import Cookies from "js-cookie";

export default function ChatSidebar({
  onUserThemeClick,
  onlineUsers,
  socketConnection,
  setSocketConnection,
}) {
  const { user } = useSelector((user) => ({ ...user }));
  const [allUser, setAllUser] = useState([]);
  const [showSearchUsersMenu, setShowSearchUsersMenu] = useState(false);
  const [showChatFriendsMenu, setShowChatFriendsMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  useEffect(() => {
    if (socketConnection) {
      socketConnection.emit("sidebar", user.id);

      socketConnection.on("conversation", (data) => {

        const conversationUserData = data.map((conversationUser, index) => {
          if (
            conversationUser?.sender?._id === conversationUser?.receiver?._id
          ) {
            return {
              ...conversationUser,
              userDetails: conversationUser?.sender,
            };
          } else if (conversationUser?.receiver?._id !== user?.id) {
            return {
              ...conversationUser,
              userDetails: conversationUser.receiver,
            };
          } else {
            return {
              ...conversationUser,
              userDetails: conversationUser.sender,
            };
          }
        });

        setAllUser(conversationUserData);
      });
    }
  }, [socketConnection, user]);

  return (
    <div className="user-chat-sidebar-s">
      <div className="chat-sidebar-content-s">
        <div>
          <Link to="/" className="messages-chat-p-i">
            <HomeIcon />
          </Link>
          <div
            className="messages-chat-p-i"
            onClick={() => {
              setShowSearchUsersMenu(true);
            }}
          >
            <ExploreIcon />
          </div>
          <Link
            to="/chat"
            className="messages-chat-p-i messages-chat-p-i-active"
          >
            <MessagesIcon />
          </Link>
          <div
            className="messages-chat-p-i"
            onClick={() => {
              setShowChatFriendsMenu(true);
            }}
          >
            <FriendsIcon />
          </div>
        </div>
        <div>
          <div className="messages-chat-p-i" onClick={onUserThemeClick}>
            <ThemeIcon />
          </div>
          <Link to="/profile" className="messages-chat-p-u-img">
            <img src={user?.picture} alt="" />
          </Link>
          <div
            className="messages-chat-p-i"
            onClick={() => {
              logout();
            }}
          >
            <LogoutIcon />
          </div>
        </div>
      </div>
      <div className="p-chat-sidebar-content-s">
        <div className="chat-siebar-messages-heading-s-c">
          <h2 className="chat-siebar-messages-heading-s">Messages</h2>
        </div>
        <div className="chat-s-divider-s"></div>
        <div className="chat-a-m-c-c-s">
          {allUser.length === 0 && (
            <div>
              <div className="c-p-arrow-u-l-s-c">
                <ArrowUpLeftIcon />
              </div>
              <p className="s-s-c-m-p-p-s">
                <span>Namrolink</span> chat
              </p>
              <p className="e-u-s-c-m-p-s">
                Explore users to start a conversation
              </p>
            </div>
          )}
          {allUser.map((conv, index) => {
            return (
              <Link
                to={"/chat/" + conv?.userDetails?._id}
                key={conv?._id}
                className="a-u-chat-s-d-m"
              >
                <div>
                  <img
                    src={conv?.userDetails?.picture}
                    className="a-u-m-c-s-style"
                    alt=""
                  />
                </div>
                <div>
                  <h3 className="user-f-a-l-n-c-s-a-u">
                    {conv?.userDetails?.first_name}{" "}
                    {conv?.userDetails?.last_name}
                  </h3>
                  <div className="last-msg-a-c-s">
                    <div>
                      {conv?.lastMsg?.imageUrl && (
                        <div className="chat-sidebar-last-msg-uploaded-f-s">
                          <span className="chat-sidebar-last-msg-uploaded-f-s-svg-i">
                            <PhotoIcon />
                          </span>
                          {!conv?.lastMsg?.text && <span>Image</span>}
                        </div>
                      )}
                      {conv?.lastMsg?.videoUrl && (
                        <div className="chat-sidebar-last-msg-uploaded-f-s">
                          <span className="chat-sidebar-last-msg-uploaded-f-s-svg-i">
                            <VideoCameraIcon />
                          </span>
                          {!conv?.lastMsg?.text && <span>Video</span>}
                        </div>
                      )}
                    </div>
                    <p className="last-message-t-chat-sidebar-s">
                      {conv?.lastMsg?.text}
                    </p>
                  </div>
                </div>
                {Boolean(conv?.unseenMsg) && (
                  <p className="unseen-messages-number-chat-sidebar">
                    {conv?.unseenMsg}
                  </p>
                )}
              </Link>
            );
          })}
        </div>
      </div>
      {showSearchUsersMenu && (
        <SearchUsers setShowSearchUsersMenu={setShowSearchUsersMenu} />
      )}
      {showChatFriendsMenu && (
        <ChatFriends
          setShowChatFriendsMenu={setShowChatFriendsMenu}
          onlineUsers={onlineUsers}
        />
      )}
    </div>
  );
}
