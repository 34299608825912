import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { follow, unfollow } from "../../functions/user";
import { useState } from "react";

export default function UserCard({ category, userr }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const goToProfile = () => {
    navigate(`/profile/${userr.username}`);
  };
  const followHandler = async () => {
    await follow(userr._id, user.token);
    // Refresh data to reflect changes
  };

  const unfollowHandler = async () => {
    await unfollow(userr._id, user.token);
    setIsVisible(false);
  };

  if (!isVisible) return null; // Render nothing if not visible
  return (
    <div className="friends-card-c-s">
      {category === "following" ? (
        <div className="friend-c-w-i">
          <Link to={`/profile/${userr.username}`}>
            <img src={userr.picture} alt="" />
          </Link>
          <div className="friend-card-d-w">
            <div className="friend-card-d-c">
              <div className="friend-card-n-d-s">
                <h4>
                  {userr.first_name} {userr.last_name}
                </h4>
                <p className="text-muted">
                  <small>@{userr.username}</small>
                </p>
              </div>
              <div className="friends-c-b-c-s">
                <button className="btn-primary" onClick={unfollowHandler}>
                  Unfollow
                </button>
                <Link to={`/chat/${userr._id}`} className="btn">
                  Message
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : category === "followers" ? (
        <div className="friend-c-w-i">
          <Link to={`/profile/${userr.username}`}>
            <img src={userr.picture} alt="" />
          </Link>
          <div className="friend-card-d-w">
            <div className="friend-card-d-c">
              <div className="friend-card-n-d-s">
                <h4>
                  {userr.first_name} {userr.last_name}
                </h4>
                <p className="text-muted">
                  <small>@{userr.username}</small>
                </p>
              </div>
              <div className="friends-c-b-c-s">
                <button className="btn-primary" onClick={goToProfile}>
                  View
                </button>
                <Link to={`/chat/${userr._id}`} className="btn">
                  Message
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
