import "../../styles/style.css";
import "../../styles/general.css";
import "../../styles/left.css";
import "../../styles/mediaquery.css";
import "../../styles/navbar.css";
import "../../styles/right.css";
import "../../styles/theme.css";
import "../../styles/postcontainer.css";
import "./style.css";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header";
import { friendspage } from "../../functions/reducers";
import { getFriendsPageInfos } from "../../functions/user";
import Card from "./Card";
import LeftContainer from "../../components/left/LeftContainer";
import RightContainer from "../../components/right/RightContainer";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import FriendsIcon from "../../components/icons/FriendsIcon";
import FriendGroupIcon from "../../components/icons/FriendGroupIcon";
import AllFriendsIcon from "../../components/icons/AllFriendsIcon";
import FriendRequestsIcon from "../../components/icons/FriendRequestsIcon";
import SentFriendRequests from "../../components/icons/SentFriendRequests";
import ExploreSearchBar from "../../components/middle/ExploreSearchBar";
import FriendsSolidIcon from "../../components/icons/FriendsSolidIcon";
import FriendRequestsSolidIcon from "../../components/icons/FriendRequestsSolidIcon";
import SentFriendRequestsOther from "../../components/icons/SentFriendRequestsOther";
import AllFriendsSolidIcon from "../../components/icons/AllFriendsSolidIcon";
import BottomMenu from "../../components/navigation/BottomMenu";
import CircleMenu from "../../components/navigation/CircleMenu";
import { Helmet } from "react-helmet";
import { ClipLoader } from "react-spinners";

export default function Friends({ setVisible }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);
  const { type } = useParams();

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  const [{ loading, error, data }, dispatch] = useReducer(friendspage, {
    loading: false,
    data: {},
    error: "",
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    dispatch({ type: "FRIENDS_REQUEST" });
    const data = await getFriendsPageInfos(user.token);
    if (data.status === "ok") {
      dispatch({ type: "FRIENDS_SUCCESS", payload: data.data });
    } else {
      dispatch({ type: "FRIENDS_ERROR", payload: data.data });
    }
  };

  return (
    <>
      <Helmet>
        <title>Namrolink - Friends</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Friends"
        />
      </Helmet>
      <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      <div className="main">
        <div className="element_container">
          <div className="left-container-section">
            <LeftContainer
              onMenuItemClick={handleMenuItemClick}
              onThemeClick={() => handleThemeVisibility(true)}
              setVisible={setVisible}
            />
          </div>
          <div className="middle-container-section">
            <div className="middle activeitem">
              <ExploreSearchBar activeMenuItem={activeMenuItem} />
              <div>
                <div className="feeds">
                  <div className="feed zero-margin-top-i-s">
                    <div className="friends-links-wrapper">
                      <div className="friends-links-container">
                        <Link to="/friends" className="friends-link-s">
                          <div className="friends-link-text">
                            {type === undefined ? (
                              <FriendsSolidIcon />
                            ) : (
                              <FriendsIcon />
                            )}
                            Home
                          </div>
                        </Link>
                      </div>
                      <Link
                        to="/friends/requests"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {type === "requests" ? (
                              <FriendRequestsSolidIcon />
                            ) : (
                              <FriendRequestsIcon />
                            )}
                            Requests
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/friends/sent"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {type === "sent" ? (
                              <SentFriendRequestsOther />
                            ) : (
                              <SentFriendRequests />
                            )}
                            Sent
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/friends/all"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {type === "all" ? (
                              <AllFriendsSolidIcon />
                            ) : (
                              <AllFriendsIcon />
                            )}
                            Friends
                          </div>
                        </div>
                      </Link>
                      <Link to="/discover" className="friends-links-container">
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            <FriendGroupIcon />
                            Discover
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  {(type === undefined || type === "requests") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>Friend requests</h4>
                        {type === undefined && (
                          <Link to="/friends/requests">See all</Link>
                        )}
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.requests && data.requests.length === 0 && (
                          <div className="n-friends-a-a-p-s">
                            No friend requests yet
                          </div>
                        )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.requests &&
                          data.requests.map((user) => (
                            <Card
                              userr={user}
                              key={user._id}
                              type="request"
                              getData={getData}
                            />
                          ))}
                      </div>
                    </div>
                  )}

                  {(type === undefined || type === "sent") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>Sent friend requests</h4>
                        {type === undefined && (
                          <Link to="/friends/sent">See all</Link>
                        )}
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.sentRequests &&
                          data.sentRequests.length === 0 && (
                            <div className="n-friends-a-a-p-s">
                              No sent friend requests
                            </div>
                          )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.sentRequests &&
                          data.sentRequests.map((user) => (
                            <Card
                              userr={user}
                              key={user._id}
                              type="sent"
                              getData={getData}
                            />
                          ))}
                      </div>
                    </div>
                  )}

                  {(type === undefined || type === "all") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>Friends</h4>
                        {type === undefined && (
                          <Link to="/friends/all">See all</Link>
                        )}
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.friends && data.friends.length === 0 && (
                          <div className="n-friends-a-a-p-s">
                            No friends added yet
                          </div>
                        )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.friends &&
                          data.friends.map((user) => (
                            <Card
                              userr={user}
                              key={user._id}
                              type="friends"
                              getData={getData}
                            />
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right-container-section">
            <RightContainer />
          </div>
        </div>
      </div>
      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
      <div className="extras-options-home-page">
        <BottomMenu />
      </div>
      <div className="extras-options-circle-home-page">
        <CircleMenu />
      </div>
    </>
  );
}
