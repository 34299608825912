import "../../styles/general.css";
import React, { useEffect, useRef, useState } from "react";
import MusicIcon from "../../components/icons/MusicIcon";
import MusicPlaylistIcon from "../../components/icons/MusicPlaylistIcon";
import PlayPauseIcon from "../../components/icons/PlayPauseIcon";
import PreviousIcon from "../../components/icons/PreviousIcon";
import NextIcon from "../../components/icons/NextIcon";
import RepeatOneIcon from "../../components/icons/RepeatOneIcon";
import VolumeIcon from "../../components/icons/VolumeIcon";
import RepeatAllIcon from "../../components/icons/RepeatAllIcon";
import HomeIcon from "../../components/icons/HomeIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import EcommerceIcon from "../../components/icons/EcommerceIcon";
import DeveloperIcon from "../../components/icons/DeveloperIcon";
import HelpIcon from "../../components/icons/HelpIcon";
import { Link } from "react-router-dom";
import CloseIcon from "../../components/icons/CloseIcon";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import "./style.css";

export default function Music() {
  const { user } = useSelector((state) => ({ ...state }));
  const [currentMusic, setCurrentMusic] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);
  const [isLooping, setIsLooping] = useState(false);
  const [isRepeatAll, setIsRepeatAll] = useState(false); // New state for repeat-all functionality
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaylistVisible, setIsPlaylistVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const audioRef = useRef(new Audio("../../audio/music/music-1.mp3"));
  const seekRef = useRef(null);

  const musicData = [
    {
      backgroundImage: "../../audio/images/poster-1.jpg",
      posterUrl: "../../audio/images/poster-1.jpg",
      title: "Cradles",
      album: "Cradles",
      year: 2019,
      artist: "Sub Urban",
      musicPath: "../../audio/music/music-1.mp3",
    },
    {
      backgroundImage: "../../audio/images/poster-2.jpg",
      posterUrl: "../../audio/images/poster-2.jpg",
      title: "Superhero",
      album: "Superhero",
      year: 2016,
      artist: "Unknown Brain ft. Chris Linton",
      musicPath: "../../audio/music/music-2.mp3",
    },
    {
      backgroundImage: "../../audio/images/poster-3.jpg",
      posterUrl: "../../audio/images/poster-3.jpg",
      title: "Heroes Tonight",
      album: "Heroes Tonight",
      year: 2015,
      artist: "Janji feat. Johnning",
      musicPath: "../../audio/music/music-3.mp3",
    },
    {
      backgroundImage: "../../audio/images/poster-4.jpg",
      posterUrl: "../../audio/images/poster-4.jpg",
      title: "On & On",
      album: "On & On",
      year: 2015,
      artist: "Cartoon, Jéja, Daniel Levi",
      musicPath: "../../audio/music/music-4.mp3",
    },
    {
      backgroundImage: "../../audio/images/poster-5.jpg",
      posterUrl: "../../audio/images/poster-5.jpg",
      title: "Coming Home",
      album: "Coming Home",
      year: 2015,
      artist: "Diviners feat. Philly K",
      musicPath: "../../audio/music/music-5.mp3",
    },
    {
      backgroundImage: "../../audio/images/poster-6.jpg",
      posterUrl: "../../audio/images/poster-6.jpg",
      title: "Turn It Up",
      album: "Turn It Up",
      year: 2019,
      artist: "Tobu",
      musicPath: "../../audio/music/music-6.mp3",
    },
  ];

  useEffect(() => {
    const audio = audioRef.current;
    audio.src = musicData[currentMusic].musicPath;

    const updateDuration = () => {
      setDuration(audio.duration);
    };

    const updateCurrentTime = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnd = () => {
      if (isRepeatAll) {
        skipNext();
      } else {
        setIsPlaying(false);
      }
    };

    audio.addEventListener("loadeddata", updateDuration);
    audio.addEventListener("timeupdate", updateCurrentTime);
    audio.addEventListener("ended", handleEnd); // Handle song end event

    return () => {
      audio.removeEventListener("loadeddata", updateDuration);
      audio.removeEventListener("timeupdate", updateCurrentTime);
      audio.removeEventListener("ended", handleEnd); // Cleanup event listener
    };
  }, [currentMusic, isRepeatAll]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      audio.pause();
    }
  }, [isPlaying, currentMusic]);

  const playMusic = () => {
    const audio = audioRef.current;
    setIsPlaying(!audio.paused);
    if (audio.paused) {
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      audio.pause();
    }
  };

  const changeMusic = (index) => {
    const audio = audioRef.current;
    audio.pause();
    setCurrentMusic(index);
    setIsPlaying(true);
    audio.src = musicData[index].musicPath;
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
    });
  };

  const skipNext = () => {
    if (isShuffled) {
      const randomIndex = Math.floor(Math.random() * musicData.length);
      changeMusic(randomIndex);
    } else {
      const nextIndex = (currentMusic + 1) % musicData.length;
      changeMusic(nextIndex);
    }
  };

  const skipPrev = () => {
    if (isShuffled) {
      const randomIndex = Math.floor(Math.random() * musicData.length);
      changeMusic(randomIndex);
    } else {
      const prevIndex =
        (currentMusic - 1 + musicData.length) % musicData.length;
      changeMusic(prevIndex);
    }
  };

  const toggleShuffle = () => {
    setIsShuffled(!isShuffled);
  };

  const toggleRepeat = () => {
    const audio = audioRef.current;
    audio.loop = !audio.loop;
    setIsLooping(audio.loop);
  };

  const toggleRepeatAll = () => {
    setIsRepeatAll(!isRepeatAll);
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleSeekChange = (event) => {
    const newTime = parseFloat(event.target.value);
    setCurrentTime(newTime);
    audioRef.current.currentTime = newTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const togglePlaylistVisibility = () => {
    setIsPlaylistVisible(!isPlaylistVisible);
  };

  const closePlaylist = () => {
    setIsPlaylistVisible(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMusicData = musicData.filter(
    (music) =>
      music.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      music.album.toLowerCase().includes(searchTerm.toLowerCase()) ||
      music.artist.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setIsSearching(true);
    const timeoutId = setTimeout(() => {
      setIsSearching(false);
    }, 500); // Simulating a delay for the preloader

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  return (
    <div>
      <Helmet>
        <title>Namrolink - Music</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan connections, Music"
        />
      </Helmet>
      <div>
        <style jsx>{`
          :root {
            --eerie-black_a95: hsla(204, 9%, 11%, 0.95);
            --eerie-black_a50: hsla(204, 9%, 11%, 0.5);
            --eerie-black: hsl(204, 9%, 11%);
            --gainsboro: hsl(225, 7%, 89%);
            --charcoal: hsl(203, 9%, 28%);
            --silver-sand: hsl(208, 12%, 78%);
            --light-sky-blue: hsl(200, 100%, 73%);
            --prussian-blue: hsl(196, 100%, 14%);
            --black: hsl(0, 0%, 0%);
            --black_a50: hsla(0, 0%, 0%, 0.5);
            --black_a20: hsla(0, 0%, 0%, 0.2);
            --light-sky-blue_a8: hsla(200, 100%, 73%, 0.08);
            --light-sky-blue_a12: hsla(200, 100%, 73%, 0.12);
            --silver-sand_a8: hsla(208, 12%, 78%, 0.08);
            --silver-sand_a12: hsla(208, 12%, 78%, 0.12);

            --background: var(--eerie-black);
            --background-opacity: var(--eerie-black_a95);
            --on-background: var(--gainsboro);
            --surface-variant: var(--charcoal);
            --on-surface-variant: var(--silver-sand);
            --on-surface-variant-hover: var(--light-sky-blue_a8);
            --on-surface-variant-focus: var(--light-sky-blue_a12);
            --primary: var(--light-sky-blue);
            --on-primary: var(--prussian-blue);

            --gradient: linear-gradient(
              180deg,
              hsla(204, 9%, 11%, 0.9) 60%,
              transparent 120%
            );

            --ff-inter: "Inter", sans-serif;

            --headline-sm: 2.4rem;
            --title-lg: 2.2rem;
            --label-lg: 1.4rem;
            --label-md: 1.2rem;

            --fw-400: 400;
            --fw-500: 500;

            --shadow-1: 0 1px 4px 1px var(--black_a20);
            --shadow-2: 0 1px 4px 1px var(--black_a50);

            --radius-24: 24px;
            --radius-16: 16px;
            --radius-12: 12px;
            --radius-pill: 100px;
            --radius-circle: 50%;

            --transition-1: 200ms cubic-bezier(0.2, 0, 0, 1);
            --transition-2: 300ms cubic-bezier(0.2, 0, 0, 1);
          }

          *,
          *::before,
          *::after {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          li {
            list-style: none;
          }

          a,
          img,
          span,
          input,
          button {
            display: block;
          }

          a {
            text-decoration: none;
            color: inherit;
          }

          img {
            height: auto;
          }

          input,
          button {
            background: none;
            border: none;
            font: inherit;
          }

          input {
            width: 100%;
          }

          button {
            cursor: pointer;
          }

          html {
            font-family: var(--ff-inter);
            font-size: 10px !important;
            scroll-behavior: smooth;
          }

          body {
            background-color: var(--black);
            color: var(--on-background);
            font-size: 1.6rem !important;
            line-height: 1.5;
            min-height: 100vh;
            min-width: 250px;
            background-size: 150%;
            background-repeat: no-repeat;
            background-position: top;
            overflow: overlay;
          }

          body.modalActive {
            overflow: hidden;
          }

          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: var(--radius-pill);
          }

          body:hover::-webkit-scrollbar-thumb {
            background-color: var(--surface-variant);
          }

          .material-symbols-rounded {
            font-variation-settings: "FILL" 0, "wght" 400, "GRAD" -25, "opsz" 24;
            width: 1em;
            height: 1em;
            overflow: hidden;
            user-select: none;
          }

          .wrapper-m {
            display: flex;
            align-items: center;
          }

          .title-lg {
            font-size: var(--title-lg) !important;
            font-weight: var(--fw-400);
          }

          .btn-icon {
            color: var(--on-surface-variant);
            width: 40px;
            height: 40px;
            display: grid;
            place-items: center;
            border-radius: var(--radius-circle);
          }

          .btn-icon:hover {
            background-color: var(--on-surface-variant-hover);
          }

          .btn-icon:is(:focus, :focus-visible) {
            background-color: var(--on-surface-variant-focus);
          }

          .img-cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .headline-sm {
            font-size: var(--headline-sm) !important;
            font-weight: var(--fw-400);
          }

          .label-lg,
          .label-md {
            font-weight: var(--fw-500);
          }

          .label-lg {
            font-size: var(--label-lg) !important;
            letter-spacing: 0.1px;
          }

          .label-md {
            font-size: var(--label-md) !important;
            letter-spacing: 0.5px;
          }

          .top-bar {
            position: fixed;
            top: 0;
            left: 0;
            background-color: var(--background-opacity);
            min-width: 250px;
            width: 100%;
            height: 64px;
            padding-inline: 16px;
            justify-content: space-between;
            backdrop-filter: blur(50px);
            box-shadow: var(--shadow-1);
            z-index: 2;
          }

          .logo-m {
            gap: 12px;
          }

          .volume {
            display: none;
          }

          .player {
            --padding: 24px;
            background-image: var(--gradient);
            padding: var(--padding);
            padding-block-start: calc(64px + var(--padding));
            min-height: 100vh;
            display: flex;
            text-align: center;
            backdrop-filter: blur(30px);
            overflow: hidden;
          }

          .player .container-music {
            width: 100%;
            display: grid;
            grid-template-rows: 1fr max-content;
            gap: 24px;
          }

          .music-banner {
            background-color: var(--surface-variant);
            max-width: 400px;
            margin-inline: auto;
            align-self: center;
            overflow: hidden;
            border-radius: var(--radius-24);
          }

          .label-wrapper {
            justify-content: center;
            opacity: 0.8;
            margin-block: 8px 4px;
          }

          .label-wrapper span:first-child::after {
            content: "|";
            display: inline-block;
            margin-inline: 4px;
          }

          .artist {
            opacity: 0.6;
            margin-block-end: 36px;
          }

          .seek-control {
            margin-block-end: 20px;
          }

          .range-wrapper {
            position: relative;
          }

          .range {
            appearance: none;
            cursor: pointer;
          }

          .range::-webkit-slider-runnable-track {
            appearance: none;
            background-color: var(--surface-variant);
            height: 6px;
            width: 100%;
            border-radius: var(--radius-pill);
          }

          .range-fill {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 6px;
            background-color: var(--color-primary);
            border-radius: var(--radius-pill);
            pointer-events: none;
          }

          .range::-webkit-slider-thumb {
            appearance: none;
            background-color: var(--color-primary);
            width: 16px;
            height: 16px;
            margin-block-start: -5px;
            border-radius: var(--radius-pill);
            transition: var(--transition-1);
          }

          .range::-webkit-slider-thumb:hover {
            box-shadow: 0 0 0 12px var(--on-surface-variant-hover);
          }

          .range::-webkit-slider-thumb:is(:focus, :focus-visible) {
            box-shadow: 0 0 0 12px var(--on-surface-variant-focus);
          }

          .duration-label {
            justify-content: space-between;
            margin-block-start: 12px;
          }

          .player-control {
            justify-content: space-evenly;
          }

          .player-control .toggle.active-m {
            color: var(--primary);
          }

          .player-control .play {
            background-color: var(--surface-variant);
            color: var(--primary);
          }

          .player-control .play.active-m {
            background-color: var(--primary);
            color: var(--on-primary);
          }

          .player-control .btn-icon.active-m .default-icon,
          .player-control .btn-icon .active-icon {
            display: none;
          }

          .player-control .btn-icon .default-icon,
          .player-control .btn-icon.active-m .active-icon {
            display: block;
          }

          .playlist {
            position: fixed;
            top: 0;
            right: -180px;
            width: 180px;
            height: 100vh;
            background-color: var(--background);
            padding: 28px;
            border-top-left-radius: var(--radius-16);
            border-bottom-left-radius: var(--radius-16);
            box-shadow: var(--shadow-2);
            overflow-y: overlay;
            visibility: hidden;
            transition: var(--transition-2);
            z-index: 4;
          }

          .playlist:hover::-webkit-scrollbar-thumb {
            background-color: var(--surface-variant);
          }

          .playlist.active-m {
            transform: translateX(-180px);
            visibility: visible;
          }

          .music-list {
            display: grid;
            gap: 20px;
          }

          .music-item {
            position: relative;
            border-radius: var(--radius-12);
            background-color: var(--surface-variant);
            overflow: hidden;
          }

          .music-item .item-icon {
            position: absolute;
            inset: 0;
            background-color: var(--eerie-black_a50);
            display: grid;
            place-items: center;
            opacity: 0;
          }

          .music-item.playing .item-icon {
            opacity: 1;
          }

          .music-item .item-icon .material-symbols-rounded {
            color: var(--color-primary);
            font-size: 3rem !important;
          }

          .music-item:is(:hover, :focus-visible, :active, .playing) {
            outline: 1px solid var(--color-primary) !important;
          }

          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: var(--black);
            opacity: 0;
            pointer-events: none;
            transition: var(--transition-2);
            z-index: 3;
          }

          .overlay.active-m {
            pointer-events: all;
            opacity: 0.5;
          }

          @media (min-width: 575px) {
            body {
              background-size: 100%;
            }

            .player {
              justify-content: center;
            }

            .player .container-music {
              max-width: 540px;
            }
          }

          @media (min-width: 992px) {
            :root {
              --headline-sm: 4.2rem;
              --label-lg: 2.2rem;
            }

            body {
              background-size: 40% 100%;
              background-position: left center;
            }

            .article-m {
              display: flex;
            }

            .btn-icon {
              width: 54px;
              height: 54px;
            }

            .btn-icon .material-symbols-rounded {
              font-size: 2.8rem !important;
            }

            .top-bar-actions {
              display: none;
            }

            .player {
              --padding: 48px;
              text-align: left;
              flex-grow: 1;
              align-items: center;
              backdrop-filter: blur(100px);
            }

            .player .container-music {
              max-width: 1200px;
              grid-template-columns: 0.8fr 1fr;
              grid-template-rows: 1fr;
              gap: 48px;
              max-height: 500px;
              height: 100%;
            }

            .music-banner {
              aspect-ratio: unset;
              max-width: max-content;
              width: 100%;
              height: 100%;
            }

            .music-content {
              display: flex;
              flex-direction: column;
              padding-block-start: 48px;
              min-width: 100%;
            }

            .music-content :is(.headline-sm, .label-lg, .label-md) {
              max-width: 85%;
            }

            .label-wrapper {
              justify-content: flex-start;
              margin-block-end: 8px;
            }

            .artist {
              --label-md: 1.8rem;
            }

            .seek-control {
              margin-block-start: auto;
              display: grid;
              grid-template-columns: 1fr 150px;
              align-items: center;
              gap: 24px;
            }

            .volume {
              display: flex;
              align-items: center;
              gap: 4px;
              margin-block-start: -30px;
            }

            .volume .btn-icon {
              flex-shrink: 0;
            }

            .volume .range-fill {
              width: 100%;
            }

            .player-control {
              margin-inline-end: 174px;
              min-width: max-content;
              gap: 8px;
            }

            .overlay {
              display: none;
            }

            .playlist {
              position: static;
              visibility: visible;
              border-radius: 0;
              box-shadow: none;
              flex-shrink: 0;
            }

            .playlist.active-m {
              transform: unset;
            }

            .music-item:is(:hover, :focus-visible, :active, .playing) {
              outline: 2px solid var(--primary);
            }

            .music-item .item-icon .material-symbols-rounded {
              font-size: 3.6rem !important;
            }
          }

          @media (min-width: 1200px) {
            .playlist {
              padding: 32px;
              width: 220px;
            }

            .music-list {
              gap: 28px;
            }
          }
          .my-playlist-icon-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .my-playlist-icon-section svg {
            height: 2rem;
            width: 2rem;
          }
          .changeable-playlist-icon {
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          .playlist-mobile-closer {
            @media screen and (min-width: 768px) {
              display: none;
            }
          }
          .playlist-mb-spacer {
            margin-bottom: 6px;
          }
          .music-page-search-bar {
            display: flex;
            background: var(--color-light);
            border-radius: 8px;
            margin-bottom: 14px;
          }
          .music-page-search-bar input[type="search"] {
            background: transparent;
            width: 100%;
            color: var(--color-dark);
          }
          .music-page-search-bar input[type="search"]::placeholder {
            color: var(--color-gray);
            padding-left: 3px;
            display: none;
          }
          .loader-container-m-p {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .music-list-m-i-details {
            word-break: break-word;
            overflow-wrap: break-word;
          }
          .music-list-title-d {
            font-weight: 600;
            font-size: 1.4rem;
          }
          .music-list-artist-d {
            color: var(--silver-sand);
            font-weight: 200;
            font-size: 1.1rem;
          }
          .music-list-year-d {
            color: var(--silver-sand);
            text-align: right;
            font-weight: 200;
            font-size: 1rem;
          }
          .p-o-p-i-s-m svg {
            color: var(--color-dark);
          }
        `}</style>
        <div className="top-bar wrapper-m">
          <div className="logo-m wrapper-m">
            <span className="material-symbols-rounded">
              <MusicIcon />
            </span>

            <h1 className="title-lg">Namrolink music</h1>
          </div>

          <div className="top-bar-actions">
            <button
              className="btn-icon"
              data-playlist-toggler
              onClick={togglePlaylistVisibility}
            >
              <span className="material-symbols-rounded">
                <MusicPlaylistIcon />
              </span>
            </button>
          </div>
        </div>

        <div className="main-for-music">
          <div className="article-m">
            <div className="player">
              <div className="container-music">
                <figure className="music-banner">
                  <img
                    src={musicData[currentMusic].posterUrl}
                    width="800"
                    height="800"
                    alt={`${musicData[currentMusic].title} Album Poster`}
                    className="img-cover"
                    data-player-banner
                  />
                </figure>

                <div className="music-content">
                  <h2 className="headline-sm" data-title>
                    {musicData[currentMusic].title}
                  </h2>

                  <p className="label-lg label-wrapper wrapper-m">
                    <span data-album>{musicData[currentMusic].album}</span>

                    <span data-year>{musicData[currentMusic].year}</span>
                  </p>

                  <p className="label-md artist" data-artist>
                    {musicData[currentMusic].artist}
                  </p>

                  <div className="seek-control">
                    <div>
                      <div className="range-wrapper">
                        <input
                          type="range"
                          step="1"
                          min="0"
                          max={duration}
                          value={currentTime}
                          className="range"
                          data-range
                          data-seek
                          ref={seekRef}
                          onChange={handleSeekChange}
                        />

                        <div
                          className="range-fill"
                          data-range-fill
                          style={{
                            width: `${(currentTime / duration) * 100}%`,
                          }}
                        ></div>
                      </div>

                      <div className="duration-label wrapper-m">
                        <span className="label-md" data-running-time>
                          {formatTime(currentTime)}
                        </span>

                        <span className="label-md" data-duration>
                          {formatTime(duration)}
                        </span>
                      </div>
                    </div>

                    <div className="volume">
                      <button
                        className="btn-icon"
                        data-volume-btn
                        onClick={() => setVolume(volume === 0 ? 1 : 0)}
                      >
                        <span className="material-symbols-rounded">
                          <VolumeIcon />
                        </span>
                      </button>

                      <div className="range-wrapper">
                        <input
                          type="range"
                          step="0.05"
                          max="1"
                          value={volume}
                          className="range"
                          data-range
                          data-volume
                          onChange={handleVolumeChange}
                        />

                        <div
                          className="range-fill"
                          data-range-fill
                          style={{ width: `${volume * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="player-control wrapper-m">
                    <button
                      className={`btn-icon toggle ${isLooping ? "active" : ""}`}
                      data-repeat
                      onClick={toggleRepeat}
                    >
                      <span className="material-symbols-rounded default-icon">
                        <RepeatOneIcon />
                      </span>
                      <span className="material-symbols-rounded active-icon">
                        <RepeatOneIcon />
                      </span>
                    </button>

                    <button
                      className="btn-icon"
                      data-skip-prev
                      onClick={skipPrev}
                    >
                      <span className="material-symbols-rounded">
                        <PreviousIcon />
                      </span>
                    </button>

                    <button
                      className="btn-icon play"
                      data-play-btn
                      onClick={playMusic}
                    >
                      <span className="material-symbols-rounded default-icon p-o-p-i-s-m">
                        <PlayPauseIcon />
                      </span>
                    </button>

                    <button
                      className="btn-icon"
                      data-skip-next
                      onClick={skipNext}
                    >
                      <span className="material-symbols-rounded">
                        <NextIcon />
                      </span>
                    </button>

                    <button
                      className={`btn-icon toggle ${
                        isRepeatAll ? "active" : ""
                      }`}
                      data-repeat-all
                      onClick={toggleRepeatAll}
                    >
                      <span className="material-symbols-rounded">
                        <RepeatAllIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`playlist ${isPlaylistVisible ? "active-m" : ""}`}
              data-playlist
            >
              <div className="my-playlist-icon-section">
                <Link to="/">
                  <HomeIcon />
                </Link>
                <Link to="/profile">
                  <ProfileIcon />
                </Link>
                <Link to="/chat">
                  <MessagesIcon />
                </Link>
              </div>
              <div className="playlist-mb-spacer"></div>
              <div className="my-playlist-icon-section">
                <Link to="/help">
                  <HelpIcon />
                </Link>
                <a
                  href="https://www.namronet.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EcommerceIcon />
                </a>
                <Link className="changeable-playlist-icon" to="/developer">
                  <DeveloperIcon />
                </Link>
                <div
                  className="playlist-mobile-closer"
                  onClick={togglePlaylistVisibility}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="playlist-mb-spacer"></div>
              <div className="music-page-search-bar">
                <input
                  type="search"
                  name="search"
                  className="music-page-search-input"
                  placeholder="Search music"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="playlist-mb-spacer"></div>
              <ul className="music-list" data-music-list>
                {isSearching ? (
                  <div className="loader-container-m-p">
                    <HashLoader color="#1876f2" />
                  </div>
                ) : filteredMusicData.length > 0 ? (
                  filteredMusicData.map((music, index) => (
                    <li key={index}>
                      <button
                        className={`music-item ${
                          index === currentMusic ? "playing" : ""
                        }`}
                        data-playlist-toggler
                        data-playlist-item={index}
                        onClick={() =>
                          changeMusic(
                            musicData.findIndex((m) => m.title === music.title)
                          )
                        }
                      >
                        <img
                          src={music.posterUrl}
                          width="80"
                          height="80"
                          alt={`${music.title} Album Poster`}
                          className="img-cover"
                          loading="lazy"
                        />

                        <div className="item-icon">
                          <span className="material-symbols-rounded">
                            <VolumeIcon />
                          </span>
                        </div>
                      </button>
                      <div className="music-list-m-i-details">
                        <div className="music-list-title-d">{music.title}</div>
                        <div className="music-list-artist-d">
                          {music.artist}
                        </div>
                        <div className="music-list-year-d">{music.year}</div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="no-results-found-m-p">No results found</div>
                )}
              </ul>
              <div className="playlist-mb-spacer"></div>
              <div className="playlist-mb-spacer"></div>
            </div>

            <div
              className={`overlay ${isPlaylistVisible ? "active-m" : ""}`}
              data-playlist-toggler
              data-overlay
              onClick={closePlaylist}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
