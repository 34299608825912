import { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import useClickOutside from "../../helpers/clickOutside";
import getCroppedImg from "../../helpers/getCroppedImg";
import { uploadImages } from "../../functions/uploadImages";
import { useSelector } from "react-redux";
import { updateCover } from "../../functions/user";
import { createPost } from "../../functions/post";
import PulseLoader from "react-spinners/PulseLoader";
import OldCovers from "./OldCovers";
import CameraSolidIcon from "../../components/icons/CameraSolidIcon";
import UploadIcon from "../../components/icons/UploadIcon";
import PhotoIcon from "../../components/icons/PhotoIcon";
import ReportIcon from "../../components/icons/ReportIcon";
import BlockIcon from "../../components/icons/BlockIcon";
import GlobeIcon from "../../components/icons/GlobeIcon";
import ThreeDotsVertical from "../../components/icons/ThreeDotsVertical";
import ThreeBarsIcon from "../../components/icons/ThreeBarsIcon";
import FeedbackPopup from "../../components/feedbackPopup";

export default function Cover({ cover, visitor, photos, profile }) {
  const [showCoverMenu, setShowCoverMenu] = useState(false);
  const [showAdvancedMenu, setShowAdvancedMenu] = useState(false);
  const [coverPicture, setCoverPicture] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));
  const menuRef = useRef(null);
  const advanedMenuRef = useRef(null);
  const refInput = useRef(null);
  const cRef = useRef(null);
  useClickOutside(menuRef, () => setShowCoverMenu(false));
  useClickOutside(advanedMenuRef, () => setShowAdvancedMenu(false));
  const [error, setError] = useState("");
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const handleImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      setShowCoverMenu(false);
      return;
    }
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`${file.name} format is not supported.`);
      setShowCoverMenu(false);
      return;
    } else if (file.size > 1024 * 1024 * 7) {
      setError(`${file.name} is too large. The maximum allowed size is 7MB`);
      setShowCoverMenu(false);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setCoverPicture(event.target.result);
    };
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Restrict movement to vertical only
  const handleCropChange = (newCrop) => {
    setCrop({ x: 0, y: newCrop.y });
  };

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(coverPicture, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setCoverPicture(img);
        } else {
          return img;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [croppedAreaPixels]
  );
  const coverRef = useRef(null);
  const [width, setWidth] = useState();
  useEffect(() => {
    setWidth(coverRef.current.clientWidth);
  }, [window.innerWidth]);

  const updateCoverPicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage();
      let blob = await fetch(img).then((b) => b.blob());
      const path = `namrolink-social-media/${user.username}/cover_pictures`;
      let formData = new FormData();
      formData.append("file", blob);
      formData.append("path", path);
      const res = await uploadImages(formData, path, user.token);
      const updated_picture = await updateCover(res[0].url, user.token);
      if (updated_picture === "ok") {
        const new_post = await createPost(
          "coverPicture",
          null,
          null,
          res,
          null,
          user.id,
          user.token
        );
        if (new_post.status === "ok") {
          setLoading(false);
          setCoverPicture("");
          cRef.current.src = res[0].url;
        } else {
          setLoading(false);
          setError(new_post);
        }
      } else {
        setLoading(false);
        setError(updated_picture);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };

  return (
    <div className="profile-cover-photo-container" ref={coverRef}>
      {coverPicture && (
        <div className="save-changes-cover">
          <div className="save-changes-left">
            <i className="u-l-n-p-p-s-i">
              <GlobeIcon />
            </i>
            Your cover photo is public
          </div>
          <div className="save-changes-right">
            <button
              className="p-p-blue_btn-cancel"
              onClick={() => setCoverPicture("")}
            >
              Cancel
            </button>
            <button
              className="p-p-blue_btn"
              onClick={() => updateCoverPicture()}
            >
              {loading ? <PulseLoader color="#fff" size={5} /> : "Save changes"}
            </button>
          </div>
        </div>
      )}
      <input
        type="file"
        ref={refInput}
        hidden
        accept="image/jpeg,image/png,image/webp,image/gif"
        onChange={handleImage}
      />
      {error && (
        <div className="postError comment_error">
          <div className="postError_error">{error}</div>
          <button className="p-blue_btn" onClick={() => setError("")}>
            Try again
          </button>
        </div>
      )}
      {coverPicture && (
        <div className="cover-crooper">
          <Cropper
            image={coverPicture}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={handleCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={true}
            objectFit="horizontal-cover"
          />
        </div>
      )}
      {cover && !coverPicture && (
        <img src={cover} className="cover" alt="" ref={cRef} />
      )}
      {!visitor && (
        <div className="update-cover-wrapper">
          <div
            className="open-cover-update"
            onClick={() => setShowCoverMenu((prev) => !prev)}
          >
            <i className="camera-filled-icon">
              <CameraSolidIcon />
            </i>
            Add cover photo
          </div>
          {showCoverMenu && (
            <div className="open-cover-menu" ref={menuRef}>
              <div
                className="open-cover-menu-item hover1-m-p"
                onClick={() => setShow(true)}
              >
                <i className="profile-a-c-m-p-i-s">
                  <PhotoIcon />
                </i>
                Select photo
              </div>
              <div
                className="open-cover-menu-item hover1-m-p"
                onClick={() => refInput.current.click()}
              >
                <i className="profile-a-c-m-p-i-s">
                  <UploadIcon />
                </i>
                Upload photo
              </div>
            </div>
          )}
        </div>
      )}

      {visitor && (
        <div className="update-cover-wrapper">
          <div
            className="open-cover-update"
            onClick={() => setShowAdvancedMenu((prev) => !prev)}
          >
            <i className="camera-filled-icon">
              <ThreeDotsVertical />
            </i>
          </div>
          {showAdvancedMenu && (
            <div className="open-cover-menu" ref={advanedMenuRef}>
              <div className="open-cover-menu-item hover1-m-p d-n-a-o-s-p">
                <i className="profile-a-c-m-p-i-s">
                  <BlockIcon />
                </i>
                Block user
              </div>
              <div
                className="open-cover-menu-item hover1-m-p"
                onClick={() => openFeedbackPopup()}
              >
                <i className="profile-a-c-m-p-i-s">
                  <ReportIcon />
                </i>
                Report user
              </div>
            </div>
          )}
        </div>
      )}

      {show && (
        <OldCovers
          photos={photos}
          setCoverPicture={setCoverPicture}
          setShow={setShow}
        />
      )}
      {isFeedbackPopupOpen && (
        <FeedbackPopup
          subject={`Account profile of id: ${profile._id} (${profile.username}) reported`}
          onClose={closeFeedbackPopup} // Pass a function to close the popup
        />
      )}
    </div>
  );
}
