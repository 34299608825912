import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import FontSizeIcon from "../../icons/FontSizeIcon";
import PageColorIcon from "../../icons/PageColorIcon";
import ButtonColorIcon from "../../icons/ButtonColorIcon";
import PreviousImageIcon from "../../icons/PreviousImageIcon";
import BackgroundIcon from "../../icons/BackgroundIcon";

export default function DisplayAccessibility({ setVisible }) {
  const dispatch = useDispatch();
  const { darkTheme } = useSelector((state) => ({ ...state }));

  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontSize") || "16px"
  );
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primaryColor") || "252"
  );
  const [buttonColor, setButtonColor] = useState(
    localStorage.getItem("buttonColor") || "252"
  );
  const [background, setBackground] = useState(
    JSON.parse(localStorage.getItem("background")) || [
      ["--color-card", "100%"],
      ["--color-bg", "95%"],
      ["--color-font", "17%"],
    ]
  );

  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
    localStorage.setItem("fontSize", fontSize);
  }, [fontSize]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color-hue",
      primaryColor
    );
    localStorage.setItem("primaryColor", primaryColor);
  }, [primaryColor]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--btn-primary-color-hue",
      buttonColor
    );
    localStorage.setItem("buttonColor", buttonColor);
  }, [buttonColor]);

  useEffect(() => {
    background.forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
    localStorage.setItem("background", JSON.stringify(background));
  }, [background]);

  const handleFontSizeChange = (size) => {
    setFontSize(`${size}px`);
  };

  const handlePrimaryColorChange = (hue) => {
    setPrimaryColor(hue);
  };

  const handleButtonColorChange = (hue) => {
    setButtonColor(hue);
  };

  const handleBackgroundChange = (rootVars, colors) => {
    const newBackground = rootVars.map((variable, idx) => [
      variable,
      colors[idx],
    ]);
    setBackground(newBackground);
  };

  return (
    <div className="absolute_wrap">
      <div className="absolute_wrap_header">
        <div
          className="circle d-a-a-a-i-s hover1-m-p"
          onClick={() => {
            setVisible(0);
          }}
        >
          <i className="arrow-back-icon-u-m-s">
            <PreviousImageIcon />
          </i>
        </div>
        Display settings
      </div>
      <div className="mmenu_item">
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <FontSizeIcon />
          </i>
        </div>
        <span>Font size</span>
      </div>

      <div className="card-container-header-m">
        <div className="font-size-header-m">
          <div>
            <h6>Aa</h6>
            <div className="choose-size-header-m">
              {[14, 16, 18, 20, 22].map((size) => (
                <span
                  key={size}
                  onClick={() => handleFontSizeChange(size)}
                  className={`font-size-${size / 2} ${
                    fontSize === `${size}px` ? "activeitem" : ""
                  }`}
                ></span>
              ))}
            </div>
            <h3>Aa</h3>
          </div>
        </div>
      </div>

      <div className="mmenu_item">
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <PageColorIcon />
          </i>
        </div>
        <span>Page color</span>
      </div>

      <div className="card-container-header-m">
        <div className="color-header-m">
          <div className="choose-color-header-m">
            {[252, 52, 352, 152, 202].map((hue, idx) => (
              <span
                key={hue}
                onClick={() => handlePrimaryColorChange(hue)}
                className={`color-${idx + 1} ${
                  primaryColor === `${hue}` ? "activeitem" : ""
                }`}
              ></span>
            ))}
          </div>
        </div>
      </div>

      <div className="mmenu_item">
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <ButtonColorIcon />
          </i>
        </div>
        <span>Button Color</span>
      </div>

      <div className="card-container-header-m">
        <div className="color-header-m">
          <div className="choose-color-header-m">
            {[252, 52, 352, 152, 202].map((hue, idx) => (
              <span
                key={hue}
                onClick={() => handleButtonColorChange(hue)}
                className={`color-${idx + 1} ${
                  buttonColor === `${hue}` ? "activeitem" : ""
                }`}
              ></span>
            ))}
          </div>
        </div>
      </div>

      <div className="mmenu_item">
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <BackgroundIcon />
          </i>
        </div>
        <span>Background</span>
      </div>

      <div className="card-container-header-m">
        <div className="background-header-m">
          <div className="choose-bg-header-m">
            {[
              ["100%", "95%", "17%"],
              ["20%", "15%", "90%"],
              ["10%", "0%", "90%"],
            ].map((colors, idx) => (
              <div
                key={idx}
                onClick={() =>
                  handleBackgroundChange(
                    ["--color-card", "--color-bg", "--color-font"],
                    colors
                  )
                }
                className={`bg-${idx + 1}-h-m ${
                  JSON.stringify(background.map((item) => item[1])) ===
                  JSON.stringify(colors)
                    ? "activeitem"
                    : ""
                }`}
              >
                <span></span>
                <h5>{idx === 0 ? "Light" : idx === 1 ? "Dim" : "Dark"}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="header-menu-spacing-element-s"></div>
    </div>
  );
}
