import { useSelector } from "react-redux";
import "./style.css";
import { useEffect, useState } from "react";
import Header from "../../components/header";
import DeveloperIcon from "../../components/icons/DeveloperIcon";
import HomeIcon from "../../components/icons/HomeIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import FriendsIcon from "../../components/icons/FriendsIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import HelpIcon from "../../components/icons/HelpIcon";
import MusicIcon from "../../components/icons/MusicIcon";
import FriendGroupIcon from "../../components/icons/FriendGroupIcon";
import NewspaperIcon from "../../components/icons/NewspaperIcon";
import EcommerceIcon from "../../components/icons/EcommerceIcon";
import ShieldCheckIcon from "../../components/icons/ShieldCheckIcon";
import LogInIcon from "../../components/icons/LogInIcon";
import TablePlusIcon from "../../components/icons/TablePlusIcon";
import { Link, useNavigate } from "react-router-dom";
import ErrorTriangleOutline from "../../components/icons/ErrorTriangleOutline";
import NotLoggedInHeader from "../../components/header/NotLoggedInHeader";
import CircleMenu from "../../components/navigation/CircleMenu";
import { Helmet } from "react-helmet";

export default function Error({ setVisible }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);
  const [showErrorPageMessage, setShowErrorPageMessage] = useState(true);
  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorPageMessage(false);
    }, 5000); // Hide after 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);
  return (
    <div>
      <Helmet>
        <title>Namrolink - Error</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Error"
        />
      </Helmet>
      {user ? (
        <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      ) : (
        <NotLoggedInHeader />
      )}
      <div className="help-page-main">
        <div className="help-page-element_container">
          <div className="help-page-main-center">
            <div className="error-page-popup-container-s">
              {showErrorPageMessage && (
                <div className="error-page-error-top-r-m">
                  An error has occured
                </div>
              )}
            </div>
            <h3 className="text-muted">404 Error</h3>
            <div>
              <div className="other-col-wrapper">
                <div className="col-wrapper" style={{ alignItems: "start" }}>
                  <div className="h-c-help-box">
                    <div>
                      <h2>
                        Namrolink{" "}
                        <span
                          style={{
                            color: "var(--color-primary)",
                            fontWeight: "bold",
                          }}
                        >
                          Error
                        </span>
                      </h2>
                      <div className="error-page-error-icon-big">
                        <ErrorTriangleOutline />
                      </div>
                      <div className="help-page-developer-section">
                        <h3>Hey Buddy</h3>

                        <p
                          style={{ fontSize: "0.95rem", marginBottom: "15px" }}
                        >
                          We cannot seem to find the page you are looking for.
                        </p>
                        <button
                          className="error-box-page-button"
                          onClick={() => navigate("/")}
                        >
                          <div className="help-contact-us-submit-button-div">
                            <i>
                              <HomeIcon />
                            </i>
                            Back home
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="h-c-help-box">
                    <div>
                      <h2>Namrolink Links</h2>

                      <div className="help-bottom-link-wrapper">
                        <div className="help-bottom-link-container">
                          <Link to="/" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <HomeIcon />
                              Home
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link to="/chat" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <MessagesIcon />
                              Chat
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link to="/profile" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <ProfileIcon />
                              Profile
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link to="/friends" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <FriendsIcon />
                              Friends
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link
                            to="/discover"
                            className="help-page-bottom-link"
                          >
                            <p className="help-page-bottom-link-texts">
                              <FriendGroupIcon />
                              Discover
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link to="/help" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <HelpIcon />
                              Help
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link to="/music" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <MusicIcon />
                              Music
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link
                            to="/discover/feed"
                            className="help-page-bottom-link"
                          >
                            <p className="help-page-bottom-link-texts">
                              <NewspaperIcon />
                              Feed
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <Link
                            to="/developer"
                            className="help-page-bottom-link"
                          >
                            <p className="help-page-bottom-link-texts">
                              <DeveloperIcon />
                              Developer
                            </p>
                          </Link>
                        </div>

                        <div className="help-bottom-link-container">
                          <a
                            href="https://www.namronet.com/"
                            className="help-page-bottom-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="help-page-bottom-link-texts">
                              <EcommerceIcon />
                              Namronet
                            </p>
                          </a>
                        </div>
                        {!user && (
                          <div className="help-bottom-link-container">
                            <Link to="/" className="help-page-bottom-link">
                              <p className="help-page-bottom-link-texts">
                                <LogInIcon />
                                Login
                              </p>
                            </Link>
                          </div>
                        )}

                        {!user && (
                          <div className="help-bottom-link-container">
                            <Link to="/" className="help-page-bottom-link">
                              <p className="help-page-bottom-link-texts">
                                <TablePlusIcon />
                                Register
                              </p>
                            </Link>
                          </div>
                        )}

                        <div className="help-bottom-link-container">
                          <Link to="/" className="help-page-bottom-link">
                            <p className="help-page-bottom-link-texts">
                              <ShieldCheckIcon />
                              Reset
                            </p>
                          </Link>
                        </div>
                      </div>

                      <p className="help-bottom-section-footer">
                        Copyright &copy; {new Date().getFullYear()} Namrolink.
                        All Rights Reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="extras-options-circle-error-page">
        <CircleMenu />
      </div>
    </div>
  );
}
