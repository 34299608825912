import { Link, useNavigate } from "react-router-dom";
import PostOptionsIcon from "../../components/icons/PostOptionsIcon";
import CheckBadgeSolidIcon from "../../components/icons/CheckBadgeSolidIcon";
import ShareIcon from "../../components/icons/ShareIcon";
import PreviousImageIcon from "../../components/icons/PreviousImageIcon";
import NextImageIcon from "../../components/icons/NextImageIcon";
import InfoIcon from "../../components/icons/InfoIcon";
import EcommerceIcon from "../../components/icons/EcommerceIcon";
import HelpIcon from "../../components/icons/HelpIcon";
import MusicIcon from "../../components/icons/MusicIcon";
import NewspaperIcon from "../../components/icons/NewspaperIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import MessageOutlineIcon from "../../components/icons/MessageOutlineIcon";
import WhatsAppIcon from "../../components/icons/WhatsAppIcon";
import TelegramIcon from "../../components/icons/TelegramIcon";
import FacebookIcon from "../../components/icons/FacebookIcon";
import useOnClickOutside from "../../helpers/clickOutside";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import StarIcon from "../../components/icons/StarIcon";
import ImagesPreviewPopup from "../../components/ImagesPreview/ImagesPreviewPopup";
import HelpAdImagesPreview from "../../components/ImagesPreview/HelpAdImagesPreview";
import FeedbackPopup from "../../components/feedbackPopup";

export default function ImagesPreviewBoxes() {
  const { user } = useSelector((user) => ({ ...user }));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAdMenu, setShowAdMenu] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const link = `${process.env.REACT_APP_FRONTEND_URL}`;
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const admenu = useRef(null);
  const shareIconRef = useRef(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  useOnClickOutside(admenu, () => setShowAdMenu(false));
  useOnClickOutside(shareIconRef, () => setIsSharePopupOpen(false));

  const [isThumbnailVisible, setIsThumbnailSectionVisible] = useState(true);
  const images = [
    "../../../images/imagesPreviewImages/social-media-example-1.jpg",
    "../../../images/imagesPreviewImages/social-media-example-2.jpg",
    "../../../images/imagesPreviewImages/social-media-example-3.jpg",
    "../../../images/imagesPreviewImages/social-media-example-4.jpg",
    "../../../images/imagesPreviewImages/social-media-example-5.jpg",
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setActiveIndex((activeIndex - 1 + images.length) % images.length);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const toggleThumbnailSection = () => {
    setIsThumbnailSectionVisible(!isThumbnailVisible);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Get the current date
  const currentDate = new Date();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1400); // Hide message after 1 second
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const sectionsHelp = [
    {
      imgSrc: "../../../images/imagesPreviewImages/e-commerce-example-1.jpg",
      heading: "Namronet",
      icon: <EcommerceIcon />,
      textSection:
        "Discover quality, experience convenience and elevate your lifestyle.",
      linkURL: "https://www.namronet.com/",
      linkText: "Start trading",
    },
    {
      imgSrc: "../../../images/imagesPreviewImages/social-media-example-2.jpg",
      heading: "Namrolink",
      icon: <MessageOutlineIcon />,
      textSection:
        "Connect deeper, share freely, explore together. Link. Share. Connect.",
      linkURL: "https://www.namrolink.com/",
      linkText: "Start chatting",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSectionIndex(
        (prevIndex) => (prevIndex + 1) % sectionsHelp.length
      );
    }, 15000); // 15000ms = 15 seconds

    return () => clearInterval(interval);
  }, [sectionsHelp.length]);

  const { imgSrc, heading, icon, textSection, linkURL, linkText } =
    sectionsHelp[activeSectionIndex];

  const handleLinkCopy = () => {
    copyToClipboard(link); // Copy link to clipboard
  };

  return (
    <div className="other-col-wrapper">
      <div className="col-wrapper" style={{ alignItems: "start" }}>
        <div className="h-c-help-box">
          <div>
            <div className="help-page-feed post-middle-p-r">
              <div className="help-page-feed-head">
                <div className="help-p-f-user">
                  <Link to="/help" className="profile-photo ad-p-i-c-s">
                    <img
                      src="../../../images/defaultUserImages/user-img-2.jpg"
                      className="ad-profile-a-img-s"
                      alt=""
                    />
                  </Link>
                  <div>
                    <h3 className="c-p-user-feed-post-help">
                      Namrolink{" "}
                      <span>
                        <CheckBadgeSolidIcon />
                      </span>
                    </h3>
                    <small>Kampala, Uganda, {formatDate(currentDate)}</small>
                  </div>
                </div>
                <span
                  className="more-help-feed-i-s"
                  onClick={() => setShowAdMenu((prev) => !prev)}
                >
                  <i className="user-feed-icons-help">
                    <PostOptionsIcon />
                  </i>
                </span>
              </div>

              <div className="help-feed-caption">
                <p>Keep up to date with all your new and amazing trends.</p>
              </div>

              <div className="help-userfeed-photo">
                <div className="help-feed-slider">
                  <div className="list">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className={`useritem ${
                          index === activeIndex ? "useractivei" : ""
                        }`}
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                  </div>

                  <div className="userarrows">
                    <button id="prevarrow" onClick={handlePrev}>
                      <PreviousImageIcon />
                    </button>
                    <button id="nextarrow" onClick={handleNext}>
                      <NextImageIcon />
                    </button>
                  </div>

                  <div className="userthumbnail">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className={`useritem ${
                          index === activeIndex ? "useractivei" : ""
                        }`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="action-buttons">
                <div className="interaction-buttons">
                  <span className="like" onClick={() => navigate("/help")}>
                    <i className="uil uil-heart user-feed-icons">
                      <InfoIcon />
                    </i>
                  </span>
                  <span onClick={() => navigate("/discover/feed")}>
                    <i className="uil uil-comment-dots user-feed-icons">
                      <NewspaperIcon />
                    </i>
                  </span>

                  <span
                    ref={shareIconRef}
                    className="share-icon-wrapper"
                    onClick={toggleSharePopup}
                  >
                    <i className="uil uil-share-alt user-feed-icons">
                      <ShareIcon />
                      {copied && (
                        <div className="link-c-m-s copied-o-s">Copied</div>
                      )}
                    </i>
                    {isSharePopupOpen && (
                      <div className="share-popup">
                        <div className="share-icons-container">
                          <div
                            className="share-icon link-container-s"
                            onClick={() => copyToClipboard(link)}
                          >
                            <LinkIcon />
                          </div>
                          <Link
                            to="/chat"
                            className="share-icon"
                            onClick={handleLinkCopy}
                          >
                            <MessageOutlineIcon />
                          </Link>
                          <div
                            className="share-icon"
                            onClick={() =>
                              openInNewTab(`https://wa.me/?text=${link}`)
                            }
                          >
                            <WhatsAppIcon />
                          </div>
                          <div
                            className="share-icon"
                            onClick={() =>
                              openInNewTab(`https://t.me/share/url?url=${link}`)
                            }
                          >
                            <TelegramIcon />
                          </div>
                          <div
                            className="share-icon"
                            onClick={() =>
                              openInNewTab(
                                `https://www.facebook.com/sharer/sharer.php?u=${link}`
                              )
                            }
                          >
                            <FacebookIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
                <div className="bookmark" onClick={() => navigate("/music")}>
                  <span>
                    <i className="uil uil-bookmark user-feed-icons">
                      <MusicIcon />
                    </i>
                  </span>
                </div>
              </div>
              <div
                className="liked-by cursor-pointer-element-style"
                onClick={() => navigate("/discover")}
              >
                <span>
                  <img
                    src="../../../images/defaultUserImages/user-img-5.jpg"
                    alt=""
                  />
                </span>
                <span>
                  <img
                    src="../../../images/defaultUserImages/user-img-3.jpg"
                    alt=""
                  />
                </span>
                <span>
                  <img
                    src="../../../images/defaultUserImages/user-img-4.jpg"
                    alt=""
                  />
                </span>
                <span>
                  <img
                    src="../../../images/defaultUserImages/user-img-6.jpg"
                    alt=""
                  />
                </span>
                <p>
                  <b>Numerous</b> active users
                </p>
              </div>
              {showAdMenu && (
                <ul className="post_menu" ref={admenu}>
                  <div
                    onClick={() => {
                      window.open("https://namronet.com/", "_blank");
                    }}
                  >
                    <li className="hover1-m-p">
                      <i className="menu-item-i-s">
                        <EcommerceIcon />
                      </i>
                      <div className="post_menu_text">
                        <span>Namronet Ecommerce</span>
                        <span className="menu_post_col">
                          Find more, spend less
                        </span>
                      </div>
                    </li>
                  </div>
                  <div className="line"></div>
                  {user ? (
                    <>
                      <div onClick={() => openFeedbackPopup()}>
                        <li className="hover1-m-p">
                          <i className="menu-item-i-s">
                            <HelpIcon />
                          </i>
                          <div className="post_menu_text">
                            <span>Give feedback</span>
                            <span className="menu_post_col">
                              Chat with out support team
                            </span>
                          </div>
                        </li>
                      </div>
                      {isFeedbackPopupOpen && (
                        <FeedbackPopup
                          subject={`Right side section feedback or query from: ${user?.id} (${user?.username})`}
                          onClose={closeFeedbackPopup} // Pass a function to close the popup
                        />
                      )}
                    </>
                  ) : (
                    <div onClick={() => navigate("/login")}>
                      <li className="hover1-m-p">
                        <i className="menu-item-i-s">
                          <HelpIcon />
                        </i>
                        <div className="post_menu_text">
                          <span>Give feedback</span>
                          <span className="menu_post_col">
                            Chat with out support team
                          </span>
                        </div>
                      </li>
                    </div>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="h-c-help-box">
          <div>
            <div className="help-page-advertisement">
              <div className="help-advertisement-heading">
                <h4 className="right-heading-hover-s">{heading}</h4>
                <i
                  className="r-s-i-c-pointer"
                  onClick={() => {
                    window.open(linkURL, "_blank");
                  }}
                >
                  {icon}
                </i>
              </div>
              <div className="help-advertisement-photo-c-s">
                <img src={imgSrc} alt="" onClick={handleOpenPopup} />
              </div>
              <div className="ad-c-t-r-s">{textSection}</div>
              <div className="shop-now-r-btn-c">
                <button
                  onClick={() => {
                    window.open(linkURL, "_blank");
                  }}
                >
                  <StarIcon />
                  <Link to={linkURL}>{linkText}</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPopupVisible && <HelpAdImagesPreview onClose={handleClosePopup} />}
    </div>
  );
}
