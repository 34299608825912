import DeveloperExternal from "../../components/developer";

export default function DeveloperOther() {
  return (
    <div>
      <style jsx>{`
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style>
      <DeveloperExternal />
    </div>
  );
}
