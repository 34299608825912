import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import ThreeDotsVertical from "../../components/icons/ThreeDotsVertical";
import PreviousPageIcon from "../../components/icons/PreviousPageIcon";
import AttachmentIcon from "../../components/icons/AttachmentIcon";
import PhotoIcon from "../../components/icons/PhotoIcon";
import VideoCameraIcon from "../../components/icons/VideoCameraIcon";
import uploadFile from "../../helpers/uploadFile";
import CloseIcon from "../../components/icons/CloseIcon";
import { HashLoader } from "react-spinners";
import SendOutlineIcon from "../../components/icons/SendOutlineIcon";
import EmojiIcon from "../../components/icons/EmojiIcon";
import Moment from "react-moment";
import DeleteIcon from "../../components/icons/DeleteIcon";
import ReportIcon from "../../components/icons/ReportIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import ChildrenSolidIcon from "../../components/icons/ChildrenSolidIcon";
import ErrorSolidCircleIcon from "../../components/icons/ErrorSolidCircleIcon";
import Picker from "emoji-picker-react";
import FeedbackPopup from "../../components/feedbackPopup";

export default function MessagesPage({
  socketConnection,
  setSocketConnection,
}) {
  const { user } = useSelector((state) => ({ ...state }));
  const params = useParams();
  const [dataUser, setDataUser] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    picture: "",
    cover: "",
    bYear: 0,
    online: false,
    _id: "",
  });
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [allMessage, setAllMessage] = useState([]);
  const currentMessage = useRef(null);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [isChildSafetyMenuVisible, setIsChildSafetyMenuVisible] =
    useState(false);
  const [isDataUserErrorMenuVisible, setDataUserErrorMenuVisible] =
    useState(false);
  const currentYear = new Date().getFullYear();
  const userAge = dataUser?.bYear ? currentYear - dataUser.bYear : null;
  const [picker, setPicker] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const messageInputRef = useRef(null);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);

  const handleEmojiClick = (event, { emoji }) => {
    const ref = messageInputRef.current;
    ref.focus();
    const start = message.text.substring(0, ref.selectionStart);
    const end = message.text.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setMessage((prev) => ({
      ...prev,
      text: newText,
    }));
    setCursorPosition(start.length + emoji.length);
  };

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition]);

  const isDefaultUser =
    dataUser?.first_name === "" &&
    dataUser?.last_name === "" &&
    dataUser?.username === "" &&
    dataUser?.email === "" &&
    dataUser?.picture === "" &&
    dataUser?.cover === "" &&
    dataUser?.bYear === 0 &&
    dataUser?.online === false &&
    dataUser?._id === "";

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL, {
      auth: { token: user?.token },
    });

    socket.on("connect", () => {
      setSocketConnection(socket);
      if (params.userId) {
        socket.emit("message-page", params.userId);
      }
    });

    socket.on("message-user", (data) => {
      setDataUser(data);
    });

    socket.on("message", (data) => {
      setAllMessage(data);
    });

    return () => {
      socket.disconnect();
      setSocketConnection(null);
    };
  }, [user?.token]);

  useEffect(() => {
    if (socketConnection && params.userId) {
      socketConnection.emit("message-page", params.userId);
      socketConnection.emit("seen", params.userId);

      // Attach listeners only if socketConnection is valid
      socketConnection.on("message-user", (data) => {
        setDataUser(data);
      });

      socketConnection.on("message", (data) => {
        setAllMessage(data);
      });

      return () => {
        if (socketConnection) {
          socketConnection.off("message-user");
          socketConnection.off("message");
        }
      };
    }
  }, [socketConnection, params.userId, user]);

  useEffect(() => {
    if (currentMessage.current) {
      currentMessage.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [allMessage]);

  const handleUploadImageVideoOpen = () => {
    setOpenImageVideoUpload((prev) => !prev);
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];

    try {
      setLoading(true);
      const uploadPhoto = await uploadFile(file, true);
      setLoading(false);
      setOpenImageVideoUpload(false);
      setMessage((prev) => ({
        ...prev,
        imageUrl: uploadPhoto.url,
      }));
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const handleClearUploadImage = () => {
    setMessage((prev) => ({
      ...prev,
      imageUrl: "",
    }));
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];

    try {
      setLoading(true);
      const uploadPhoto = await uploadFile(file, false);
      setLoading(false);
      setOpenImageVideoUpload(false);
      setMessage((prev) => ({
        ...prev,
        videoUrl: uploadPhoto.url,
      }));
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const handleClearUploadVideo = () => {
    setMessage((prev) => ({
      ...prev,
      videoUrl: "",
    }));
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setMessage((prev) => ({
      ...prev,
      text: value,
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    // Trim the message text to remove leading and trailing spaces
    const trimmedText = message.text.trim();

    // Check if the message is empty or only contains spaces
    if (!trimmedText && !message.imageUrl && !message.videoUrl) {
      setError("Cannot send a blank message.");
      return;
    }

    if (socketConnection) {
      socketConnection.emit("new message", {
        sender: user?.id,
        receiver: params.userId,
        text: trimmedText,
        imageUrl: message.imageUrl,
        videoUrl: message.videoUrl,
        msgByUserId: user?.id,
        msgToUserId: params.userId, // Added this line
      });
      setMessage({
        text: "",
        imageUrl: "",
        videoUrl: "",
      });
    }
  };

  // Handle delete message function
  const handleDeleteMessage = (messageId) => {
    if (socketConnection) {
      socketConnection.emit("delete message", { messageId });
    }
  };

  const handleRetry = () => {
    setError(null);
    setMessage({
      text: "",
      imageUrl: "",
      videoUrl: "",
    });
  };

  const toggleUserMenu = () => {
    setIsUserMenuVisible(!isUserMenuVisible);
  };

  const toggleChildSafetyMenu = () => {
    setIsChildSafetyMenuVisible(!isChildSafetyMenuVisible);
  };

  const toggleUserErrorMenu = () => {
    setDataUserErrorMenuVisible(!isDataUserErrorMenuVisible);
  };

  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };

  return (
    <div className="messages-page-container-s">
      <div className="messages-page-header-s">
        <div className="m-p-h-d-style">
          <Link to="/chat" className="previous-c-p-i-s">
            <PreviousPageIcon />
          </Link>
          <div>
            <img
              src={dataUser.picture}
              className="messages-page-user-profile-i"
              alt=""
            />
          </div>
          <div className="messages-p-u-n-s-d-s">
            <h3>
              {dataUser?.first_name} {dataUser?.last_name}
            </h3>
            <p>
              {dataUser.online ? (
                <span className="messages-p-u-online-s">Online</span>
              ) : (
                <span className="text-muted">Offline</span>
              )}
            </p>
          </div>
        </div>
        <div>
          <div className="m-p-t-d-s-i">
            <div onClick={toggleUserMenu}>
              <ThreeDotsVertical />
            </div>
            {isUserMenuVisible && (
              <div className="three-dots-vertical-m-p-l">
                <Link
                  to={`/profile/${dataUser?.username}`}
                  className="options-message-page-data-user"
                >
                  <i className="t-d-msg-pg-u-i">
                    <ProfileIcon />
                  </i>
                  View profile
                </Link>
                <div
                  className="options-message-page-data-user"
                  onClick={() => openFeedbackPopup()}
                >
                  <i className="t-d-msg-pg-u-i">
                    <ReportIcon />
                  </i>
                  Report user
                </div>
              </div>
            )}

            {isFeedbackPopupOpen && (
              <FeedbackPopup
                subject={`Chat user of id: ${dataUser?._id} (${dataUser?.username}) reported`}
                onClose={closeFeedbackPopup} // Pass a function to close the popup
              />
            )}

            {isChildSafetyMenuVisible && (
              <div className="three-dots-vertical-m-p-l-umsg">
                <div
                  className="options-message-page-data-user"
                  onClick={toggleChildSafetyMenu}
                >
                  You are advised to keep your chats respectful.
                </div>
              </div>
            )}

            {isDataUserErrorMenuVisible && (
              <div className="three-dots-vertical-m-p-l-umsg-error">
                <div
                  className="options-message-page-data-user"
                  onClick={toggleUserErrorMenu}
                >
                  An error occured loading the user details. Try refreshing the
                  page or selecting another user.
                </div>
              </div>
            )}

            <div className="msg-p-u-t-u-check-i-container">
              {userAge !== null &&
                userAge > 0 &&
                userAge < 18 &&
                dataUser?._id !== user?.id && (
                  <div
                    className="msg-p-u-t-u-check-i"
                    title="Safety"
                    onClick={toggleChildSafetyMenu}
                  >
                    <ChildrenSolidIcon />
                  </div>
                )}
              {isDefaultUser && (
                <div
                  className="msg-p-u-t-u-check-i"
                  title="Error"
                  onClick={toggleUserErrorMenu}
                >
                  <ErrorSolidCircleIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="show-all-messages-m-p">
        <div className="all-message-show-here-s" ref={currentMessage}>
          {allMessage.map((msg, index) => {
            // Check if the message belongs to the current chat
            if (
              (msg.msgByUserId === user.id &&
                msg.msgToUserId === params.userId) ||
              (msg.msgByUserId === params.userId && msg.msgToUserId === user.id)
            ) {
              const isSender = user.id === msg?.msgByUserId;
              const isReceiver = user.id === msg?.msgToUserId;
              return (
                <div
                  key={index}
                  className={`message-r-c-a-m-s-s ${
                    user.id === msg?.msgByUserId ? "m-p-my-message-a-m-s-h" : ""
                  }`}
                >
                  <div className="u-w-f-p-r-s-s-a-m-m">
                    {msg?.imageUrl && (
                      <img
                        src={msg?.imageUrl}
                        className="a-m-s-h-img-s"
                        alt=""
                      />
                    )}
                    {msg?.videoUrl && (
                      <video
                        src={msg.videoUrl}
                        className="a-m-s-h-vid-s"
                        controls
                      />
                    )}
                  </div>
                  <p className="message-text-a-m-s">{msg.text}</p>
                  <p className="moment-msg-t-a-m-s">
                    <Moment fromNow interval={300}>
                      {msg.createdAt}
                    </Moment>
                    {isSender && (
                      <i
                        className="u-message-delete-icon"
                        onClick={() => handleDeleteMessage(msg._id)}
                      >
                        <DeleteIcon />
                      </i>
                    )}
                  </p>
                </div>
              );
            } else {
              // No need to render messages not for the current chat
              return null;
            }
          })}
        </div>

        {message.imageUrl && (
          <div className="upload-file-display-c">
            <div className="m-p-c-i-s" onClick={handleClearUploadImage}>
              <CloseIcon />
            </div>
            <div className="upload-file-u-f-c-s">
              <img
                src={message.imageUrl}
                alt=""
                className="upload-image-s-a-m"
              />
            </div>
          </div>
        )}

        {message.videoUrl && (
          <div className="upload-file-display-c">
            <div className="m-p-c-i-s" onClick={handleClearUploadVideo}>
              <CloseIcon />
            </div>
            <div className="upload-file-u-f-c-s">
              <video
                src={message.videoUrl}
                className="upload-video-i-t-m-p"
                controls
                muted
                autoPlay
              />
            </div>
          </div>
        )}

        {loading && (
          <div className="upload-file-m-p-loading-c">
            <HashLoader color="#1876f2" />
          </div>
        )}
        {error && (
          <div className="upload-file-display-c">
            <div className="upload-file-u-f-c-s error-m-p-u-f-p-c-s">
              <div className="upload-file-error-message-display">
                <div>{error}</div>
                <button className="p-p-blue_btn" onClick={handleRetry}>
                  Try again
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="send-message-container">
        <div className="s-m-c-c-m-p-s-o-c">
          <button
            className="s-m-c-c-m-p-s attachment-send-m-p"
            onClick={handleUploadImageVideoOpen}
          >
            <AttachmentIcon />
          </button>
          {openImageVideoUpload && (
            <div className="v-a-i-m-p-c">
              <form>
                <label
                  htmlFor="uploadImage"
                  className="m-p-a-u-o-s s-i-o-v-l-t-s"
                >
                  <div>
                    <PhotoIcon />
                  </div>
                  <p>Image</p>
                </label>
                <label
                  htmlFor="uploadVideo"
                  className="m-p-a-u-o-s s-i-o-v-l-t-s"
                >
                  <div>
                    <VideoCameraIcon />
                  </div>
                  <p>Video</p>
                </label>
                <input
                  type="file"
                  accept="image/jpeg,image/png,image/webp,image/gif"
                  id="uploadImage"
                  hidden
                  onChange={handleUploadImage}
                />

                <input
                  type="file"
                  accept="video/mp4"
                  id="uploadVideo"
                  hidden
                  onChange={handleUploadVideo}
                />
              </form>
            </div>
          )}
        </div>
        <form
          className="send-message-m-p-i-container"
          onSubmit={handleSendMessage}
        >
          <input
            type="text"
            placeholder="Type your message here ..."
            className="send-message-m-p-i-s"
            value={message.text}
            onChange={handleOnChange}
            ref={messageInputRef}
            onKeyUp={(e) => setCursorPosition(e.target.selectionStart)}
          />
          <div className="r-s-m-p-s-m-i-c-i-s">
            <div className="chat-add-emoji-icon-m-p">
              <div onClick={() => setPicker((prev) => !prev)}>
                <EmojiIcon />
              </div>
              {picker && (
                <div className="send-msg-emoji-picker">
                  <Picker onEmojiClick={handleEmojiClick} />
                </div>
              )}
            </div>
            <button className="send-message-m-p-button-s">
              <SendOutlineIcon />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
