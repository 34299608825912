import React, { useState } from "react";
import "./style.css";
import PreviousImageIcon from "../icons/PreviousImageIcon";
import NextImageIcon from "../icons/NextImageIcon";
import EyeIcon from "../icons/EyeIcon";
import CloseIcon from "../icons/CloseIcon";
import EyeSlashIcon from "../icons/EyeSlashIcon";

export default function HelpAdImagesPreview({ onClose }) {
  const [isThumbnailVisible, setIsThumbnailSectionVisible] = useState(true);
  const images = [
    "../../../images/imagesPreviewImages/namrolink-friends-page-demo.jpg",
    "../../../images/imagesPreviewImages/namrolink-discover-page-demo.jpg",
    "../../../images/imagesPreviewImages/namrolink-post-bg-example.jpg",
    "../../../images/imagesPreviewImages/namrolink-theme-toggler-demo.jpg",
    "../../../images/imagesPreviewImages/namrolink-chat-page-demo.jpg",
    "../../../images/imagesPreviewImages/namrolink-profile-page-demo.jpg",
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setActiveIndex((activeIndex - 1 + images.length) % images.length);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const toggleThumbnailSection = () => {
    setIsThumbnailSectionVisible(!isThumbnailVisible);
  };

  return (
    <div className="blur">
      <div className="image-preview-b-s">
        <div className="userslider">
          <div className="list">
            {images.map((image, index) => (
              <div
                key={index}
                className={`useritem ${
                  index === activeIndex ? "useractivei" : ""
                }`}
              >
                <img src={image} alt="" />
              </div>
            ))}
          </div>

          <div className="userarrows">
            <button id="prevarrow" onClick={handlePrev}>
              <PreviousImageIcon />
            </button>
            <button id="nextarrow" onClick={handleNext}>
              <NextImageIcon />
            </button>
          </div>

          <div className="otherusericonimg">
            {isThumbnailVisible && (
              <button id="eyeiconimg" onClick={toggleThumbnailSection}>
                <EyeSlashIcon />
              </button>
            )}
            {!isThumbnailVisible && (
              <button id="eyeiconimg" onClick={toggleThumbnailSection}>
                <EyeIcon />
              </button>
            )}
            <button id="closeiconimg" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          {isThumbnailVisible && (
            <div className="userthumbnail">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`useritem ${
                    index === activeIndex ? "useractivei" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img src={image} alt="" />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
