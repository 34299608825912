import { useState } from "react";
import "./style.css";
import axios from "axios";
import "../../../styles/middle.css";
import "../../../styles/mediaquery.css";
export default function SendVerification({ user }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const sendVerificationLink = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/sendVerification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess(data.message);
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  return (
    <div className="feeds">
      <div className="feed">
        <div className="h-v-m-c-s">
          <span>
            Please verify your account within 30 days of creation to ensure
            continued access and prevent deletion. <br />
          </span>
          <div className="send-v-link-container">
            <div
              className="hash-tag"
              onClick={() => {
                sendVerificationLink();
              }}
            >
              &#8594; Click here to resend verification link
            </div>
          </div>
          {success && <div className="success_text">{success}</div>}
          {error && <div className="error_text">{error}</div>}
        </div>
      </div>
    </div>
  );
}
