import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addFriend, unfriend } from "../../functions/user";
import { useState } from "react";

export default function AllUserCard({ userr }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const goToProfile = () => {
    navigate(`/profile/${userr.username}`);
  };
  const befriendHandler = async () => {
    await addFriend(userr._id, user.token);
    // Refresh data to reflect changes
  };

  const unfriendHandler = async () => {
    await unfriend(userr._id, user.token);
    // Refresh data to reflect changes
  };
  return (
    <div className="friends-card-c-s">
      <div className="friend-c-w-i">
        <Link to={`/profile/${userr.username}`}>
          <img src={userr.picture} alt="" />
        </Link>
        <div className="friend-card-d-w">
          <div className="friend-card-d-c">
            <div className="friend-card-n-d-s">
              <h4>
                {userr.first_name} {userr.last_name}
              </h4>
              <p className="text-muted">
                <small>@{userr.username}</small>
              </p>
            </div>
            <div className="friends-c-b-c-s">
              <button className="btn-primary" onClick={goToProfile}>
                View
              </button>
              <Link to={`/chat/${userr._id}`} className="btn">
                Message
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
