import React, { useState } from "react";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import TelegramIcon from "../icons/TelegramIcon";
import FacebookIcon from "../icons/FacebookIcon";
import LinkIcon from "../icons/LinkIcon";
import MessageOutlineIcon from "../icons/MessageOutlineIcon";
import { Link } from "react-router-dom";
import MoreIcon from "../icons/MoreIcon";
export default function ShareIcons({
  username,
  link,
  copied,
  copyToClipboard,
}) {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const handleLinkCopy = () => {
    copyToClipboard(link);
  };

  const handleNativeShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Namrolink builds connections",
          url: link,
          text: `Namrolink post by @${username}`,
        })
        .catch((err) => {
          console.error("Error sharing:", err);
        });
    } else {
      console.log("Native share not supported on this device.");
    }
  };

  return (
    <div className="share-icons-container">
      <div
        className="share-icon link-container-s"
        onClick={() => copyToClipboard(link)}
      >
        <LinkIcon />
      </div>
      <Link to="/chat" className="share-icon" onClick={handleLinkCopy}>
        <MessageOutlineIcon />
      </Link>
      <div
        className="share-icon"
        onClick={() => openInNewTab(`https://wa.me/?text=${link}`)}
      >
        <WhatsAppIcon />
      </div>
      <div
        className="share-icon"
        onClick={() => openInNewTab(`https://t.me/share/url?url=${link}`)}
      >
        <TelegramIcon />
      </div>
      <div
        className="share-icon"
        onClick={() =>
          openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${link}`)
        }
      >
        <FacebookIcon />
      </div>
      <div className="share-icon" onClick={handleNativeShare}>
        <MoreIcon />
      </div>
    </div>
  );
}
