import React, { useEffect, useState } from "react";
import { submitFeedback } from "../../functions/feedback";
import MessageUpIcon from "../../components/icons/MessageUpIcon";
import { useSelector } from "react-redux";
import axios from "axios";

export default function LoggedInFeedback() {
  const { user } = useSelector((state) => ({ ...state }));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState("");

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getPhoneNumber`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data.phoneNumber) {
          setPhoneNumber(response.data.phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhoneNumber();
  }, [user.token]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingUserDetails(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUserDetails(false);
      }
    };

    fetchUserDetails();
  }, [user.token]);

  const handleSubmit = async () => {
    const feedbackData = {
      fullName: `${user?.first_name} ${user?.last_name}`,
      email: userDetails?.email,
      phoneNumber: phoneNumber,
      subject: subject.trim(),
      message: message.trim(),
    };

    if (!subject.trim() || !message.trim()) {
      setFeedbackStatus("Please fill in the missing fields");
      setTimeout(() => setFeedbackStatus(""), 5000);
      return;
    }

    const response = await submitFeedback(feedbackData, user.token);
    setFeedbackStatus(response.message);

    if (response.message === "Message sent successfully") {
      setSubject("");
      setMessage("");
    }

    setTimeout(() => setFeedbackStatus(""), 5000);
  };

  return (
    <div className="h-c-help-box">
      <h2>Contact Us</h2>
      <input
        type="text"
        value={`${user?.first_name} ${user?.last_name}`}
        placeholder="Full Names"
        className="help-contact-us-input"
        hidden
        readOnly
      />
      <input
        type="text"
        value={userDetails?.email}
        placeholder="Contact email"
        className="help-contact-us-input"
        hidden
        readOnly
      />
      <input
        type="text"
        value={phoneNumber}
        placeholder="Mobile Number"
        className="help-contact-us-input"
        hidden
        readOnly
      />
      <input
        type="text"
        placeholder="Message subject"
        className="help-contact-us-input"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <textarea
        type="text"
        className="help-contact-us-textarea"
        placeholder="Your Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      {feedbackStatus && (
        <p
          style={{
            color: feedbackStatus.includes("success") ? "green" : "red",
          }}
        >
          {feedbackStatus}
        </p>
      )}
      <button className="help-contact-us-submit-button" onClick={handleSubmit}>
        <div className="help-contact-us-submit-button-div">
          <i>
            <MessageUpIcon />
          </i>
          Submit
        </div>
      </button>
    </div>
  );
}
