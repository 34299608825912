import "../../styles/middle.css";
import "../../styles/mediaquery.css";
import { useSelector } from "react-redux";
import UserFeedDefault from "./UserFeedDefault";

export default function ActivateUserFeed({ posts }) {
  const { user } = useSelector((user) => ({ ...user }));
  return (
    <div>
      <div className="feeds">
        <UserFeedDefault />
      </div>
    </div>
  );
}
