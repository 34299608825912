import { useCallback, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from "../../functions/post";
import { uploadImages } from "../../functions/uploadImages";
import { updateprofilePicture } from "../../functions/user";
import getCroppedImg from "../../helpers/getCroppedImg";
import PulseLoader from "react-spinners/PulseLoader";
import Cookies from "js-cookie";
import CloseIcon from "../icons/CloseIcon";
import CropIcon from "../icons/CropIcon";
import PlusIcon from "../icons/PlusIcon";
import MinusIcon from "../icons/MinusIcon";

export default function UpdateProfilePicture({
  setImage,
  image,
  setError,
  setShow,
  pRef,
  popup,
}) {
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const slider = useRef(null);
  const { user } = useSelector((state) => ({ ...state }));
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const zoomIn = () => {
    slider.current.stepUp();
    setZoom(slider.current.value);
  };

  const zoomOut = () => {
    slider.current.stepDown();
    setZoom(slider.current.value);
  };

  const getCroppedImage = useCallback(
    async (show) => {
      try {
        const img = await getCroppedImg(image, croppedAreaPixels);
        if (show) {
          setZoom(1);
          setCrop({ x: 0, y: 0 });
          setImage(img);
        } else {
          return img;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [croppedAreaPixels]
  );

  const updateProfielPicture = async () => {
    try {
      setLoading(true);
      let img = await getCroppedImage();
      let blob = await fetch(img).then((b) => b.blob());
      const path = `namrolink-social-media/${user.username}/profile_pictures`;
      let formData = new FormData();
      formData.append("file", blob);
      formData.append("path", path);
      const res = await uploadImages(formData, path, user.token);
      const updated_picture = await updateprofilePicture(
        res[0].url,
        user.token
      );
      if (updated_picture === "ok") {
        const new_post = await createPost(
          "profilePicture",
          null,
          description,
          res,
          null,
          user.id,
          user.token
        );
        if (new_post.status === "ok") {
          setLoading(false);
          setImage("");
          pRef.current.style.backgroundImage = `url(${res[0].url})`;
          Cookies.set(
            "user",
            JSON.stringify({
              ...user,
              picture: res[0].url,
            })
          );
          dispatch({
            type: "UPDATEPICTURE",
            payload: res[0].url,
          });
          setShow(false);
        } else {
          setLoading(false);
          setError(new_post);
        }
      } else {
        setLoading(false);
        setError(updated_picture);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="postBox pictureBox-a-s update-img" ref={popup}>
      <div className="box_header">
        <div className="small_circle" onClick={() => setImage("")}>
          <i className="close-i-p-s">
            <CloseIcon />
          </i>
        </div>
        <span className="update-p-p-p-s-c">Profile picture</span>
      </div>
      <div className="update-image-desc">
        <textarea
          placeholder="Feel like adding a description to your update?"
          value={description}
          maxLength="250"
          onChange={(e) => setDescription(e.target.value)}
          className="textarea-u-p-p details-input"
        ></textarea>
      </div>
      <div className="update-center">
        <div className="crooper">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={true}
            showGrid={false}
          />
        </div>
        <div className="slider">
          <div className="slider_circle hover1-m-p" onClick={() => zoomOut()}>
            <i className="b-i-s-s-u-p-p">
              <MinusIcon />
            </i>
          </div>
          <input
            type="range"
            min={1}
            max={3}
            step={0.2}
            ref={slider}
            value={zoom}
            onChange={(e) => setZoom(e.target.value)}
          />
          <div className="slider_circle hover1-m-p" onClick={() => zoomIn()}>
            <i className="b-i-s-s-u-p-p">
              <PlusIcon />
            </i>
          </div>
        </div>
      </div>
      <div className="flex-up">
        <div
          className="gray-btn-p crop-i-c-s"
          onClick={() => getCroppedImage("show")}
        >
          <i className="u-p-p-a-b-i-s">
            <CropIcon />
          </i>
          Crop photo
        </div>
      </div>
      <div className="update-submit-wrap">
        <div className="blue-link" onClick={() => setImage("")}>
          Cancel
        </div>
        <button
          className="p-blue_btn"
          disabled={loading}
          onClick={() => updateProfielPicture()}
        >
          {loading ? <PulseLoader color="#fff" size={5} /> : "Save"}
        </button>
      </div>
    </div>
  );
}
