import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Friends({ friends }) {
  const [count, setCount] = useState(9);

  const showMoreFriends = () => {
    setCount((prev) => prev + 18);
  };
  return (
    <div className="friends-p-b-t-s">
      <h4 className="outer-f-t-s">Friends</h4>
      <div className="feed">
        {friends && (
          <p className="profile_card_count">
            {friends.length === 0 ? (
              <b>No friends yet &#128532;</b>
            ) : friends.length === 1 ? (
              <b>1 friend</b>
            ) : (
              <b>{friends.length} friends</b>
            )}
          </p>
        )}
        <div className="photo">
          <div className="profile-card-grid">
            {friends &&
              friends.slice(0, count).map((friend, i) => (
                <Link
                  to={`/profile/${friend.username}`}
                  className="profile_photo_card"
                  key={i}
                >
                  <img
                    className="friends-p-pic-b-s"
                    src={friend.picture}
                    alt=""
                  />
                  <span className="friends-n-profile-p">
                    {friend.first_name} {friend.last_name}
                  </span>
                </Link>
              ))}
          </div>
        </div>
        {friends && count < friends.length && (
          <div className="see-a-photos" onClick={showMoreFriends}>
            See all friends
          </div>
        )}
      </div>
    </div>
  );
}
