const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/auto/upload`;

const MAX_IMAGE_SIZE = 7 * 1024 * 1024; // 7MB
const MAX_VIDEO_SIZE = 4 * 1024 * 1024; // 4MB
const VALID_IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
];
const VALID_VIDEO_TYPES = ["video/mp4"];

const uploadVideo = async (file, isImage = true) => {
  // Check file type
  const validTypes = isImage ? VALID_IMAGE_TYPES : VALID_VIDEO_TYPES;
  if (!validTypes.includes(file.type)) {
    throw new Error(
      `Unsupported file format. ${
        isImage ? "Images" : "Videos"
      } must be one of ${validTypes.join(", ")}.`
    );
  }

  // Check file size
  const maxSize = isImage ? MAX_IMAGE_SIZE : MAX_VIDEO_SIZE;
  if (file.size > maxSize) {
    throw new Error(
      `File size is too large. The maximum allowed size is ${
        maxSize / (1024 * 1024)
      }MB.`
    );
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "namrolink-post-video-files");

  const response = await fetch(url, {
    method: "post",
    body: formData,
  });
  const responseData = await response.json();

  return responseData;
};

export default uploadVideo;
