import { useSelector } from "react-redux";
import SendOutlineIcon from "../../components/icons/SendOutlineIcon";
import FeedbackPopup from "../../components/feedbackPopup";
import { useState } from "react";

export default function LoggedInUsersDeveloperForm() {
  const { user } = useSelector((state) => ({ ...state }));
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };

  return (
    <form action="" className="contact__form grid">
      <div className="contact__inputs grid">
        <div className="contact__content">
          <label for="" className="contact__label">
            Name
          </label>
          <input
            type="text"
            className="contact__input"
            readOnly
            onClick={() => openFeedbackPopup()}
          />
        </div>

        <div className="contact__content">
          <label for="" className="contact__label">
            E-mail
          </label>
          <input
            type="email"
            className="contact__input"
            readOnly
            onClick={() => openFeedbackPopup()}
          />
        </div>
      </div>

      <div className="contact__content">
        <label for="" className="contact__label">
          Subject
        </label>
        <input
          type="text"
          className="contact__input"
          readOnly
          onClick={() => openFeedbackPopup()}
        />
      </div>

      <div className="contact__content">
        <label for="" className="contact__label">
          Description
        </label>
        <textarea
          name=""
          id=""
          cols="0"
          rows="7"
          className="contact__input"
          readOnly
          style={{ resize: "none" }}
          onClick={() => openFeedbackPopup()}
        ></textarea>
      </div>

      <div>
        <a
          href="#"
          className="button button--flex"
          onClick={() => openFeedbackPopup()}
        >
          Send message
          <i className="uil uil-message button__icon developer-c-s-m-i-s">
            <SendOutlineIcon />
          </i>
        </a>
      </div>
      {isFeedbackPopupOpen && (
        <FeedbackPopup
          subject={`Message to the Namrolink developer (Sayiga Norman Haq). From: ${user?.id} (${user?.username})`}
          onClose={closeFeedbackPopup} // Pass a function to close the popup
        />
      )}
    </form>
  );
}
