import "../../styles/left.css";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "../icons/HomeIcon";
import ExploreIcon from "../icons/ExploreIcon";
import NotificationsIcon from "../icons/NotificationsIcon";
import MessagesIcon from "../icons/MessagesIcon";
import FriendsIcon from "../icons/FriendsIcon";
import HelpIcon from "../icons/HelpIcon";
import ThemeIcon from "../icons/ThemeIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import ThreeBarsIcon from "../icons/ThreeBarsIcon";
import { useState } from "react";
import NotificationsList from "./NotificationsList";
import CloseIcon from "../icons/CloseIcon";
import MusicIcon from "../icons/MusicIcon";

export default function LeftContainer({
  onMenuItemClick,
  onThemeClick,
  setVisible,
}) {
  const { user } = useSelector((user) => ({ ...user }));
  const [menuActive, setMenuActive] = useState(false);
  const [notificationsActive, setNotificationsActive] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const toggleNotifications = () => {
    setNotificationsActive(!notificationsActive);
  };

  const handleMenuItemClick = (e, id) => {
    e.preventDefault();
    onMenuItemClick(id);
  };

  // Function to prevent event propagation when clicking on a notification
  const handleNotificationClick = (e) => {
    e.stopPropagation();
  };

  const navigate = useNavigate();

  const handleExploreClick = () => {
    const exploreSearch = document.getElementById("explore-search");
    const exploreNavSearch = document.getElementById("nav-search");
    const searchElement =
      window.innerWidth > 992
        ? document.getElementById("navbar-search")
        : document.getElementById("search");

    searchElement.focus();
    exploreSearch.style.boxShadow = "0 0 1rem var(--color-primary)";
    exploreNavSearch.style.boxShadow = "0 0 1rem var(--color-primary)";
    setTimeout(() => {
      exploreSearch.style.boxShadow = "none";
      exploreNavSearch.style.boxShadow = "none";
    }, 2000);
  };

  const getActiveClass = (path) => {
    if (
      location.pathname === "/" ||
      location.pathname === "/home" ||
      location.pathname === "/activate" ||
      location.pathname.startsWith("/activate/")
    ) {
      return path === "/" ? "activeitem" : "";
    }
    if (location.pathname.includes("profile")) return "";
    if (
      location.pathname.includes("messages") ||
      location.pathname.includes("chat")
    ) {
      return path === "/chat" ? "activeitem" : "";
    }
    if (location.pathname.startsWith("/friends")) {
      return path === "/friends" ? "activeitem" : "";
    }
    if (location.pathname.includes("/help")) {
      return path === "/help" ? "activeitem" : "";
    }
    if (location.pathname.includes("/music")) {
      return path === "/music" ? "activeitem" : "";
    }
    return "";
  };

  return (
    <div className={`left ${menuActive ? "activeitem" : ""}`}>
      <Link to="/profile" className="profile">
        <div className="profile-photo">
          <img src={user?.picture} alt="" />
        </div>
        <div className="handle left-top-text-control">
          <h4 className="dark_h4_texts">
            {user?.first_name} {user?.last_name}
          </h4>
          <p className="text-muted">@{user?.username}</p>
        </div>
      </Link>

      <div className="sidebar">
        <div
          className={`menu-toggle ${menuActive ? "activeitem" : ""}`}
          onClick={toggleMenu}
        >
          <div>
            <i className="uil uil-bars menu-toggle-icon">
              <ThreeBarsIcon />
            </i>
            <i className="uil uil-arrow-right menu-toggle-icon">
              <ArrowRightIcon />
            </i>
          </div>
        </div>
        <Link to="/" className={`menu-item ${getActiveClass("/")}`} id="home">
          <span>
            <i className="uil uil-home navigation-icon">
              <HomeIcon />
            </i>
          </span>
          <h3>Home</h3>
        </Link>
        <Link
          to="#"
          className="menu-item"
          id="explore"
          onClick={(e) => {
            handleMenuItemClick(e, "explore");
            handleExploreClick();
          }}
        >
          <span>
            <i className="uil uil-compass navigation-icon">
              <ExploreIcon />
            </i>
          </span>
          <h3>Explore</h3>
        </Link>
        <div
          className="menu-item"
          id="notification-menu-item"
          onClick={toggleNotifications}
        >
          <span>
            <i className="uil uil-bell navigation-icon">
              <NotificationsIcon />
            </i>
          </span>
          <h3>Notifications</h3>
          <div
            className={`notifications-popup ${
              notificationsActive ? "activeitem" : ""
            }`}
            onClick={handleNotificationClick}
          >
            <NotificationsList />
            <div className="close-notification-container">
              <i
                className="close-notifications-icon"
                onClick={toggleNotifications}
              >
                <CloseIcon />
              </i>
            </div>
          </div>
        </div>
        <Link
          to="/chat"
          className={`menu-item ${getActiveClass("/chat")}`}
          id="messages-notifications"
        >
          <span>
            <i className="uil uil-envelope-alt navigation-icon">
              <MessagesIcon />
            </i>
          </span>
          <h3>Messages</h3>
        </Link>
        <Link
          to="/friends"
          className={`menu-item ${getActiveClass("/friends")}`}
        >
          <span>
            <i className="uil uil-bookmark navigation-icon">
              <FriendsIcon />
            </i>
          </span>
          <h3>Friends</h3>
        </Link>
        <Link to="/help" className={`menu-item ${getActiveClass("/help")}`}>
          <span>
            <i className="uil uil-chart-line navigation-icon">
              <HelpIcon />
            </i>
          </span>
          <h3>Help</h3>
        </Link>
        <Link
          to="#"
          className="menu-item"
          id="theme-menu-item"
          onClick={onThemeClick}
        >
          <span>
            <i className="uil uil-palette navigation-icon">
              <ThemeIcon />
            </i>
          </span>
          <h3>Theme</h3>
        </Link>
        <Link to="/music" className={`menu-item ${getActiveClass("/music")}`}>
          <span>
            <i className="uil uil-setting navigation-icon">
              <MusicIcon />
            </i>
          </span>
          <h3>Music</h3>
        </Link>
      </div>

      <label
        className="btn btn-primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        Create Post
      </label>
    </div>
  );
}
