import { useEffect, useRef, useState } from "react";
import "./style.css";
import EmojiPickerBackgrounds from "./EmojiPickerBackgrounds";
import ImagesPreviewPopup from "./ImagesPreviewPopup";
import ViewIcon from "../icons/ViewIcon";
import useClickOutside from "../../helpers/clickOutside";
import { createPost } from "../../functions/post";
import PulseLoader from "react-spinners/PulseLoader";
import PostError from "./PostError";
import dataURItoBlob from "../../helpers/dataURItoBlob";
import { uploadImages } from "../../functions/uploadImages";
import CloseIcon from "../icons/CloseIcon";
import AfricaGlobeIcon from "../icons/AfricaGlobeIcon";
import VideoPreviewPopup from "./VideoPreviewPopup";
import uploadVideo from "../../helpers/uploadVideo";

export default function CreatePostPopup({
  user,
  setVisible,
  posts,
  dispatch,
  profile,
}) {
  const popup = useRef(null);
  const [text, setText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [showPrev, setShowPrev] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [images, setImages] = useState([]);
  const [background, setBackground] = useState("");
  const textRef = useRef(null);
  const imageInputRef = useRef(null);
  const bgRef = useRef(null); // Add this line to define bgRef
  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [error, setError] = useState("");
  const [openVideoUpload, setOpenVideoUpload] = useState(false);

  const [totalImages, setTotalImages] = useState([]);
  const [totalSize, setTotalSize] = useState(0);

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];

    try {
      setLoadingVideo(true);
      const uploadPhoto = await uploadVideo(file, false);
      setLoadingVideo(false);
      setOpenVideoUpload(false);
      setVideoUrl(uploadPhoto.url);
    } catch (error) {
      setLoadingVideo(false);
      setError(error.message);
    }
  };

  const handleClearUploadVideo = () => {
    setVideoUrl("");
  };

  const handleImages = (e) => {
    let files = Array.from(e.target.files);
    let newTotalSize = totalSize;
    let newTotalImages = totalImages.length;

    // Calculate total size of new files
    const addedFilesSize = files.reduce((acc, file) => acc + file.size, 0);
    newTotalSize += addedFilesSize;

    // Calculate total number of new files
    const addedFilesCount = files.length;
    newTotalImages += addedFilesCount;

    // Check if total number of images exceeds 10
    if (newTotalImages > 10) {
      setError("Cannot upload more than 10 files.");
      return;
    }

    // Check if total size exceeds 7MB
    if (newTotalSize > 1024 * 1024 * 7) {
      setError(
        "Total file size is too large. The maximum allowed size is 7MB."
      );
      return;
    }

    // If everything is okay, proceed with the file processing
    files.forEach((img) => {
      if (
        img.type !== "image/jpeg" &&
        img.type !== "image/png" &&
        img.type !== "image/webp" &&
        img.type !== "image/gif"
      ) {
        setError(
          `${img.name} format is unsupported! Only JPEG, PNG, WebP, and GIF are allowed.`
        );
        files = files.filter((item) => item.name !== img.name);
        return;
      } else if (img.size > 1024 * 1024 * 7) {
        setError(
          `${img.name} size is too large. The maximum allowed size is 7MB.`
        );
        files = files.filter((item) => item.name !== img.name);
        return;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = (readerEvent) => {
          setImages((images) => [...images, readerEvent.target.result]);
        };
      }
    });

    // Update totalImages and totalSize after processing
    setTotalImages((prevImages) => [...prevImages, ...files]);
    setTotalSize(newTotalSize);
  };

  const handleClickOutside = (e) => {
    if (e.target.closest(".images-preview-popup") === null) {
      setShowImagePreview(false);
    }
  };

  useEffect(() => {
    if (showImagePreview) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showImagePreview]);

  useClickOutside(popup, () => {
    setVisible(false);
  });

  const postSubmit = async () => {
    if (!text.trim() && images.length === 0 && !background && !videoUrl) {
      setError("Cannot make a blank post");
      return;
    }

    try {
      setLoading(true);
      let response;
      if (background) {
        response = await createPost(
          null,
          background,
          text,
          null,
          null,
          user.id,
          user.token
        );
      } else if (images && images.length) {
        const postImages = images.map((img) => dataURItoBlob(img));
        const path = `namrolink-social-media/${user.username}/post_images`;
        let formData = new FormData();
        formData.append("path", path);
        postImages.forEach((image) => {
          formData.append("file", image);
        });
        const uploadResponse = await uploadImages(formData, path, user.token);
        response = await createPost(
          null,
          null,
          text,
          uploadResponse,
          null,
          user.id,
          user.token
        );
      } else if (videoUrl) {
        response = await createPost(
          null,
          null,
          text,
          null,
          videoUrl,
          user.id,
          user.token
        );
      } else if (text) {
        response = await createPost(
          null,
          null,
          text,
          null,
          null,
          user.id,
          user.token
        );
      } else {
        console.log("Nothing to post");
        return;
      }

      setLoading(false);

      if (response.status === "ok") {
        dispatch({
          type: profile ? "PROFILE_POSTS" : "POSTS_SUCCESS",
          payload: [response.data, ...posts],
        });
        setBackground("");
        setText("");
        setImages([]);
        setVideoUrl("");
        setVisible(false);
      } else {
        setError(response.message || "An error occurred");
      }
    } catch (err) {
      setLoading(false);
      setError("An unexpected error occurred");
    }
  };

  useEffect(() => {
    textRef.current.focus();
  }, []);

  return (
    <div className="blur">
      <div className="postBox" ref={popup}>
        {error && <PostError error={error} setError={setError} />}
        <div className="box_header">
          <div
            className="small_circle"
            onClick={() => {
              setVisible(false);
            }}
          >
            <i className="close-i-p-s">
              <CloseIcon />
            </i>
          </div>
          <span>Create Post</span>
        </div>
        <div className="box_profile">
          <img src={user?.picture} alt="" className="box_profile_img" />
          <div className="box_col">
            <div className="box_profile_name">
              {user?.first_name} {user?.last_name}
            </div>
            <div className="box_privacy">
              <AfricaGlobeIcon />
              <span>Public</span>
              <i className="arrowDown_icon invisible_arrowDown_icon"></i>
            </div>
          </div>
        </div>

        {!showPrev && (
          <>
            <div className="flex_center" ref={bgRef}>
              <textarea
                ref={textRef}
                maxLength="250"
                value={text}
                placeholder={`What is on your mind, ${user?.first_name}?`}
                className="post_input"
                onChange={(e) => setText(e.target.value)}
                style={{
                  paddingTop: `${
                    background
                      ? Math.abs(textRef.current.value.length * 0.1 - 20)
                      : "0"
                  }%`,
                }}
              ></textarea>
            </div>
            <EmojiPickerBackgrounds
              text={text}
              textRef={textRef}
              setText={setText}
              user={user}
              imageInputRef={imageInputRef} // Pass the ref here
              setBackground={setBackground}
              background={background}
              bgRef={bgRef} // Pass the bgRef here
              loadingVideo={loadingVideo}
            />
          </>
        )}
        <input
          type="file"
          accept="image/jpeg,image/png,image/webp,image/gif"
          multiple
          hidden
          ref={imageInputRef}
          onChange={handleImages}
        />
        <input
          type="file"
          accept="video/mp4"
          id="uploadPostVideoInput"
          hidden
          onChange={handleUploadVideo}
        />
        <input type="file" accept="video/mp4" hidden />
        <button className="post_submit" onClick={postSubmit} disabled={loading}>
          {loading ? <PulseLoader color="#fff" size={5} /> : "Post"}
        </button>
        <div className="post-p-button-spacing"></div>
        {videoUrl ? (
          <div
            className="user-pics-preview-popup"
            onClick={() => setShowVideoPreview(true)}
          >
            <ViewIcon /> Tap here to see video preview
          </div>
        ) : (
          <div>
            {images && images.length ? (
              <div
                className="user-pics-preview-popup"
                onClick={() => setShowImagePreview(true)}
              >
                <ViewIcon /> Tap here to see images preview
              </div>
            ) : (
              <div className="user-add-pics">
                <div
                  className="p-popup-i-p-prompt"
                  onClick={() => {
                    imageInputRef.current.click();
                  }}
                >
                  Would you like to add an image?
                </div>
              </div>
            )}
          </div>
        )}
        {showImagePreview && (
          <ImagesPreviewPopup
            onClose={() => setShowImagePreview(false)}
            images={images}
            setImages={setImages}
            imageInputRef={imageInputRef}
          />
        )}
        {showVideoPreview && (
          <VideoPreviewPopup
            onVideoPreviewClose={() => setShowVideoPreview(false)}
            handleClearUploadVideo={handleClearUploadVideo}
            videoUrl={videoUrl}
          />
        )}
        <div className="post-p-button-spacing"></div>
      </div>
    </div>
  );
}
