import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import { useSelector } from "react-redux";
import {
  acceptRequest,
  addFriend,
  cancelRequest,
  deleteRequest,
  follow,
  unfollow,
  unfriend,
} from "../../functions/user";
import { Link } from "react-router-dom";

export default function Friendship({ friendshipp, profileid }) {
  const [friendship, setFriendship] = useState(friendshipp);
  const [friendsMenu, setFriendsMenu] = useState(false);
  const [respondMenu, setRespondMenu] = useState(false);
  const [actionsMenu, setActionsMenu] = useState(false); // New state for Actions menu
  const menu = useRef(null);
  const menu1 = useRef(null);
  const menu2 = useRef(null); // New ref for Actions menu
  useClickOutside(menu, () => setFriendsMenu(false));
  useClickOutside(menu1, () => setRespondMenu(false));
  useClickOutside(menu2, () => setActionsMenu(false)); // New click outside handler for Actions menu
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setFriendship(friendshipp);
  }, [friendshipp]);

  const addFriendHandler = async () => {
    setFriendship({ ...friendship, requestSent: true, following: true });
    await addFriend(profileid, user.token);
  };

  const cancelRequestHandler = async () => {
    setFriendship({ ...friendship, requestSent: false, following: false });
    await cancelRequest(profileid, user.token);
  };

  const followHandler = async () => {
    setFriendship({ ...friendship, following: true });
    await follow(profileid, user.token);
  };

  const unfollowHandler = async () => {
    setFriendship({ ...friendship, following: false });
    await unfollow(profileid, user.token);
  };

  const acceptRequestHandler = async () => {
    setFriendship({
      ...friendship,
      friends: true,
      following: true,
      requestSent: false,
      requestReceived: false,
    });
    await acceptRequest(profileid, user.token);
  };

  const unfriendHandler = async () => {
    setFriendship({
      ...friendship,
      friends: false,
      following: false,
      requestSent: false,
      requestReceived: false,
    });
    await unfriend(profileid, user.token);
  };

  const deleteRequestHandler = async () => {
    setFriendship({
      ...friendship,
      friends: false,
      following: false,
      requestSent: false,
      requestReceived: false,
    });
    await deleteRequest(profileid, user.token);
  };

  return (
    <div>
      <div className="profile-w-right">
        <div className="profile-c-buttons-o-v friends_menu_wrap">
          {friendship?.friends ? (
            <>
              <button
                className="btn btn-primary mr-p-b-b-s"
                onClick={() => setFriendsMenu(true)}
              >
                <span>Friends</span>
              </button>
              {friendsMenu && (
                <div className="open_cover_menu" ref={menu}>
                  {friendship?.following ? (
                    <div
                      className="open_cover_menu_item hover1-m-p"
                      onClick={() => unfollowHandler()}
                    >
                      Unfollow
                    </div>
                  ) : (
                    <div
                      className="open_cover_menu_item hover1-m-p"
                      onClick={() => followHandler()}
                    >
                      Follow
                    </div>
                  )}
                  <div
                    className="open_cover_menu_item hover1-m-p"
                    onClick={() => unfriendHandler()}
                  >
                    Unfriend
                  </div>
                </div>
              )}
            </>
          ) : (
            !friendship?.requestReceived && (
              <>
                <button
                  className="btn btn-primary mr-p-b-b-s"
                  onClick={() => setActionsMenu(true)}
                >
                  <span>{friendship?.requestSent ? "Cancel" : "Actions"}</span>
                </button>
                {actionsMenu && (
                  <div className="open_cover_menu" ref={menu2}>
                    {friendship?.requestSent ? (
                      <div
                        className="open_cover_menu_item hover1-m-p"
                        onClick={() => cancelRequestHandler()}
                      >
                        Cancel request
                      </div>
                    ) : (
                      <div
                        className="open_cover_menu_item hover1-m-p"
                        onClick={() => addFriendHandler()}
                      >
                        Befriend
                      </div>
                    )}
                    {friendship?.following ? (
                      <div
                        className="open_cover_menu_item hover1-m-p"
                        onClick={() => unfollowHandler()}
                      >
                        Unfollow
                      </div>
                    ) : (
                      <div
                        className="open_cover_menu_item hover1-m-p"
                        onClick={() => followHandler()}
                      >
                        Follow
                      </div>
                    )}
                  </div>
                )}
              </>
            )
          )}
          {friendship?.requestReceived && (
            <>
              <button
                className="btn btn-primary mr-p-b-b-s"
                onClick={() => setRespondMenu(true)}
              >
                <span>Respond</span>
              </button>
              {respondMenu && (
                <div className="open_cover_menu" ref={menu1}>
                  <div
                    className="open_cover_menu_item hover1-m-p"
                    onClick={() => acceptRequestHandler()}
                  >
                    Confirm
                  </div>
                  <div
                    className="open_cover_menu_item hover1-m-p"
                    onClick={() => deleteRequestHandler()}
                  >
                    Delete
                  </div>
                </div>
              )}
            </>
          )}
          <>
            <Link
              to={`/chat/${profileid}`}
              className={
                friendship?.friends
                  ? "btn friends-message-btn-p-p"
                  : "btn not-friends-message-btn-p-p"
              }
            >
              <span>Message</span>
            </Link>
          </>
        </div>
      </div>
    </div>
  );
}
