export default function GenderRegister({ handleRegisterChange, genderError }) {
  return (
    <div>
      <label htmlFor="male" className="login__label">
        Gender
      </label>
      <div className="gender_select_section">
        <div className="option-container">
          <input
            type="radio"
            id="male"
            name="gender"
            value="male"
            className="gender_radio_input"
            onChange={handleRegisterChange}
          />
          <label for="male" className="gender_label">
            Male
          </label>
        </div>
        <div className="option-container">
          <input
            type="radio"
            id="female"
            name="gender"
            value="female"
            className="gender_radio_input"
            onChange={handleRegisterChange}
          />
          <label for="male" className="gender_label">
            Female
          </label>
        </div>
      </div>
      {genderError && <div className="input_error_message">{genderError}</div>}
    </div>
  );
}
