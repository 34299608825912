export default function FAQServices() {
  return (
    <div className="other-col-wrapper">
      <div className="col-wrapper" style={{ alignItems: "start" }}>
        <div className="h-c-help-box">
          <div>
            <h2>FAQs</h2>
            <p>
              <strong>Question 1:</strong> How do I create an account?
            </p>
            <p>
              <strong>Answer:</strong> You can create an account by navigating
              to the register page using the or the Sign Up Options header menu
              option at the top of the page or by using the Namrolink Links at
              the bottom of the page and then following the on-screen prompts.
            </p>
            <p>
              <strong>Question 2:</strong> How can I reset my password?
            </p>
            <p>
              <strong>Answer:</strong> In case you forgot you password, you can
              simply reset it from the reset page. On the reset page, you will
              be prompted to sign in to your gmail so as to automatically mark
              your account and enable you to receive the reset code that will
              enable you to select a new password.
            </p>
            <p>
              <strong>Question 3:</strong> Who made Namrolink?
            </p>
            <p>
              <strong>Answer:</strong> Namrolink was lauched by a Ugandan
              software developer called Sayiga Norman Haq.
            </p>
            <p>
              <strong>Question 4:</strong> How do I report inappropriate
              content?
            </p>
            <p>
              <strong>Answer:</strong> You can report inappropriate content
              using the report option action or by just submitting your concern
              in the help page Contact Us form from which it will be reviewed.
            </p>
            <p>
              <strong>Question 5:</strong> What should I do if I encounter
              technical issues?
            </p>
            <p>
              <strong>Answer:</strong> You could contact the Namrolink technical
              support using the official accounts that are displayed on the help
              page or by just sending your request for help via the Contact Us
              form so as to be provided with viable solutions, troubleshooting
              tips and contact information.
            </p>
          </div>
        </div>

        <div className="h-c-help-box">
          <div>
            <h2>Namrolink Services</h2>
            <p>
              Namrolink offers a secure environment for meaningful connections,
              a lightning fast messaging system, and extensive customization
              options that let users personalize their experience with stunning
              themes and display settings.
            </p>
            <p>
              We kindly urge all users to avoid posting or engaging in any
              dangerous, illegal, harmful, disrespectful, explicit, immoral or
              hateful behavior on Namrolink, to help maintain a safe and
              positive community for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
