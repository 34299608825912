import { Link, useNavigate } from "react-router-dom";
import CheckBadgeSolidIcon from "../../components/icons/CheckBadgeSolidIcon";
import DeveloperIcon from "../../components/icons/DeveloperIcon";
import GitHubIcon from "../../components/icons/GitHubIcon";
import LinkedInIcon from "../../components/icons/LinkedInIcon";
import TelegramIcon from "../../components/icons/TelegramIcon";
import WhatsAppIcon from "../../components/icons/WhatsAppIcon";
import HomeIcon from "../../components/icons/HomeIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import FriendsIcon from "../../components/icons/FriendsIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import HelpIcon from "../../components/icons/HelpIcon";
import MusicIcon from "../../components/icons/MusicIcon";
import FriendGroupIcon from "../../components/icons/FriendGroupIcon";
import NewspaperIcon from "../../components/icons/NewspaperIcon";
import EcommerceIcon from "../../components/icons/EcommerceIcon";
import ShieldCheckIcon from "../../components/icons/ShieldCheckIcon";
import LogInIcon from "../../components/icons/LogInIcon";
import TablePlusIcon from "../../components/icons/TablePlusIcon";
import { useSelector } from "react-redux";

export default function HelpBottomSection() {
  const { user } = useSelector((user) => ({ ...user }));
  const navigate = useNavigate();
  return (
    <div className="other-col-wrapper">
      <div className="col-wrapper" style={{ alignItems: "start" }}>
        <div className="h-c-help-box">
          <div>
            <h2>Namrolink Developer</h2>
            <div className="help-developer-image-wrapper">
              <img
                src="../../../developer/images/developer-help-pic.png"
                className="help-developer-image"
                alt=""
                onClick={() => navigate("/developer")}
              />
            </div>
            <div className="help-page-developer-section">
              <p style={{ fontSize: "0.85rem" }}>Hello, I&apos;m</p>

              <div className="help-page-developer-section">
                <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Sayiga Norman Haq <CheckBadgeSolidIcon />
                </p>
              </div>

              <div className="centered-help-p-div">
                <a
                  href="https://wa.me/+256789980531"
                  className="help-contact-img-icon-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="help-contact-svg-icon">
                    <WhatsAppIcon />
                  </div>
                </a>

                <a
                  href="https://t.me/SayigaNormanHaq"
                  className="help-contact-img-icon-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="help-contact-svg-icon">
                    <TelegramIcon />
                  </div>
                </a>

                <a
                  href="https://www.linkedin.com/in/sayiga-norman-haq-4a47432a9/"
                  className="help-contact-img-icon-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="help-contact-svg-icon">
                    <LinkedInIcon />
                  </div>
                </a>

                <a
                  href="https://github.com/SayigaNormanHaq"
                  className="help-contact-img-icon-link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="help-contact-svg-icon">
                    <GitHubIcon />
                  </div>
                </a>
              </div>

              <p style={{ fontSize: "0.95rem" }}>Software developer</p>
              <p style={{ fontSize: "0.95rem", marginBottom: "15px" }}>
                I am dedicated to delivering carefully developed software
                innovations through hard work, creativity, and collaboration. My
                goal is to contribute positively to every project and support my
                team, ensuring we achieve the best outcomes together.
              </p>
              <button
                className="help-developer-page-button"
                onClick={() => navigate("/developer")}
              >
                <div className="help-contact-us-submit-button-div">
                  <i>
                    <DeveloperIcon />
                  </i>
                  Developer page
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="h-c-help-box">
          <div>
            <h2>Namrolink Links</h2>

            <div className="help-bottom-link-wrapper">
              <div className="help-bottom-link-container">
                <Link to="/" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <HomeIcon />
                    Home
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/chat" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <MessagesIcon />
                    Chat
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/profile" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <ProfileIcon />
                    Profile
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/friends" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <FriendsIcon />
                    Friends
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/discover" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <FriendGroupIcon />
                    Discover
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/help" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <HelpIcon />
                    Help
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/music" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <MusicIcon />
                    Music
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/discover/feed" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <NewspaperIcon />
                    Feed
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <Link to="/developer" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <DeveloperIcon />
                    Developer
                  </p>
                </Link>
              </div>

              <div className="help-bottom-link-container">
                <a
                  href="https://www.namronet.com/"
                  className="help-page-bottom-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="help-page-bottom-link-texts">
                    <EcommerceIcon />
                    Namronet
                  </p>
                </a>
              </div>
              {!user && (
                <div className="help-bottom-link-container">
                  <Link to="/" className="help-page-bottom-link">
                    <p className="help-page-bottom-link-texts">
                      <LogInIcon />
                      Login
                    </p>
                  </Link>
                </div>
              )}

              {!user && (
                <div className="help-bottom-link-container">
                  <Link to="/" className="help-page-bottom-link">
                    <p className="help-page-bottom-link-texts">
                      <TablePlusIcon />
                      Register
                    </p>
                  </Link>
                </div>
              )}

              <div className="help-bottom-link-container">
                <Link to="/" className="help-page-bottom-link">
                  <p className="help-page-bottom-link-texts">
                    <ShieldCheckIcon />
                    Reset
                  </p>
                </Link>
              </div>
            </div>

            <p className="help-bottom-section-footer">
              Copyright &copy; {new Date().getFullYear()} Namrolink. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
