import { useEffect, useRef, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import "./style.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { submitFeedback } from "../../functions/feedback";
import useOnClickOutside from "../../helpers/clickOutside";

export default function FeedbackPopup({ subject, onClose }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [message, setMessage] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const popupRef = useRef(null);

  useOnClickOutside(popupRef, onClose);

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getPhoneNumber`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data.phoneNumber) {
          setPhoneNumber(response.data.phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhoneNumber();
  }, [user.token]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingUserDetails(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUserDetails(false);
      }
    };

    fetchUserDetails();
  }, [user.token]);

  const handleSubmit = async () => {
    const feedbackData = {
      fullName: `${user?.first_name} ${user?.last_name}`,
      email: userDetails?.email,
      phoneNumber: phoneNumber,
      subject: subject.trim(),
      message: message.trim(),
    };

    if (!subject.trim() || !message.trim()) {
      setFeedbackStatus("Please fill in the missing field");
      setTimeout(() => setFeedbackStatus(""), 5000);
      return;
    }

    const response = await submitFeedback(feedbackData, user.token);
    setFeedbackStatus(response.message);

    if (response.message === "Message sent successfully") {
      setMessage("");
    }

    setTimeout(() => setFeedbackStatus(""), 5000);
  };
  return (
    <div>
      <div className="feedback-chat-popup" ref={popupRef}>
        <div className="feedback-chat-badge" onClick={onClose}>
          <i className="close-f-chat-p-i-s">
            <CloseIcon />
          </i>
        </div>

        <div className="feedback-chat-area">
          <div className="feedback-chat-income-msg">
            <img
              src="../../../images/defaultUserImages/user-img-2.jpg"
              className="feedback-chat-avatar"
              alt=""
            />
            <span class="feedback-chat-msg">Hi! How can I help you?</span>
          </div>
          {feedbackStatus && (
            <div className="feedback-chat-income-msg feedback-disappear-msg-container">
              <img
                src="../../../images/defaultUserImages/user-img-2.jpg"
                className="feedback-chat-avatar"
                alt=""
              />
              <span
                class="feedback-chat-msg"
                style={{
                  color: feedbackStatus.includes("success") ? "green" : "red",
                }}
              >
                {feedbackStatus}
              </span>
            </div>
          )}
        </div>

        <div className="feedback-chat-input-area">
          <input
            type="text"
            value={`${user?.first_name} ${user?.last_name}`}
            placeholder="Full Names"
            className="help-contact-us-input"
            hidden
            readOnly
          />
          <input
            type="text"
            value={userDetails?.email}
            placeholder="Contact email"
            className="help-contact-us-input"
            hidden
            readOnly
          />
          <input
            type="text"
            value={phoneNumber}
            placeholder="Mobile Number"
            className="help-contact-us-input"
            hidden
            readOnly
          />
          <input
            type="text"
            value={subject}
            placeholder="Message subject"
            className="help-contact-us-input"
            hidden
            readOnly
          />
          <input
            type="text"
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className="feedback-chat-submit" onClick={handleSubmit}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
