import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import useOnCLickOutside from "../../helpers/clickOutside";
import { useRef } from "react";

export default function UserTileCard({
  userTileImgCover,
  userTileFirstName,
  userTileLastName,
  userTileFriendsCount,
  userTileFollowersCount,
  userTileImgPic,
  userTileFollowingCount,
  userJob,
  userTileUsername,
  setUserTileVisible,
}) {
  const usertilemodal = useRef(null);
  useOnCLickOutside(usertilemodal, () => setUserTileVisible(false));
  return (
    <div className="blur">
      <style jsx>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: "Poppins", sans-serif;
        }

        .user-tile-c-body {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
        }
      `}</style>
      <div className="user-tile-c-body">
        <div className="u-card-t" ref={usertilemodal}>
          <div className="u-t-imgBx">
            <img src={userTileImgPic} alt="" />
          </div>
          <div className="u-t-content">
            <div className="u-t-details">
              <h2>
                {userTileFirstName} {userTileLastName}
                <br />
                {userJob ? (
                  <span className="job-p-u-t-d">{userJob}</span>
                ) : (
                  <span className="job-p-u-t-d">No info yet</span>
                )}
              </h2>
              <div className="u-t-data">
                <h3>
                  {userTileFriendsCount}
                  <br />
                  <span>Friends</span>
                </h3>
                <h3>
                  {userTileFollowersCount}
                  <br />
                  <span>Followers</span>
                </h3>
                <h3>
                  {userTileFollowingCount}
                  <br />
                  <span>Following</span>
                </h3>
              </div>
              <div className="u-t-actionBtn">
                <Link
                  to={`/profile/${userTileUsername}`}
                  className="u-t-c-f-a-b-s"
                >
                  More
                </Link>
                <div
                  className="u-t-c-f-a-b-s"
                  onClick={() => {
                    setUserTileVisible(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
