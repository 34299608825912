import React from "react";

const DeveloperExternal = () => {
  return (
    <iframe
      src={`${process.env.PUBLIC_URL}/external/index.html`}
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        border: "none",
      }}
      title="Namrolink - Developer"
    />
  );
};

export default DeveloperExternal;
