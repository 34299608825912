import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import RegisterFormInput from "../../components/inputs/registerFormInput";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function SearchAccount({
  email,
  setEmail,
  error,
  setError,
  setLoading,
  setUserInfos,
  setVisible,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state }));
  const [googleLoggedIn, setGoogleLoggedIn] = useState(false);
  const [isResetImage, setIsResetImage] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");

    if (emailParam) {
      // Set email and clean URL
      setEmail(emailParam);
      setGoogleLoggedIn(true);
      setVisible(0);
      window.history.replaceState(null, "", window.location.pathname);
    } else if (!user) {
      // If user is not logged in, show Google login option
      setGoogleLoggedIn(false);
    }
  }, [setEmail, setVisible, user]);

  const validateEmail = Yup.object({
    email: Yup.string()
      .required("Email address is required.")
      .email("Must be a valid email address.")
      .max(50, "Email address can't be more than 50 characters."),
  });

  const handleSearch = async () => {
    try {
      setLoading(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/findUser`,
        { email }
      );
      setUserInfos(data);
      setVisible(1);
      setError("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const toggleResetImage = () => {
    setIsResetImage(!isResetImage);
  };

  return (
    <div className="container">
      <div className="login">
        <div className="login__content">
          {isResetImage ? (
            <img
              className="login__img"
              src="../../backgrounds/bg-reset.jpg"
              alt=""
              onClick={toggleResetImage}
            />
          ) : (
            <img
              className="login__img"
              src="../../backgrounds/bg-reset-other.jpg"
              alt=""
              onClick={toggleResetImage}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={{
              email,
            }}
            validationSchema={validateEmail}
            onSubmit={() => {
              handleSearch();
            }}
          >
            {(formik) => (
              <Form className="login__form">
                {!googleLoggedIn ? (
                  <div>
                    <div className="intro_to_signup">
                      <h1 className="login__title">
                        <span>Namrolink</span> Password Reset
                      </h1>
                      <p className="login__description">
                        Please login to your email account to begin the password
                        reset process.
                      </p>
                    </div>

                    <div className="signup_button_spacing"></div>

                    <div>
                      <div>
                        <div className="login__buttons">
                          <button
                            className="login__button"
                            onClick={handleGoogleLogin}
                          >
                            Login with Google
                          </button>
                        </div>
                      </div>

                      {user ? (
                        <div
                          className="register__forgot bottom-search-account-o"
                          onClick={() => {
                            logout();
                          }}
                        >
                          I would like to log in to another account instead.
                        </div>
                      ) : (
                        <Link
                          className="register__forgot bottom-search-account-o"
                          to="/login"
                        >
                          I already know my password.
                        </Link>
                      )}
                    </div>
                    <Link to="/help" className="need-help-r-p-text-s">
                      Need help?
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="intro_to_signup">
                      <h1 className="login__title">
                        <span>Namrolink</span> Password Reset
                      </h1>

                      <p className="login__description">
                        Please enter your email address to search for your
                        account.
                      </p>
                    </div>

                    <div className="user_field_container">
                      <div className="login__inputs">
                        <div>
                          <label htmlFor="email" className="login__label">
                            Email
                          </label>
                          <RegisterFormInput
                            className="login__input"
                            type="text"
                            placeholder="Email address"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            readOnly
                          />
                        </div>
                      </div>

                      <div>
                        {error && <div className="error_text">{error}</div>}
                      </div>
                    </div>

                    <div className="signup_button_spacing"></div>

                    <div>
                      <div>
                        <div className="login__buttons">
                          <button
                            className="login__button login__button-ghost"
                            type="button"
                            onClick={() => navigate("/login")}
                          >
                            <Link to="/login">Cancel</Link>
                          </button>
                          <button className="login__button" type="submit">
                            Search
                          </button>
                        </div>
                      </div>

                      {user ? (
                        <div
                          className="register__forgot bottom-search-account-o"
                          onClick={() => {
                            logout();
                          }}
                        >
                          I would like to log in to another account instead.
                        </div>
                      ) : (
                        <Link
                          className="register__forgot bottom-search-account-o"
                          to="/login"
                        >
                          Login instead.
                        </Link>
                      )}
                      <Link to="/help" className="need-help-r-p-text-s">
                        Need help?
                      </Link>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
