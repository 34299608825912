import { useRef, useState } from "react";
import "./style.css";
import UpdateProfilePicture from "./UpdateProfilePicture";
import useOnClickOutside from "../../helpers/clickOutside";
import { useSelector } from "react-redux";
import CloseIcon from "../icons/CloseIcon";
import PlusIcon from "../icons/PlusIcon";

export default function ProfilePicture({ username, setShow, pRef, photos }) {
  const popup = useRef(null);
  const { user } = useSelector((state) => ({ ...state }));
  useOnClickOutside(popup, () => setShow(false));
  const refInput = useRef(null);
  const [image, setImage] = useState("");
  const [error, setError] = useState("");

  const handleImage = (e) => {
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/webp" &&
      file.type !== "image/gif"
    ) {
      setError(`${file.name} format is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 7) {
      setError(`${file.name} is too large. The maximum allowed size is 7MB.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setImage(event.target.result);
    };
  };

  const profilePictures = photos.filter(
    (img) =>
      img.folder === `namrolink-social-media/${user.username}/profile_pictures`
  );
  const otherPictures = photos.filter(
    (img) =>
      img.folder !== `namrolink-social-media/${user.username}/profile_pictures`
  );

  return (
    <div className="blur">
      <input
        type="file"
        ref={refInput}
        hidden
        onChange={handleImage}
        accept="image/jpeg,image/png,image/webp,image/gif"
      />
      <div className="postBox pictureBox-a-s" ref={popup}>
        <div className="box_header">
          <div className="small_circle" onClick={() => setShow(false)}>
            <i className="close-i-p-s">
              <CloseIcon />
            </i>
          </div>
          <span className="update-p-p-p-s-c">Profile picture</span>
        </div>
        <div className="update-picture-wrap">
          <div className="update-picture-buttons">
            <button
              className="p-update-picture-b"
              onClick={() => refInput.current.click()}
            >
              <i className="plus-icon-p-p-s">
                <PlusIcon />
              </i>
              Upload photo
            </button>
          </div>
        </div>
        {error && (
          <div className="postError comment_error">
            <div className="postError_error">{error}</div>
            <button className="p-blue_btn" onClick={() => setError("")}>
              Try again
            </button>
          </div>
        )}
        <div className="pictureBox">
          <div className="old-pictures-wrap">
            <h4>Your profile pictures</h4>
            {profilePictures.length === 0 ? (
              <div className="no-pic-yet-p-u-m">
                You do not have any pictures yet &#128546;
              </div>
            ) : (
              <div className="old-pictures">
                {profilePictures.map((photo) => (
                  <img
                    src={photo.secure_url}
                    key={photo.public_id}
                    alt=""
                    onClick={() => setImage(photo.secure_url)}
                  />
                ))}
              </div>
            )}
            <h4 className="other-pic-p-t-s">Other pictures</h4>
            {otherPictures.length === 0 ? (
              <div className="no-pic-yet-p-u-m">
                You do not have any pictures yet &#128546;
              </div>
            ) : (
              <div className="old-pictures">
                {otherPictures.map((photo) => (
                  <img
                    src={photo.secure_url}
                    key={photo.public_id}
                    alt=""
                    onClick={() => setImage(photo.secure_url)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {image && (
        <UpdateProfilePicture
          setImage={setImage}
          image={image}
          setShow={setShow}
          setError={setError}
          pRef={pRef}
          popup={popup}
        />
      )}
    </div>
  );
}
