import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../components/icons/SearchIcon";
import { HashLoader } from "react-spinners";
import useClickOutside from "../../helpers/clickOutside";
import EditIcon from "../../components/icons/EditIcon";
import SettingsIcon from "../../components/icons/SettingsIcon";
import MoreRightArrow from "../../components/icons/MoreRightArrow";
import HelpIcon from "../../components/icons/HelpIcon";
import ThemeIcon from "../../components/icons/ThemeIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";
import LogInIcon from "../../components/icons/LogInIcon";
import FontSizeIcon from "../../components/icons/FontSizeIcon";
import PageColorIcon from "../../components/icons/PageColorIcon";
import ButtonColorIcon from "../../components/icons/ButtonColorIcon";
import PreviousImageIcon from "../../components/icons/PreviousImageIcon";
import BackgroundIcon from "../../components/icons/BackgroundIcon";
import EcommerceIcon from "../icons/EcommerceIcon";
import PhoneIcon from "../icons/PhoneIcon";
import ShieldCheckIcon from "../icons/ShieldCheckIcon";
import NavigationIcon from "../icons/NavigationIcon";
import NavigationOffIcon from "../icons/NavigationOffIcon";

export default function NotLoggedInHeader() {
  const { user, darkTheme } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [showSearchMenu, setShowSearchMenu] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const menu = useRef(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [visible, setVisible] = useState(0);
  const dispatch = useDispatch();
  const usermenu = useRef(null);

  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontSize") || "16px"
  );
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primaryColor") || "252"
  );
  const [buttonColor, setButtonColor] = useState(
    localStorage.getItem("buttonColor") || "252"
  );
  const [background, setBackground] = useState(
    JSON.parse(localStorage.getItem("background")) || [
      ["--color-card", "100%"],
      ["--color-bg", "95%"],
      ["--color-font", "17%"],
    ]
  );

  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
    localStorage.setItem("fontSize", fontSize);
  }, [fontSize]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color-hue",
      primaryColor
    );
    localStorage.setItem("primaryColor", primaryColor);
  }, [primaryColor]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--btn-primary-color-hue",
      buttonColor
    );
    localStorage.setItem("buttonColor", buttonColor);
  }, [buttonColor]);

  useEffect(() => {
    background.forEach(([variable, color]) => {
      document.documentElement.style.setProperty(variable, color);
    });
    localStorage.setItem("background", JSON.stringify(background));
  }, [background]);

  const handleFontSizeChange = (size) => {
    setFontSize(`${size}px`);
  };

  const handlePrimaryColorChange = (hue) => {
    setPrimaryColor(hue);
  };

  const handleButtonColorChange = (hue) => {
    setButtonColor(hue);
  };

  const handleBackgroundChange = (rootVars, colors) => {
    const newBackground = rootVars.map((variable, idx) => [
      variable,
      colors[idx],
    ]);
    setBackground(newBackground);
  };

  const images = [
    "../../../images/defaultUserImages/user-img-5.jpg",
    "../../../images/defaultUserImages/user-img-6.jpg",
    "../../../images/defaultUserImages/user-img-2.jpg",
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // 15000 milliseconds = 15 seconds

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [images.length]);

  useClickOutside(menu, () => {
    setShowSearchMenu(false);
  });

  useEffect(() => {
    if (showSearchMenu) {
      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSearchMenu]);

  useClickOutside(usermenu, () => {
    setShowUserMenu(false);
  });

  const setDarkTheme = (value) => {
    Cookies.set("darkTheme", value, { expires: 365 }); // Set cookie to expire in 1 year
    dispatch({ type: value ? "DARK" : "LIGHT" });
  };

  return (
    <div className="nav">
      <div className="element_container">
        <Link to="/">
          <h2 className="logo">Namrolink</h2>
        </Link>
        <div className="search-bar feeds-search u-m-search-s" id="nav-search">
          <i className="uil uil-search user_search_icon cursor-pointer-element-style">
            <SearchIcon />
          </i>
          <input
            type="search"
            name="search"
            id="navbar-search"
            placeholder="Search Namrolink"
            onFocus={() => {
              setShowSearchMenu(true);
            }}
            onClick={() => {
              setShowSearchMenu(true);
            }}
          />
          <select id="navbar-filter">
            <option value="creator">Users</option>
          </select>
          {showSearchMenu && (
            <div className="u-search-menu" ref={menu}>
              <div className="u-search-menu-c-c">
                <div className="u-search-menu-heading">
                  <div className="u-search-menu-title">Recent searches</div>
                  <div
                    className="u-search-menu-close"
                    onClick={() => {
                      setShowSearchMenu(false);
                    }}
                  >
                    Close
                  </div>
                </div>
                {showLoader ? (
                  <div className="seach-menu-loader-container">
                    <HashLoader color="#1876f2" size={50} />
                  </div>
                ) : (
                  <div className="not-logged-in-header-search-menu-prompt hover1-m-p">
                    Please log in to your Namrolink account to view your search
                    history.
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="create" ref={usermenu}>
          <div
            onClick={() => {
              setShowUserMenu((prev) => !prev);
            }}
          >
            <label className="btn btn-primary nav-btn-s-r">Options</label>
          </div>
          {showUserMenu && (
            <div className="mmenu">
              {visible === 0 && (
                <div>
                  <Link to="/register" className="mmenu_header hover1-m-p">
                    <img src={images[currentImageIndex]} alt="" />
                    <div className="mmenu_col">
                      <span>Sign Up?</span>
                      <span>It is quick and easy</span>
                    </div>
                  </Link>
                  <div className="mmenu_splitter"></div>
                  <div
                    className="mmenu_main c-p-o-user-m-s cursor-pointer-element-style hover1-m-p"
                    onClick={() => navigate("/login")}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <EditIcon />
                      </i>
                    </div>
                    <div className="mmenu_col">
                      <div className="mmenu_span1">Create post</div>
                      <div className="mmenu_span2">What is on your mind?</div>
                    </div>
                  </div>
                  <div className="mmenu_splitter"></div>
                  <div
                    className="mmenu_item cursor-pointer-element-style hover1-m-p"
                    onClick={() => {
                      setVisible(1);
                    }}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <SettingsIcon />
                      </i>
                    </div>
                    <span>Settings</span>
                    <div className="rArrow">
                      <i className="right-arrow-icon-u-m">
                        <MoreRightArrow />
                      </i>
                    </div>
                  </div>
                  <div
                    className="mmenu_item cursor-pointer-element-style hover1-m-p"
                    onClick={() => {
                      setVisible(3);
                    }}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <ThemeIcon />
                      </i>
                    </div>
                    <span>Display</span>
                    <div className="rArrow">
                      <i className="right-arrow-icon-u-m">
                        <MoreRightArrow />
                      </i>
                    </div>
                  </div>
                  <div
                    className="mmenu_item cursor-pointer-element-style hover1-m-p"
                    onClick={() => navigate("/login")}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <LogInIcon />
                      </i>
                    </div>
                    <span>Login</span>
                  </div>
                </div>
              )}
              {visible === 1 && (
                <div className="absolute_wrap">
                  <div className="absolute_wrap_header">
                    <div
                      className="circle d-a-a-a-i-s hover1-m-p"
                      onClick={() => {
                        setVisible(0);
                      }}
                    >
                      <i className="arrow-back-icon-u-m-s">
                        <PreviousImageIcon />
                      </i>
                    </div>
                    Settings
                  </div>
                  {!darkTheme ? (
                    <div
                      className="mmenu_item hover1-m-p cursor-pointer-element-style"
                      onClick={() => setDarkTheme(true)}
                    >
                      <div className="small_circle">
                        <i className="user-menu-icon-s">
                          <NavigationIcon />
                        </i>
                      </div>
                      <span>Activate navigator</span>
                    </div>
                  ) : (
                    <div
                      className="mmenu_item hover1-m-p cursor-pointer-element-style"
                      onClick={() => setDarkTheme(false)}
                    >
                      <div className="small_circle">
                        <i className="user-menu-icon-s">
                          <NavigationOffIcon />
                        </i>
                      </div>
                      <span>Deactivate navigator</span>
                    </div>
                  )}
                  <div
                    className="mmenu_item hover1-m-p cursor-pointer-element-style"
                    onClick={() => {
                      window.open("https://www.namronet.com/account", "_blank");
                    }}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <EcommerceIcon />
                      </i>
                    </div>
                    <span>Namronet account</span>
                  </div>
                  <div
                    className="mmenu_item hover1-m-p cursor-pointer-element-style"
                    onClick={() => navigate("/login")}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <PhoneIcon />
                      </i>
                    </div>
                    <span>Phone number</span>
                  </div>
                  <div
                    className="mmenu_item hover1-m-p cursor-pointer-element-style"
                    onClick={() => navigate("/reset")}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <ShieldCheckIcon />
                      </i>
                    </div>
                    <span>Reset password</span>
                  </div>
                  <div
                    className="mmenu_item hover1-m-p cursor-pointer-element-style"
                    onClick={() => navigate("/help")}
                  >
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <HelpIcon />
                      </i>
                    </div>
                    <span>Help center</span>
                  </div>
                </div>
              )}
              {visible === 3 && (
                <div className="absolute_wrap">
                  <div className="absolute_wrap_header">
                    <div
                      className="circle d-a-a-a-i-s hover1-m-p"
                      onClick={() => {
                        setVisible(0);
                      }}
                    >
                      <i className="arrow-back-icon-u-m-s">
                        <PreviousImageIcon />
                      </i>
                    </div>
                    Display settings
                  </div>
                  <div className="mmenu_item">
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <FontSizeIcon />
                      </i>
                    </div>
                    <span>Font size</span>
                  </div>

                  <div className="card-container-header-m">
                    <div className="font-size-header-m">
                      <div>
                        <h6>Aa</h6>
                        <div className="choose-size-header-m">
                          {[14, 16, 18, 20, 22].map((size) => (
                            <span
                              key={size}
                              onClick={() => handleFontSizeChange(size)}
                              className={`font-size-${size / 2} ${
                                fontSize === `${size}px` ? "activeitem" : ""
                              }`}
                            ></span>
                          ))}
                        </div>
                        <h3>Aa</h3>
                      </div>
                    </div>
                  </div>

                  <div className="mmenu_item">
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <PageColorIcon />
                      </i>
                    </div>
                    <span>Page color</span>
                  </div>

                  <div className="card-container-header-m">
                    <div className="color-header-m">
                      <div className="choose-color-header-m">
                        {[252, 52, 352, 152, 202].map((hue, idx) => (
                          <span
                            key={hue}
                            onClick={() => handlePrimaryColorChange(hue)}
                            className={`color-${idx + 1} ${
                              primaryColor === `${hue}` ? "activeitem" : ""
                            }`}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mmenu_item">
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <ButtonColorIcon />
                      </i>
                    </div>
                    <span>Button Color</span>
                  </div>

                  <div className="card-container-header-m">
                    <div className="color-header-m">
                      <div className="choose-color-header-m">
                        {[252, 52, 352, 152, 202].map((hue, idx) => (
                          <span
                            key={hue}
                            onClick={() => handleButtonColorChange(hue)}
                            className={`color-${idx + 1} ${
                              buttonColor === `${hue}` ? "activeitem" : ""
                            }`}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mmenu_item">
                    <div className="small_circle">
                      <i className="user-menu-icon-s">
                        <BackgroundIcon />
                      </i>
                    </div>
                    <span>Background</span>
                  </div>

                  <div className="card-container-header-m">
                    <div className="background-header-m">
                      <div className="choose-bg-header-m">
                        {[
                          ["100%", "95%", "17%"],
                          ["20%", "15%", "90%"],
                          ["10%", "0%", "90%"],
                        ].map((colors, idx) => (
                          <div
                            key={idx}
                            onClick={() =>
                              handleBackgroundChange(
                                ["--color-card", "--color-bg", "--color-font"],
                                colors
                              )
                            }
                            className={`bg-${idx + 1}-h-m ${
                              JSON.stringify(
                                background.map((item) => item[1])
                              ) === JSON.stringify(colors)
                                ? "activeitem"
                                : ""
                            }`}
                          >
                            <span></span>
                            <h5>
                              {idx === 0 ? "Light" : idx === 1 ? "Dim" : "Dark"}
                            </h5>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="header-menu-spacing-element-s"></div>
                </div>
              )}
            </div>
          )}
          <div className="profile-photo">
            <Link to="/profile">
              <img src={images[currentImageIndex]} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
