import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  acceptRequest,
  cancelRequest,
  deleteRequest,
} from "../../functions/user";

export default function Card({ userr, type, getData }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const goToProfile = () => {
    navigate(`/profile/${userr.username}`);
  };
  const cancelRequestHandler = async (userId) => {
    const res = await cancelRequest(userId, user.token);
    if (res == "ok") {
      getData();
    }
  };
  const confirmHandler = async (userId) => {
    const res = await acceptRequest(userId, user.token);
    if (res == "ok") {
      getData();
    }
  };
  const deleteHandler = async (userId) => {
    const res = await deleteRequest(userId, user.token);
    if (res == "ok") {
      getData();
    }
  };
  return (
    <div className="friends-card-c-s">
      {type === "sent" ? (
        <div className="friend-c-w-i">
          <Link to={`/profile/${userr.username}`}>
            <img src={userr.picture} alt="" />
          </Link>
          <div className="friend-card-d-w">
            <div className="friend-card-d-c">
              <div className="friend-card-n-d-s">
                <h4>
                  {userr.first_name} {userr.last_name}
                </h4>
                <p className="text-muted">
                  <small>@{userr.username}</small>
                </p>
              </div>
              <div className="friends-c-b-c-s">
                <button className="btn-primary" onClick={goToProfile}>
                  View
                </button>
                <button
                  className="btn"
                  onClick={() => cancelRequestHandler(userr._id)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : type === "request" ? (
        <div className="friend-c-w-i">
          <Link to={`/profile/${userr.username}`}>
            <img src={userr.picture} alt="" />
          </Link>
          <div className="friend-card-d-w">
            <div className="friend-card-d-c">
              <div className="friend-card-n-d-s">
                <h4>
                  {userr.first_name} {userr.last_name}
                </h4>
                <p className="text-muted">
                  <small>@{userr.username}</small>
                </p>
              </div>
              <div className="friends-c-b-c-s">
                <button
                  className="btn-primary"
                  onClick={() => confirmHandler(userr._id)}
                >
                  Confirm
                </button>
                <button
                  className="btn"
                  onClick={() => deleteHandler(userr._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="friend-c-w-i">
          <Link to={`/profile/${userr.username}`}>
            <img src={userr.picture} alt="" />
          </Link>
          <div className="friend-card-d-w">
            <div className="friend-card-d-c">
              <div className="friend-card-n-d-s">
                <h4>
                  {userr.first_name} {userr.last_name}
                </h4>
                <p className="text-muted">
                  <small>@{userr.username}</small>
                </p>
              </div>
              <div className="friends-c-b-c-s">
                <button className="btn-primary" onClick={goToProfile}>
                  View
                </button>
                <Link to={`/chat/${userr._id}`} className="btn">
                  Message
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
