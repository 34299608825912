export default function Bio({
  infos,
  handleChange,
  max,
  setShowBio,
  updateDetails,
  placeholder,
  name,
  detail,
  setShow,
  rel,
}) {
  return (
    <div>
      <div className="add-bio-h-l-s"></div>
      <div className="add-bio-wrap">
        {rel ? (
          <select
            className="select_rel"
            name={name}
            value={infos.relationship}
            onChange={(e) => {
              if (e.target.value === "Private") {
                handleChange({ target: { name, value: "" } });
              } else {
                handleChange(e);
              }
            }}
          >
            <option value="Private">Private</option>
            <option value="Single">Single</option>
            <option value="In a relationship">In a relationship</option>
            <option value="Engaged">Engaged</option>
            <option value="Married">Married</option>
            <option value="Separated">Separated</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
        ) : (
          <textarea
            placeholder={placeholder}
            name={name}
            value={infos?.[name]}
            maxLength={detail ? 48 : 100}
            className="textarea-bio-d-s details-input-bio"
            onChange={handleChange}
          ></textarea>
        )}
        {!detail && <div className="remaining">{max} characters remaining</div>}
        <div className="flex-p-s">
          <div className="flex-p-s flex-left">
            <i className="public_icon"></i>Public
          </div>
          <div className="flex-p-s flex_right">
            <button
              className="btn gray-cancel-btn"
              onClick={() => (!detail ? setShowBio(false) : setShow(false))}
            >
              Cancel
            </button>
            <button
              className="primary-c-btn"
              onClick={() => {
                updateDetails();
                setShow(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="add-bio-h-l-s"></div>
    </div>
  );
}
