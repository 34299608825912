import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";

export default function SendEmail({
  userInfos,
  email,
  error,
  setError,
  setVisible,
  setUserInfos,
  loading,
  setLoading,
}) {
  const { user } = useSelector((state) => ({ ...state }));
  const [isResetImage, setIsResetImage] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sendEmail = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/sendResetPasswordCode`,
        { email }
      );
      setError("");
      setVisible(2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const toggleResetImage = () => {
    setIsResetImage(!isResetImage);
  };

  return (
    <div>
      <div className="container">
        <div className="login">
          <div className="login__content">
            {isResetImage ? (
              <img
                className="login__img"
                src="../../backgrounds/bg-reset.jpg"
                alt=""
                onClick={toggleResetImage}
              />
            ) : (
              <img
                className="login__img"
                src="../../backgrounds/bg-reset-other.jpg"
                alt=""
                onClick={toggleResetImage}
              />
            )}
            <div className="login__form">
              <div className="intro_to_signup">
                <h1 className="login__title">
                  <span>Namrolink</span> Password Reset
                </h1>

                <p className="login__description user-email-word-c">
                  How would you like to receive the password reset code?
                </p>
              </div>
              <div className="send-via-i-c">
                <p className="login__description user-email-word-c">
                  <input type="radio" name="" id="email" checked readOnly />
                  &nbsp; Send via email to {userInfos.email}
                </p>
              </div>

              <div className="signup_button_spacing"></div>

              <div className="send-email-user-content-h">
                <img
                  src={userInfos.picture}
                  alt=""
                  className="user-reset-img-p"
                />
                <span>{userInfos.email}</span>
                <span>Namrolink user</span>
              </div>

              <div className="user_field_container">
                <div>{error && <div className="error_text">{error}</div>}</div>
              </div>

              <div>
                <div className="login__buttons">
                  <button
                    className="login__button login__button-ghost"
                    type="button"
                    onClick={() => navigate("/login")}
                  >
                    <Link to="/login">No</Link>
                  </button>
                  <button
                    className="login__button"
                    type="submit"
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    Yes
                  </button>
                </div>
                {user ? (
                  <div
                    className="register__forgot bottom-search-account-o"
                    onClick={() => {
                      logout();
                    }}
                  >
                    I would like to log in to another account instead.
                  </div>
                ) : (
                  <Link
                    className="register__forgot bottom-search-account-o"
                    to="/login"
                  >
                    Login instead.
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
