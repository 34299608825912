import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import RegisterFormInput from "../../components/inputs/registerFormInput";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useState } from "react";

export default function CodeVerification({
  code,
  setCode,
  error,
  loading,
  setLoading,
  setVisible,
  setError,
  userInfos,
}) {
  const { user } = useSelector((state) => ({ ...state }));
  const [isResetImage, setIsResetImage] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validateCode = Yup.object({
    code: Yup.string()
      .required("Code is required")
      .min("5", "Code must be 5 characters.")
      .max("5", "Code must be 5 characters."),
  });
  const { email } = userInfos;
  const verifyCode = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/validateResetCode`,
        { email, code }
      );
      setVisible(3);
      setError("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };

  const toggleResetImage = () => {
    setIsResetImage(!isResetImage);
  };

  return (
    <div className="container">
      <div className="login">
        <div className="login__content">
          {isResetImage ? (
            <img
              className="login__img"
              src="../../backgrounds/bg-reset.jpg"
              alt=""
              onClick={toggleResetImage}
            />
          ) : (
            <img
              className="login__img"
              src="../../backgrounds/bg-reset-other.jpg"
              alt=""
              onClick={toggleResetImage}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={{
              code,
            }}
            validationSchema={validateCode}
            onSubmit={() => {
              verifyCode();
            }}
          >
            {(formik) => (
              <Form className="login__form">
                <div className="intro_to_signup">
                  <h1 className="login__title">
                    <span>Namrolink</span> Password Reset
                  </h1>

                  <p className="login__description">
                    Please enter the code that has been sent to your email.
                  </p>
                </div>

                <div className="user_field_container">
                  <div className="login__inputs">
                    <div>
                      <label for="email" className="login__label">
                        Reset Code
                      </label>
                      <RegisterFormInput
                        className="login__input"
                        type="text"
                        placeholder="Enter the reset code"
                        name="code"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="signup_button_spacing"></div>

                  <div>
                    {error && <div className="error_text">{error}</div>}
                  </div>
                </div>

                <div>
                  <div className="login__buttons">
                    <button
                      className="login__button login__button-ghost"
                      type="button"
                      onClick={() => navigate("/login")}
                    >
                      <Link to="/login">Cancel</Link>
                    </button>

                    <button className="login__button" type="submit">
                      Continue
                    </button>
                  </div>

                  {user ? (
                    <div
                      className="register__forgot bottom-search-account-o"
                      onClick={() => {
                        logout();
                      }}
                    >
                      I would like to log in to another account instead.
                    </div>
                  ) : (
                    <Link
                      className="register__forgot bottom-search-account-o"
                      to="/login"
                    >
                      Login instead.
                    </Link>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
