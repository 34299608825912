import { useState } from "react";
import Bio from "./Bio";
import EditDetailIcon from "../icons/EditDetailIcon";

import InfoIcon from "../icons/InfoIcon";
import HeartSolidIcon from "../icons/HeartSolidIcon";
import WorkIcon from "../icons/WorkIcon";
import WorkplaceIcon from "../icons/WorkplaceIcon";
import StudiedIcon from "../icons/StudiedIcon";
import StudiedSolidIcon from "../icons/StudiedSolidIcon";
import FromIcon from "../icons/FromIcon";
import LivesInIcon from "../icons/LivesInIcon";
import GlobeIcon from "../icons/GlobeIcon";
import IdentificationSolidIcon from "../icons/IdentificationSolidIcon";
import AddPlusCircleIcon from "../icons/AddPlusCircleIcon";

export default function Detail({
  img,
  value,
  placeholder,
  name,
  handleChange,
  updateDetails,
  infos,
  text,
  rel,
}) {
  const [show, setShow] = useState(false);
  const iconComponents = {
    InfoIcon: InfoIcon,
    HeartSolidIcon: HeartSolidIcon,
    WorkIcon: WorkIcon,
    WorkplaceIcon: WorkplaceIcon,
    StudiedIcon: StudiedIcon,
    StudiedSolidIcon: StudiedSolidIcon,
    FromIcon: FromIcon,
    LivesInIcon: LivesInIcon,
    GlobeIcon: GlobeIcon,
    IdentificationSolidIcon: IdentificationSolidIcon,
    AddPlusCircleIcon: AddPlusCircleIcon,
  };

  const IconComponent = iconComponents[img];

  return (
    <div>
      <div className="add_details_flex " onClick={() => setShow(true)}>
        {value ? (
          <div className="info-profile">
            <div className="edit-detail-p-i-s">
              <IconComponent />
            </div>
            {value}
            <i className="edit-d-icon">
              <EditDetailIcon />
            </i>
          </div>
        ) : (
          <>
            <i className="rounded-plus-icon-d">
              <AddPlusCircleIcon />
            </i>
            <span className="underline-h">Add {text}</span>
          </>
        )}
      </div>
      {show && (
        <Bio
          placeholder={placeholder}
          name={name}
          handleChange={handleChange}
          updateDetails={updateDetails}
          infos={infos}
          detail
          setShow={setShow}
          rel={rel}
        />
      )}
    </div>
  );
}
