import React, { useState, useEffect } from "react";
import { Formik, Form, useField, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import DotLoader from "react-spinners/DotLoader";

const LoginInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="input_wrapper">
      <label className="login__label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input
        className={`login__input ${
          meta.touched && meta.error ? "input_error_border" : ""
        }`}
        {...field}
        {...props}
      />
      <ErrorMessage
        name={field.name}
        component="div"
        className="input_error_message"
      />
    </div>
  );
};

const LoginPage = ({ setVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoginImage, setIsLoginImage] = useState(true);

  const toggleLoginImage = () => {
    setIsLoginImage(!isLoginImage);
  };

  const loginValidation = Yup.object({
    email: Yup.string()
      .required("Email address is required.")
      .email("Must be a valid email.")
      .max(80, "Email must not be more than 80 characters."),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be at least 6 characters.")
      .max(36, "Password cannot be more than 36 characters"),
  });

  const loginSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        values
      );
      dispatch({ type: "LOGIN", payload: data });
      Cookies.set("user", JSON.stringify(data), { expires: 365 });
      navigate("/");
    } catch (error) {
      setSubmitting(false);
      setErrorMessages([error.response.data.message]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessages.length > 0) {
      const timer = setTimeout(() => {
        setErrorMessages([]);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessages]);

  return (
    <div className="container">
      <div className="login">
        <div className="login__content">
          {isLoginImage ? (
            <img
              className="login__img"
              src="../../backgrounds/bg-login.jpg"
              alt="Login image"
              onClick={toggleLoginImage}
            />
          ) : (
            <img
              className="login__img"
              src="../../backgrounds/bg-login-other.jpg"
              alt="Login image"
              onClick={toggleLoginImage}
            />
          )}

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidation}
            onSubmit={loginSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="login__form">
                <div>
                  <h1 className="login__title">
                    Welcome to <span>Namrolink</span>
                  </h1>
                  <p className="login__description">
                    Please login to continue.
                  </p>
                </div>

                <div>
                  <div className="login__inputs">
                    <LoginInput
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Enter your email address"
                    />
                    <LoginInput
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>

                <div>
                  <div className="login__buttons">
                    <button
                      className="login__button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {loading ? (
                        <DotLoader
                          color="#ffffff"
                          loading={loading}
                          size={10}
                        />
                      ) : (
                        "Log In"
                      )}{" "}
                    </button>
                    <button
                      className="login__button login__button-ghost"
                      type="button"
                      onClick={() => navigate("/register")}
                    >
                      <Link to="/register">Sign Up</Link>
                    </button>
                  </div>
                  <Link className="login__forgot" to="/reset">
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            )}
          </Formik>

          {errorMessages.length > 0 && (
            <div className="error_container">
              {errorMessages.map((message, index) => (
                <div className="error_message" key={index}>
                  {message}
                  <button
                    className="close_button"
                    onClick={() =>
                      setErrorMessages(
                        errorMessages.filter((_, i) => i !== index)
                      )
                    }
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
