import "./style.css";
import HomeIcon from "../../components/icons/HomeIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import PhotoIcon from "../../components/icons/PhotoIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import SettingsIcon from "../../components/icons/SettingsIcon";
import { Link, useLocation } from "react-router-dom";
import FriendsIcon from "../icons/FriendsIcon";
import HelpIcon from "../icons/HelpIcon";
import FriendGroupIcon from "../icons/FriendGroupIcon";

export default function BottomMenu() {
  const location = useLocation();
  const path = location.pathname;

  const isActive = (page) => {
    if (page === "home") return path === "/";
    if (page === "profile") return path.startsWith("/profile");
    if (page === "friends") return path.startsWith("/friends");
    if (page === "discover") return path.startsWith("/discover");
    if (page === "help") return path === "/help";
    if (page === "chat") return path.startsWith("/chat");
    return false;
  };

  return (
    <div>
      <div className="bottom-navigation-menu-container">
        <div className="bottom-naigation-menu-content">
          <div className="navigation-bottom">
            <ul>
              {!path.startsWith("/chat") && (
                <li
                  className={`nav-bottom-list ${
                    isActive("discover") ? "active-bottom-nav" : ""
                  }`}
                >
                  <Link to="/discover">
                    <span className="nav-bottom-icon">
                      <FriendGroupIcon />
                    </span>
                    <span className="nav-bottom-text">Discover</span>
                  </Link>
                </li>
              )}

              {path.startsWith("/chat") && (
                <li
                  className={`nav-bottom-list ${
                    isActive("chat") ? "active-bottom-nav" : ""
                  }`}
                >
                  <Link to="/chat">
                    <span className="nav-bottom-icon">
                      <MessagesIcon />
                    </span>
                    <span className="nav-bottom-text">Message</span>
                  </Link>
                </li>
              )}

              <li
                className={`nav-bottom-list ${
                  isActive("profile") ? "active-bottom-nav" : ""
                }`}
              >
                <Link to="/profile">
                  <span className="nav-bottom-icon">
                    <ProfileIcon />
                  </span>
                  <span className="nav-bottom-text">Profile</span>
                </Link>
              </li>

              <li
                className={`nav-bottom-list ${
                  isActive("home") ? "active-bottom-nav" : ""
                }`}
              >
                <Link to="/">
                  <span className="nav-bottom-icon">
                    <HomeIcon />
                  </span>
                  <span className="nav-bottom-text">Home</span>
                </Link>
              </li>

              <li
                className={`nav-bottom-list ${
                  isActive("friends") ? "active-bottom-nav" : ""
                }`}
              >
                <Link to="/friends">
                  <span className="nav-bottom-icon">
                    <FriendsIcon />
                  </span>
                  <span className="nav-bottom-text">Friends</span>
                </Link>
              </li>

              <li
                className={`nav-bottom-list ${
                  isActive("help") ? "active-bottom-nav" : ""
                }`}
              >
                <Link to="/help">
                  <span className="nav-bottom-icon">
                    <HelpIcon />
                  </span>
                  <span className="nav-bottom-text">Help</span>
                </Link>
              </li>

              <div className="nav-bottom-indicator"></div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
