import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "../../components/icons/CloseIcon";
import useClickOutside from "../../helpers/clickOutside";
import { getFriendsPageInfos } from "../../functions/user";
import { Link, useNavigate } from "react-router-dom";

export default function ChatFriends({ setShowChatFriendsMenu, onlineUsers }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [friends, setFriends] = useState([]);
  const chatfriendsmenu = useRef(null);
  const navigate = useNavigate();

  useClickOutside(chatfriendsmenu, () => {
    setShowChatFriendsMenu(false);
  });

  useEffect(() => {
    const fetchFriends = async () => {
      const data = await getFriendsPageInfos(user.token);
      if (data.status === "ok") {
        setFriends(data.data.friends);
      }
    };
    fetchFriends();
  }, [user.token]);

  return (
    <div className="blur">
      <div className="chat-friends-box" ref={chatfriendsmenu}>
        <div className="user-chat-friends-title-container-s">
          <div className="user-chat-friends-title">
            Your <span>friends</span>
          </div>
          <i
            onClick={() => {
              setShowChatFriendsMenu(false);
            }}
          >
            <CloseIcon />
          </i>
        </div>
        <div className="chat-friends-content-c">
          <div className="user-chat-friends-list">
            {friends.length === 0 && <div>No friends yet</div>}
            {friends.map((friend) => (
              <div
                className="user-chat-friends-l-item"
                key={friend._id}
                onClick={() => {
                  navigate(`/chat/${friend._id}`);
                  setShowChatFriendsMenu(false);
                }}
              >
                <div className="user-friend-s-p-chat-div">
                  <img src={friend.picture} alt="" />
                  {onlineUsers.includes(friend._id) && (
                    <div className="user-friend-online-c-s"></div>
                  )}
                </div>
                <div className="user-chat-p-p-friends-name">
                  {friend.first_name} {friend.last_name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
