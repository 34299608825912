import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import DotLoader from "react-spinners/DotLoader";
import axios from "axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import RegisterFormInput from "../inputs/registerFormInput";
import DateOfBirthRegister from "./DateOfBirthRegister";
import GenderRegister from "./GenderRegister";

export default function RegisterForm({ setVisible }) {
  const [googleEmail, setGoogleEmail] = useState("");
  const [googleLoggedIn, setGoogleLoggedIn] = useState(false);
  const [isRegisterImage, setIsRegisterImage] = useState(true);

  const toggleRegisterImage = () => {
    setIsRegisterImage(!isRegisterImage);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("email");
    if (emailParam) {
      setGoogleEmail(emailParam);
      setGoogleLoggedIn(true);
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, [setGoogleEmail]);

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google/service`;
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfos = {
    first_name: "",
    last_name: "",
    email: googleEmail,
    password: "",
    bYear: new Date().getFullYear(),
    bMonth: new Date().getMonth() + 1,
    bDay: new Date().getDate(),
    gender: "",
  };
  const [user, setUser] = useState(userInfos);
  const {
    first_name,
    last_name,
    email,
    password,
    bYear,
    bMonth,
    bDay,
    gender,
  } = user;
  const yearTemp = new Date().getFullYear();
  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const years = Array.from(new Array(108), (val, index) => yearTemp - index);
  const months = Array.from(new Array(12), (val, index) => 1 + index);
  const getDays = () => {
    return new Date(bYear, bMonth, 0).getDate();
  };
  const days = Array.from(new Array(getDays()), (val, index) => 1 + index);
  const registerValidation = Yup.object({
    first_name: Yup.string()
      .required("What is your first name?")
      .min(2, "First name must be between 2 and 16 characters.")
      .max(16, "First name must be between 2 and 16 characters.")
      .matches(/^[aA-zZ]+$/, "Numbers, spaces and symbols are not allowed."),
    last_name: Yup.string()
      .required("What's your last name?")
      .min(2, "Last name must be between 2 and 16 characters.")
      .max(16, "Last name must be between 2 and 16 characters.")
      .matches(/^[aA-zZ]+$/, "Numbers, spaces and symbols are not allowed."),
    email: Yup.string()
      .required("You will need this to log in. You can reset it later.")
      .email("Enter a valid email address.")
      .max(80, "Email must not be more than 80 characters."),
    password: Yup.string()
      .required("Enter a combination of at least 6 characters.")
      .min(6, "Password must be at least 6 characters.")
      .max(36, "Password cannot be more than 36 characters"),
  });
  const [dateError, setDateError] = useState("");
  const [genderError, setGenderError] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const registerSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register`,
        {
          first_name,
          last_name,
          email: googleEmail,
          password,
          bYear,
          bMonth,
          bDay,
          gender,
        }
      );
      setError("");
      setSuccess(data.message);
      const { message, ...rest } = data;
      setTimeout(() => {
        dispatch({ type: "LOGIN", payload: rest });
        Cookies.set("user", JSON.stringify(rest), { expires: 365 });
        navigate("/");
        setLoading(false);
      }, 2000);
    } catch (error) {
      setLoading(false);
      setSuccess("");
      setError(error.response.data.message);
    }
  };
  return (
    <div className="container">
      <div className="login">
        <div className="login__content">
          {isRegisterImage ? (
            <img
              className="login__img"
              src="../../backgrounds/bg-register-1.jpg"
              alt=""
              onClick={toggleRegisterImage}
            />
          ) : (
            <img
              className="login__img"
              src="../../backgrounds/bg-register-2.jpg"
              alt=""
              onClick={toggleRegisterImage}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={{
              first_name,
              last_name,
              email: googleEmail,
              password,
              bYear,
              bMonth,
              bDay,
              gender,
            }}
            validationSchema={registerValidation}
            onSubmit={() => {
              let current_date = new Date();
              let picked_date = new Date(bYear, bMonth - 1, bDay);
              let atleast14 = new Date(1970 + 14, 0, 1);
              let noMoreThan90 = new Date(1970 + 90, 0, 1);
              if (current_date - picked_date < atleast14) {
                setDateError("Unfortunately, you are too young to sign up.");
              } else if (current_date - picked_date > noMoreThan90) {
                setDateError(
                  "It looks like you have enetered the wrong information."
                );
              } else if (gender === "") {
                setDateError("");
                setGenderError("Please pick your gender.");
              } else {
                setDateError("");
                setGenderError("");
                registerSubmit();
              }
            }}
          >
            {(formik) => (
              <Form className="login__form">
                {!googleLoggedIn ? (
                  <div>
                    <div className="intro_to_signup">
                      <h1 className="login__title">
                        <span>Namrolink</span> Sign Up
                      </h1>

                      <p className="login__description">
                        Please login to your gmail account to start the sign up
                        process.
                      </p>
                    </div>

                    <div className="user_field_container">
                      <div className="login__inputs"></div>
                    </div>
                    <div className="signup_button_spacing"></div>

                    <div>
                      <div className="login__buttons">
                        <button
                          className="login__button"
                          onClick={handleGoogleLogin}
                        >
                          Sign up with Google
                        </button>
                      </div>
                      <Link className="login__forgot" to="/login">
                        I already have an account. Login instead.
                      </Link>
                      <Link to="/help" className="need-help-r-p-text-s">
                        Need help?
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="intro_to_signup">
                      <h1 className="login__title">
                        <span>Namrolink</span> Sign Up
                      </h1>

                      <p className="login__description">
                        Signing up is quick and easy.
                      </p>
                    </div>

                    <div className="user_field_container">
                      <div className="login__inputs">
                        <div className="my_flexed_name_fields">
                          <div className="user_name_input_field">
                            <label for="email" className="login__label">
                              First name
                            </label>
                            <RegisterFormInput
                              className="login__input"
                              type="text"
                              name="first_name"
                              placeholder="First name"
                              onChange={handleRegisterChange}
                            />
                          </div>

                          <div className="user_name_input_field">
                            <label for="email" className="login__label">
                              Last name
                            </label>
                            <RegisterFormInput
                              className="login__input"
                              type="text"
                              placeholder="Last name"
                              name="last_name"
                              onChange={handleRegisterChange}
                            />
                          </div>
                        </div>

                        <div>
                          <label for="email" className="login__label">
                            Email
                          </label>
                          <RegisterFormInput
                            className="login__input"
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            onChange={handleRegisterChange}
                            value={googleEmail}
                            readOnly
                          />
                        </div>

                        <div>
                          <label for="password" className="login__label">
                            Password
                          </label>
                          <div className="login__box">
                            <RegisterFormInput
                              className="login__input"
                              type="password"
                              placeholder="Enter your password"
                              name="password"
                              onChange={handleRegisterChange}
                            />
                            <i
                              className="ri-eye-off-line login__eye"
                              id="input-icon"
                            ></i>
                          </div>
                        </div>
                      </div>

                      <DateOfBirthRegister
                        bDay={bDay}
                        bMonth={bMonth}
                        bYear={bYear}
                        days={days}
                        months={months}
                        years={years}
                        handleRegisterChange={handleRegisterChange}
                        dateError={dateError}
                      />

                      <GenderRegister
                        handleRegisterChange={handleRegisterChange}
                        genderError={genderError}
                      />

                      <div className="signup_button_spacing"></div>

                      <div>
                        <div className="register_button_preloader">
                          <DotLoader
                            color="#1876f2"
                            loading={loading}
                            size={30}
                          />
                        </div>
                        {error && <div className="error_text">{error}</div>}
                        {success && (
                          <div className="success_text">{success}</div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="login__buttons">
                        <button className="login__button">Sign up</button>
                      </div>

                      <Link className="login__forgot" to="/login">
                        I already have an account. Login instead.
                      </Link>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
