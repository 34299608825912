import { useRef, useState } from "react";
import MenuItem from "./MenuItem";
import useOnClickOutside from "../../helpers/clickOutside";
import { deletePost, savePost } from "../../functions/post";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import FeedbackPopup from "../feedbackPopup";

export default function PostMenu({
  postUserId,
  userId,
  imagesLength,
  setShowMenu,
  token,
  postId,
  checkSaved,
  setCheckSaved,
  images,
  postRef,
  post,
}) {
  const [test, setTest] = useState(postUserId === userId ? true : false);
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const menu = useRef(null);
  const navigate = useNavigate();
  useOnClickOutside(menu, () => setShowMenu(false));
  const saveHandler = async () => {
    savePost(postId, token);
    if (checkSaved) {
      setCheckSaved(false);
    } else {
      setCheckSaved(true);
    }
  };
  const downloadImages = async () => {
    images.map((img) => {
      saveAs(img.url, "image.jpg");
    });
  };
  const deleteHandler = async () => {
    const res = await deletePost(postId, token);
    if (res.status === "ok") {
      postRef.current.remove();
    }
  };
  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };
  return (
    <ul className="post_menu" ref={menu}>
      <div onClick={() => saveHandler()}>
        {checkSaved ? (
          <MenuItem
            icon="UnSavePostIcon"
            title="Unsave Post"
            subtitle="Remove from saved items"
          />
        ) : (
          <MenuItem
            icon="SavePostIcon"
            title="Save Post"
            subtitle="Add to your saved items"
          />
        )}
      </div>
      {imagesLength && (
        <div onClick={() => downloadImages()}>
          <MenuItem icon="DownloadIcon" title="Download" />
        </div>
      )}
      <div className="line"></div>

      {test && (
        <div onClick={() => deleteHandler()}>
          <MenuItem
            icon="DeleteIcon"
            title="Delete post"
            subtitle="This action cannot be undone"
          />
        </div>
      )}
      {!test && (
        <div onClick={() => openFeedbackPopup()}>
          <MenuItem
            icon="ReportIcon"
            title="Report post"
            subtitle="I am concerned about this post"
          />
        </div>
      )}
      {isFeedbackPopupOpen && (
        <FeedbackPopup
          subject={`Post reported of id: ${postId} (${post.user.username})`}
          onClose={closeFeedbackPopup} // Pass a function to close the popup
        />
      )}
    </ul>
  );
}
