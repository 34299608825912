import "../../styles/right.css";
import AdvertisementRight from "./AdvertisementRight";
import RightMessagesBox from "./RightMessagesBox";
import RightImagesSlider from "./RightImagesSlider";
import RightUserInfo from "./RightUserInfo";

export default function RightContainer() {
  return (
    <div className="right">
      <RightMessagesBox />
      <AdvertisementRight />
      <RightUserInfo />
      <RightImagesSlider />
    </div>
  );
}
