import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DisplayAccessibility from "./DisplayAccessibility";
import SettingsPrivacy from "./SettingsPrivacy";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "../../../styles/general.css";
import "../../../styles/theme.css";
import EditIcon from "../../icons/EditIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import ThemeIcon from "../../icons/ThemeIcon";
import MoreRightArrow from "../../icons/MoreRightArrow";

export default function UserMenu({ user, setPostPopupVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(0);
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    navigate("/login");
  };
  return (
    <div className="mmenu">
      {visible === 0 && (
        <div>
          <Link to="/profile" className="mmenu_header hover1-m-p">
            <img src={user?.picture} alt="" />
            <div className="mmenu_col">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
              <span>See your profile</span>
            </div>
          </Link>
          <div className="mmenu_splitter"></div>
          <div
            className="mmenu_main c-p-o-user-m-s hover1-m-p cursor-pointer-element-style"
            onClick={() => setPostPopupVisible(true)}
          >
            <div className="small_circle">
              <i className="user-menu-icon-s">
                <EditIcon />
              </i>
            </div>
            <div className="mmenu_col">
              <div className="mmenu_span1">Create post</div>
              <div className="mmenu_span2">What is on your mind?</div>
            </div>
          </div>
          <div className="mmenu_splitter"></div>
          <div
            className="mmenu_item cursor-pointer-element-style hover1-m-p"
            onClick={() => {
              setVisible(1);
            }}
          >
            <div className="small_circle">
              <i className="user-menu-icon-s">
                <SettingsIcon />
              </i>
            </div>
            <span>Settings</span>
            <div className="rArrow">
              <i className="right-arrow-icon-u-m">
                <MoreRightArrow />
              </i>
            </div>
          </div>
          <div
            className="mmenu_item cursor-pointer-element-style hover1-m-p"
            onClick={() => {
              setVisible(3);
            }}
          >
            <div className="small_circle">
              <i className="user-menu-icon-s">
                <ThemeIcon />
              </i>
            </div>
            <span>Display</span>
            <div className="rArrow">
              <i className="right-arrow-icon-u-m">
                <MoreRightArrow />
              </i>
            </div>
          </div>
          <div
            className="mmenu_item cursor-pointer-element-style hover1-m-p"
            onClick={() => {
              logout();
            }}
          >
            <div className="small_circle">
              <i className="user-menu-icon-s">
                <LogoutIcon />
              </i>
            </div>
            <span>Logout</span>
          </div>
        </div>
      )}
      {visible === 1 && <SettingsPrivacy setVisible={setVisible} />}
      {visible === 3 && <DisplayAccessibility setVisible={setVisible} />}
    </div>
  );
}
