import "./style.css";
import { useSelector } from "react-redux";
import SearchIcon from "../icons/SearchIcon";
import { useEffect, useRef, useState } from "react";
import useClickOutside from "../../helpers/clickOutside";
import UserMenu from "./userMenu";
import { Link } from "react-router-dom";
import SearchMenu from "./SearchMenu";
import { HashLoader } from "react-spinners";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearch,
  search,
} from "../../functions/user";
import axios from "axios";
import Banned from "../banned";

export default function Header({
  activeMenuItem,
  setVisible: setPostPopupVisible,
}) {
  const { user } = useSelector((user) => ({ ...user }));
  const [showSearchMenu, setShowSearchMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const usermenu = useRef(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  useClickOutside(usermenu, () => {
    setShowUserMenu(false);
  });

  useEffect(() => {
    getHistory();
  }, []);
  const getHistory = async () => {
    const res = await getSearchHistory(user.token);
    setSearchHistory(res);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.trim() === "") {
        setResults([]);
        setNoResults(false);
        return;
      }

      setLoading(true);
      const res = await search(searchTerm, user.token);
      setLoading(false);

      if (res.length === 0) {
        setNoResults(true);
        setResults([]);
      } else {
        setNoResults(false);
        setResults(res);
      }
    }, 1000); // Delay of 1 second

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, user.token]);

  const addToSearchHistoryHandler = async (searchUser) => {
    const res = await addToSearchHistory(searchUser, user.token);
    getHistory();
  };
  const handleRemove = async (searchUser) => {
    removeFromSearch(searchUser, user.token);
    getHistory();
  };

  useEffect(() => {
    const navbarSearchContainer = document.getElementById("nav-search");

    if (activeMenuItem === "explore" && window.innerWidth > 992) {
      navbarSearchContainer.style.boxShadow = "0 0 1rem var(--color-primary)";
      setTimeout(() => {
        navbarSearchContainer.style.boxShadow = "none";
      }, 2000);
    }
  }, [activeMenuItem]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingUserDetails(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUserDetails(false);
      }
    };

    fetchUserDetails();
  }, [user.token]);

  return (
    <div>
      <div className="nav">
        <div className="element_container">
          <Link to="/">
            <h2 className="logo">Namrolink</h2>
          </Link>
          <div className="search-bar feeds-search u-m-search-s" id="nav-search">
            <i className="uil uil-search user_search_icon cursor-pointer-element-style">
              <SearchIcon />
            </i>
            <input
              type="search"
              name="search"
              id="navbar-search"
              placeholder="Search Namrolink"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => {
                setShowSearchMenu(true);
              }}
              onClick={() => {
                setShowSearchMenu(true);
              }}
            />
            <select id="navbar-filter">
              <option value="creator">Users</option>
            </select>
            {showSearchMenu && (
              <SearchMenu
                setShowSearchMenu={setShowSearchMenu}
                results={results}
                searchHistory={searchHistory}
                addToSearchHistoryHandler={addToSearchHistoryHandler}
                handleRemove={handleRemove}
                loading={loading}
                noResults={noResults}
                searchTerm={searchTerm}
              />
            )}
          </div>
          <div className="create" ref={usermenu}>
            <div
              onClick={() => {
                setShowUserMenu((prev) => !prev);
              }}
            >
              <label className="btn btn-primary nav-btn-s-r">Options</label>
            </div>
            {showUserMenu && (
              <UserMenu user={user} setPostPopupVisible={setPostPopupVisible} />
            )}
            {userDetails?.banned === true && <Banned />}
            <div className="profile-photo">
              <Link to="/profile">
                <img src={user?.picture} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
