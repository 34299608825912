import axios from "axios";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { postsReducer } from "../../functions/reducers";
import UserFeedPost from "../../components/post/UserFeedPost";
import { HashLoader } from "react-spinners";

export default function DiscoverFeed() {
  const { user } = useSelector((user) => ({ ...user }));
  const [{ loading, error, posts }, dispatch] = useReducer(postsReducer, {
    loading: false,
    posts: [],
    error: "",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get("postId");

  const getEveryPost = async () => {
    try {
      dispatch({
        type: "POSTS_REQUEST",
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getEveryPost`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch({
        type: "POSTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({
        type: "POSTS_ERROR",
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getEveryPost();
    }
  }, [user]);

  const filteredPosts = postId
    ? posts.filter((post) => post._id === postId)
    : posts;

  return (
    <>
      <div className="friends-t-h-s">
        <h4>User feed</h4>
      </div>
      {loading ? (
        <div className="u-f-m-loader-s">
          <HashLoader color="#1876f2" />
        </div>
      ) : (
        <div>
          {filteredPosts.length === 0 ? (
            <h4 className="no-posts-y-u-f">No posts yet</h4>
          ) : (
            filteredPosts.map((post, i) => <UserFeedPost key={i} post={post} />)
          )}
        </div>
      )}
    </>
  );
}
