import ArrowUpIcon from "../icons/ArrowUpIcon";

export default function BioButtonSwap({
  infos,
  handleChange,
  max,
  setShowBio,
  updateDetails,
  placeholder,
  name,
  detail,
  setShow,
  rel,
}) {
  return (
    <div className="btn btn-primary u-p-i-btn bio-btn-swap-s">
      <ArrowUpIcon />
      Your Info
    </div>
  );
}
