export default function DateOfBirthRegister({
  bDay,
  bMonth,
  bYear,
  days,
  months,
  years,
  handleRegisterChange,
  dateError,
}) {
  return (
    <div>
      <label for="dateofbirth" className="login__label">
        Date of birth
      </label>
      <div className="register_date_section">
        <div className="dob-container">
          <select
            className="dob-select"
            id="day"
            name="bDay"
            value={bDay}
            onChange={handleRegisterChange}
          >
            {days.map((day, i) => (
              <option value={day} key={i}>
                {day}
              </option>
            ))}
          </select>
          <span className="dob-gap">/</span>
          <select
            className="dob-select"
            id="month"
            name="bMonth"
            value={bMonth}
            onChange={handleRegisterChange}
          >
            {months.map((month, i) => (
              <option value={month} key={i}>
                {month}
              </option>
            ))}
          </select>
          <span className="dob-gap">/</span>
          <select
            className="dob-select"
            id="year"
            name="bYear"
            value={bYear}
            onChange={handleRegisterChange}
          >
            {years.map((year, i) => (
              <option value={year} key={i}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      {dateError && <div className="input_error_message">{dateError}</div>}
    </div>
  );
}
