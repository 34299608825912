import { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from "../../components/icons/SearchIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import { HashLoader } from "react-spinners";
import useClickOutside from "../../helpers/clickOutside";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearch,
  search,
} from "../../functions/user";

export default function HelpExploreSearch() {
  const { user } = useSelector((user) => ({ ...user }));
  const [showExploreSearchMenu, setShowExploreSearchMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const esmenu = useRef(null);

  const exploreTextRef = useRef(null);
  useClickOutside(esmenu, () => {
    setShowExploreSearchMenu(false);
  });

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    const res = await getSearchHistory(user.token);
    setSearchHistory(res);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.trim() === "") {
        setResults([]);
        setNoResults(false);
        return;
      }

      setLoading(true);
      const res = await search(searchTerm, user.token);
      setLoading(false);

      if (res.length === 0) {
        setNoResults(true);
        setResults([]);
      } else {
        setNoResults(false);
        setResults(res);
      }
    }, 1000); // Delay of 1 second

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, user.token]);

  const addToSearchHistoryHandler = async (searchUser) => {
    const res = await addToSearchHistory(searchUser, user.token);
    getHistory();
  };
  const handleRemove = async (searchUser) => {
    removeFromSearch(searchUser, user.token);
    getHistory();
  };

  useEffect(() => {
    if (showExploreSearchMenu && exploreTextRef.current) {
      exploreTextRef.current.focus();
    }
  }, [showExploreSearchMenu]);

  return (
    <div>
      <div className="help-explore-search">
        <i className="uil uil-search user_search_icon">
          <SearchIcon />
        </i>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search Namrolink"
          onFocus={() => {
            setShowExploreSearchMenu(true);
            if (exploreTextRef.current) {
              exploreTextRef.current.focus();
            }
          }}
          onClick={() => {
            setShowExploreSearchMenu(true);
            if (exploreTextRef.current) {
              exploreTextRef.current.focus();
            }
          }}
        />
        <select id="filter">
          <option value="creator">Users</option>
        </select>
        {showExploreSearchMenu && (
          <div className="blur">
            <div className="u-explore-search-menu-container" ref={esmenu}>
              <div className="u-explore-search-bar-s-container">
                <i className="explore-s-search-icon-s cursor-pointer-element-style">
                  <SearchIcon />
                </i>
                <input
                  type="text"
                  name="search"
                  placeholder="Search Namrolink"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  ref={exploreTextRef}
                />
                <select>
                  <option value="creator">Users</option>
                </select>
              </div>
              <div className="u-explore-search-users-list-container">
                <div className="u-eplore-s-s-content-c">
                  <div className="u-explore-search-menu-c-c">
                    {loading && searchTerm.trim() !== "" && (
                      <div className="seach-menu-loader-container">
                        <HashLoader color="#1876f2" size={50} />
                      </div>
                    )}
                    {results.length === 0 && !noResults && (
                      <div className="u-search-menu-heading">
                        <div className="u-search-menu-title">
                          Recent searches
                        </div>
                        <div
                          className="u-search-menu-close"
                          onClick={() => {
                            setShowExploreSearchMenu(false);
                          }}
                        >
                          Close
                        </div>
                      </div>
                    )}
                    <div>
                      {searchHistory &&
                        results.length === 0 &&
                        !noResults &&
                        searchHistory
                          .sort((a, b) => {
                            return (
                              new Date(b.createdAt) - new Date(a.createdAt)
                            );
                          })
                          .map((user) => (
                            <div
                              className="recent-u-search-h-item hover1-m-p"
                              key={user._id}
                            >
                              <Link
                                className="recent-u-search-h-l"
                                to={`/profile/${user.user.username}`}
                                onClick={() =>
                                  addToSearchHistoryHandler(user.user._id)
                                }
                              >
                                <img src={user.user.picture} alt="" />
                                <div>
                                  <h5>
                                    {user.user.first_name} {user.user.last_name}{" "}
                                  </h5>
                                  <p className="text-muted">
                                    <small>@{user.user.username}</small>
                                  </p>
                                </div>
                              </Link>
                              <i
                                className="search-m-exit-i-s"
                                onClick={() => {
                                  handleRemove(user.user._id);
                                }}
                              >
                                <CloseIcon />
                              </i>
                            </div>
                          ))}
                    </div>
                    <div>
                      {results.length > 0 &&
                        results.map((user) => (
                          <div
                            className="u-search-r-h-item-c-s hover1-m-p"
                            key={user._id}
                          >
                            <Link
                              to={`/profile/${user.username}`}
                              className="u-search-r-h-item"
                              onClick={() =>
                                addToSearchHistoryHandler(user._id)
                              }
                            >
                              <img src={user.picture} alt="" />
                              <div>
                                <h5>
                                  {user.first_name} {user.last_name}
                                </h5>
                                <p className="text-muted">
                                  <small>@{user.username}</small>
                                </p>
                              </div>
                            </Link>
                          </div>
                        ))}
                      {noResults && searchTerm.trim() !== "" && (
                        <h4>No user found</h4>
                      )}
                    </div>
                    <div className="search-menu-l-s-s"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
