import "../../styles/style.css";
import "../../styles/general.css";
import "../../styles/left.css";
import "../../styles/mediaquery.css";
import "../../styles/navbar.css";
import "../../styles/right.css";
import "../../styles/theme.css";
import "../../styles/postcontainer.css";
import "./style.css";
import Header from "../../components/header";
import LeftContainer from "../../components/left/LeftContainer";
import RightContainer from "../../components/right/RightContainer";
import { useState, useEffect } from "react";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import { useDispatch, useSelector } from "react-redux";
import ActivateForm from "./ActivateForm";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import ActivateMiddleContainer from "../../components/middle/ActivateMiddleContainer";
import { Helmet } from "react-helmet";

export default function Activate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((user) => ({ ...user }));
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { token } = useParams();
  useEffect(() => {
    activateAccount();
  }, []);
  const activateAccount = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/activate`,
        { token },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess(data.message);
      Cookies.set("user", JSON.stringify({ ...user, verified: true }));
      dispatch({
        type: "VERIFY",
        payload: true,
      });
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      setError(error.response.data.message);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  };

  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, []);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  return (
    <div>
      <Helmet>
        <title>Namrolink - Activate</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan connections, Activate"
        />
      </Helmet>
      <style jsx>{`
        .customize-theme {
          display: grid;
        }
        /* =============================================
MEDIA QUERY FOR SMALL LAPTOPS AND BIG TABLETS
================================================ */

        @media screen and (max-width: 1200px) {
          .element_container {
            width: 96%;
          }

          main .element_container {
            grid-template-columns: 5rem auto 30vw;
            gap: 1rem;
          }

          .left {
            width: 5rem;
            z-index: 5;
          }

          main .element_container .left .profile {
            display: none;
          }

          .sidebar h3 {
            display: none;
          }

          .left .btn {
            display: none;
          }

          .customize-theme .card-container {
            width: 65%;
          }
        }

        /* =============================================
MEDIA QUERY FOR SMALL TABLETS AND MOBILE PHONES
================================================ */
        @media screen and (max-width: 992px) {
          nav .search-bar {
            display: none;
          }

          main .element_container {
            grid-template-columns: 0 auto 5rem;
            gap: 0;
          }

          main .element_container .left {
            grid-column: 3/4;
            position: fixed;
            bottom: 0;
            right: 0;
          }

          #explore-search.activeitem {
            display: grid;
          }

          /* ----------NOTIFICATION POPUP------------ */
          .left .notifications-popup {
            position: absolute;
            left: -21rem;
            width: 20rem;
          }

          .left .notifications-popup:before {
            position: absolute;
            top: 1.3rem;
            left: calc(20rem - 0.6rem);
            display: block;
          }

          main .element_container .middle {
            display: none;
          }

          main .element_container .middle.activeitem {
            display: block;
            grid-column: 1/3;
          }

          main .element_container .right {
            display: none;
          }

          main .element_container .right.activeitem {
            display: block;
            grid-column: 1/3;
          }

          .customize-theme .card-container {
            width: 80%;
          }
        }

        @media screen and (max-width: 580px) {
          main .element_container {
            display: block;
          }

          main .element_container .left {
            translate: 5rem 0;
            transition: translate 0.5s ease;
          }

          main .element_container .left.activeitem {
            translate: 0;
          }

          main .element_container .left .menu-toggle {
            display: flex;
          }

          .left .notifications-popup {
            left: -17rem;
            width: 16rem;
          }

          .left .notifications-popup:before {
            left: calc(16rem - 0.6rem);
          }

          .customize-theme .card-container {
            width: 90%;
            padding: 1.5rem;
          }

          .customize-theme .card-container .font-size {
            margin-top: 2rem;
          }

          .customize-theme .background .choose-bg {
            margin-top: 0.3rem;
          }

          .customize-theme .background .choose-bg > div {
            flex-direction: column;
          }

          .customize-theme .background .choose-bg > div > span {
            margin: 0;
            margin-bottom: 0.4rem;
          }
        }
      `}</style>
      <Header activeMenuItem={activeMenuItem} />
      {success && (
        <ActivateForm
          type="success"
          header="Account verification successful"
          text={success}
          loading={loading}
        />
      )}
      {error && (
        <ActivateForm
          type="error"
          header="Account verification failed"
          text={error}
          loading={loading}
        />
      )}

      <div className="main">
        <div className="element_container">
          <div className="left-container-section">
            <LeftContainer
              onMenuItemClick={handleMenuItemClick}
              onThemeClick={() => handleThemeVisibility(true)}
            />
          </div>
          <div className="middle-container-section">
            <ActivateMiddleContainer activeMenuItem={activeMenuItem} />
          </div>
          <div className="right-container-section">
            <RightContainer />
          </div>
        </div>
      </div>

      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
    </div>
  );
}
