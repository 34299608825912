import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./style.css";
import HomeIcon from "../../components/icons/HomeIcon";
import ProfileIcon from "../../components/icons/ProfileIcon";
import PlusIcon from "../../components/icons/PlusIcon";
import FriendsIcon from "../icons/FriendsIcon";
import FriendGroupIcon from "../icons/FriendGroupIcon";
import MessagesIcon from "../icons/MessagesIcon";
import MusicIcon from "../icons/MusicIcon";
import HelpIcon from "../icons/HelpIcon";
import DeveloperIcon from "../icons/DeveloperIcon";

export default function CircleMenu() {
  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const isActive = (path, exact = false) => {
    return exact
      ? location.pathname === path
      : location.pathname.startsWith(path);
  };

  return (
    <div>
      <div className="circle-user-menu-content">
        <ul className={`circle-menu ${menuActive ? "circle-menu-active" : ""}`}>
          <div
            className={`circle-menu-toggle ${
              menuActive ? "circle-menu-active" : ""
            }`}
            onClick={toggleMenu}
          >
            <PlusIcon />
          </div>
          <li
            style={{ "--circle-menu-i": 0 }}
            className={isActive("/", true) ? "circle-menu-active" : ""}
          >
            <Link to="/">
              <HomeIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 1 }}
            className={isActive("/profile") ? "circle-menu-active" : ""}
          >
            <Link to="/profile">
              <ProfileIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 2 }}
            className={isActive("/friends") ? "circle-menu-active" : ""}
          >
            <Link to="/friends">
              <FriendsIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 3 }}
            className={isActive("/discover") ? "circle-menu-active" : ""}
          >
            <Link to="/discover">
              <FriendGroupIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 4 }}
            className={isActive("/chat") ? "circle-menu-active" : ""}
          >
            <Link to="/chat">
              <MessagesIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 5 }}
            className={isActive("/music") ? "circle-menu-active" : ""}
          >
            <Link to="/music">
              <MusicIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 6 }}
            className={isActive("/help") ? "circle-menu-active" : ""}
          >
            <Link to="/help">
              <HelpIcon />
            </Link>
          </li>
          <li
            style={{ "--circle-menu-i": 7 }}
            className={isActive("/developer") ? "circle-menu-active" : ""}
          >
            <Link to="/developer">
              <DeveloperIcon />
            </Link>
          </li>

          <div className="circle-menu-indicator"></div>
        </ul>
      </div>
    </div>
  );
}
