import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import PreviousImageIcon from "../../icons/PreviousImageIcon";
import HelpIcon from "../../icons/HelpIcon";
import EcommerceIcon from "../../icons/EcommerceIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import ShieldCheckIcon from "../../icons/ShieldCheckIcon";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NavigationIcon from "../../icons/NavigationIcon";
import NavigationOffIcon from "../../icons/NavigationOffIcon";
import axios from "axios";
import { savePhoneNumber } from "../../../functions/user";

export default function SettingsPrivacy({ setVisible }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, darkTheme } = useSelector((state) => ({ ...state }));
  const [isHiddenSectionVisible, setIsHiddenSectionVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const setDarkTheme = (value) => {
    Cookies.set("darkTheme", value, { expires: 365 });
    dispatch({ type: value ? "DARK" : "LIGHT" });
  };

  useEffect(() => {
    // Fetch and display the user's saved phone number if it exists
    const fetchPhoneNumber = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getPhoneNumber`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data.phoneNumber) {
          setPhoneNumber(response.data.phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhoneNumber();
  }, [user.token]);

  const handleSavePhoneNumber = async () => {
    try {
      const response = await savePhoneNumber(phoneNumber, user.token);
      setMessage(
        response === "Phone number saved successfully" ? response : response
      );
    } catch (error) {
      setMessage("Failed to save phone number");
      console.error("Error saving phone number:", error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const toggleHiddenSection = () => {
    setIsHiddenSectionVisible(!isHiddenSectionVisible);
  };

  return (
    <div className="absolute_wrap">
      <div className="absolute_wrap_header">
        <div
          className="circle d-a-a-a-i-s hover1-m-p"
          onClick={() => {
            setVisible(0);
          }}
        >
          <i className="arrow-back-icon-u-m-s">
            <PreviousImageIcon />
          </i>
        </div>
        Settings
      </div>
      {!darkTheme ? (
        <div
          className="mmenu_item hover1-m-p cursor-pointer-element-style"
          onClick={() => setDarkTheme(true)}
        >
          <div className="small_circle">
            <i className="user-menu-icon-s">
              <NavigationIcon />
            </i>
          </div>
          <span>Activate navigator</span>
        </div>
      ) : (
        <div
          className="mmenu_item hover1-m-p cursor-pointer-element-style"
          onClick={() => setDarkTheme(false)}
        >
          <div className="small_circle">
            <i className="user-menu-icon-s">
              <NavigationOffIcon />
            </i>
          </div>
          <span>Deactivate navigator</span>
        </div>
      )}
      <div
        className="mmenu_item hover1-m-p cursor-pointer-element-style"
        onClick={() => {
          window.open("https://www.namronet.com/account", "_blank");
        }}
      >
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <EcommerceIcon />
          </i>
        </div>
        <span>Namronet account</span>
      </div>
      <div
        className="mmenu_item hover1-m-p cursor-pointer-element-style"
        onClick={toggleHiddenSection}
      >
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <PhoneIcon />
          </i>
        </div>
        <span>Phone number</span>
      </div>
      {isHiddenSectionVisible && (
        <div className="mmenu_item hover1-m-p cursor-pointer-element-style">
          <div className="phone-number-s-container-n">
            <div>Save your phone number to your account</div>
            <div className="phone-number-section-n-p">
              <input
                type="text"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <button onClick={handleSavePhoneNumber}>Save</button>
            </div>
            <div className="p-n-response-message-n-s">{message}</div>
          </div>
        </div>
      )}
      <div
        className="mmenu_item hover1-m-p cursor-pointer-element-style"
        onClick={() => navigate("/reset")}
      >
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <ShieldCheckIcon />
          </i>
        </div>
        <span>Reset password</span>
      </div>
      <div
        className="mmenu_item hover1-m-p cursor-pointer-element-style"
        onClick={() => navigate("/help")}
      >
        <div className="small_circle">
          <i className="user-menu-icon-s">
            <HelpIcon />
          </i>
        </div>
        <span>Help center</span>
      </div>
    </div>
  );
}
