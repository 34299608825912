import CreatePostContainer from "./CreatePostContainer";
import UserFeed from "./UserFeed";
import UserTiles from "./UserTiles";
import "../../styles/mediaquery.css";
import "../../styles/navbar.css";
import "../../styles/middle.css";
import { useEffect, useState } from "react";
import SendVerification from "../../components/home/sendVerification";
import { useSelector } from "react-redux";
import SearchIcon from "../icons/SearchIcon";
import ExploreSearchBar from "./ExploreSearchBar";

export default function MiddleContainer({
  activeMenuItem,
  loading,
  setVisible,
  posts,
}) {
  const { user } = useSelector((state) => ({ ...state }));

  return (
    <div className="middle">
      <div className="middle activeitem">
        <ExploreSearchBar activeMenuItem={activeMenuItem} />
      </div>
      <UserTiles />
      {user.verified === false && <SendVerification user={user} />}
      <CreatePostContainer setVisible={setVisible} />
      <UserFeed posts={posts} loading={loading} />
    </div>
  );
}
