import axios from "axios";
import { useSelector } from "react-redux";
import RefreshIcon from "../icons/RefreshIcon";
import { Link } from "react-router-dom";
import HomeIcon from "../icons/HomeIcon";
import EcommerceIcon from "../icons/EcommerceIcon";
import FriendsIcon from "../icons/FriendsIcon";
import ProfileIcon from "../icons/ProfileIcon";
import ShieldCheckIcon from "../icons/ShieldCheckIcon";
import DeveloperIcon from "../icons/DeveloperIcon";
import CodeBracketIcon from "../icons/CodeBracketIcon";
import PhoneIcon from "../icons/PhoneIcon";
import React, { useState, useEffect } from "react";
import { savePhoneNumber } from "../../functions/user";
import SavedPostsIcon from "../icons/SavedPostsIcon";

export default function NotificationsList() {
  const { user } = useSelector((user) => ({ ...user }));
  const [isHiddenSectionVisible, setIsHiddenSectionVisible] = useState(false);
  const [isSavedPostsVisible, setIsSavedPostsVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // Fetch and display the user's saved phone number if it exists
    const fetchPhoneNumber = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getPhoneNumber`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        if (response.data.phoneNumber) {
          setPhoneNumber(response.data.phoneNumber);
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
      }
    };

    fetchPhoneNumber();
  }, [user.token]);

  const handleSavePhoneNumber = async () => {
    try {
      const response = await savePhoneNumber(phoneNumber, user.token);
      setMessage(
        response === "Phone number saved successfully" ? response : response
      );
    } catch (error) {
      setMessage("Failed to save phone number");
      console.error("Error saving phone number:", error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const toggleHiddenSection = () => {
    setIsHiddenSectionVisible(!isHiddenSectionVisible);
  };

  const toggleSavedPostsSection = () => {
    setIsSavedPostsVisible(!isSavedPostsVisible);
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [user.token]);

  const isBirthday = () => {
    if (!userDetails) return false;

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1; // getMonth() returns 0-indexed month

    return (
      currentDay === userDetails.birthDay &&
      currentMonth === userDetails.birthMonth
    );
  };

  return (
    <div className="notifications">
      <div className="user-n-p-i-s">
        <div onClick={toggleSavedPostsSection}>
          <SavedPostsIcon />
        </div>
        <div onClick={toggleHiddenSection}>
          <PhoneIcon />
        </div>
        <div className="n-p-d-i-hidden">
          <Link to="/developer?sayiganormanhaq">
            <CodeBracketIcon />
          </Link>
        </div>
        <Link to="/reset">
          <ShieldCheckIcon />
        </Link>
        <a
          href="https://www.namronet.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EcommerceIcon />
        </a>
        <Link to="/developer" className="n-p-d-i-hidden">
          <DeveloperIcon />
        </Link>
        <div onClick={handleRefreshClick}>
          <RefreshIcon />
        </div>
      </div>
      {isHiddenSectionVisible && (
        <div className="phone-number-s-container-n">
          <div>Save your phone number to your account</div>
          <div className="phone-number-section-n-p">
            <input
              type="text"
              placeholder="Phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <button onClick={handleSavePhoneNumber}>Save</button>
          </div>
          <div className="p-n-response-message-n-s">{message}</div>
        </div>
      )}
      {isSavedPostsVisible && (
        <>
          {userDetails?.savedPosts.length > 0 ? (
            <div className="saved-posts-i-c-s">
              {userDetails?.savedPosts.length === 1 ? (
                <div className="n-saved-p-h-s">
                  {userDetails?.savedPosts.length} saved post
                </div>
              ) : (
                <div className="n-saved-p-h-s">
                  Number of saved posts: {userDetails?.savedPosts.length}
                </div>
              )}
              <div>
                {userDetails.savedPosts.map((post, index) => (
                  <div key={post.postId}>
                    <Link
                      to={`${process.env.REACT_APP_FRONTEND_URL}/discover/feed?postId=${post.postId}`}
                      className="u-n-saved-p-l-s"
                    >
                      #Namrolink saved post {index + 1}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>No saved posts yet</div>
          )}
        </>
      )}
      {isBirthday() && (
        <div>
          <div className="profile-photo">
            <img
              src="../../../images/defaultUserImages/user-img-2.jpg"
              alt=""
            />
          </div>
          <div className="notification-body">
            <b>Namrolink</b> wishes you a happy birthday
            <small className="text-muted">12:00 PM, today</small>
          </div>
        </div>
      )}
      <div>
        <div className="profile-photo">
          <img src="../../../images/defaultUserImages/user-img-4.jpg" alt="" />
        </div>
        <div className="notification-body">
          <b>Namronet trade</b>, buy more, spend less
          <small className="text-muted">{`${new Date().getDate()} ${new Date().toLocaleString(
            "default",
            { month: "long" }
          )} ${new Date().getFullYear()}`}</small>
        </div>
      </div>
    </div>
  );
}
