import CloseIcon from "../icons/CloseIcon";
import PhotoIcon from "../icons/PhotoIcon";
import "./style.css";
export default function ImagesPreviewPopup({
  onClose,
  images,
  setImages,
  imageInputRef,
}) {
  return (
    <div className="images-preview-popup">
      <div className="postBox">
        <div className="images-p-p-closer" onClick={onClose}>
          &larr; Back to post window
        </div>
        <div className="overflow_a scrollbar">
          <div className="add_pics_wrap">
            <div className="add_pics_inside1 p0">
              <div className="preview_actions">
                <button
                  className="hover1-m-p"
                  onClick={() => {
                    imageInputRef.current.click();
                  }}
                >
                  <i className="close-i-p-s">
                    <PhotoIcon />
                  </i>
                  Add Photos
                </button>
              </div>
              <div
                className="small_white_circle"
                onClick={() => {
                  setImages([]);
                }}
              >
                <i className="close-i-p-s">
                  <CloseIcon />
                </i>
              </div>
              <div
                className={
                  images.length === 1
                    ? "preview1"
                    : images.length === 2
                    ? "preview2"
                    : images.length === 3
                    ? "preview3"
                    : images.length === 4
                    ? "preview4 "
                    : images.length === 5
                    ? "preview5"
                    : images.length % 2 === 0
                    ? "preview6"
                    : "preview6 singular_grid"
                }
              >
                {images.map((img, i) => (
                  <img src={img} key={i} alt="" />
                ))}
              </div>
            </div>
          </div>
        </div>
        {images && images.length ? (
          <div className="i-p-p-clear-images">
            <div
              className="i-p-p-clear-images"
              onClick={() => {
                setImages([]);
              }}
            >
              Would you like to clear all the selected images?
            </div>
          </div>
        ) : (
          <div className="i-p-p-prompt">
            <div
              className="i-p-p-prompt"
              onClick={() => {
                imageInputRef.current.click();
              }}
            >
              Would you like to add an image?
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
