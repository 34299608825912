import { ErrorMessage, useField } from "formik";
export default function RegisterFormInput({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div>
      <input
        className={meta.touched && meta.error ? "input_error_border" : ""}
        type={field.type}
        name={field.name}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <div className="input_error_message">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
    </div>
  );
}
