import CloseIcon from "../icons/CloseIcon";
import "./style.css";
export default function VideoPreviewPopup({
  onVideoPreviewClose,
  handleClearUploadVideo,
  videoUrl,
}) {
  return (
    <div className="images-preview-popup">
      <div className="postBox">
        <div className="images-p-p-closer" onClick={onVideoPreviewClose}>
          &larr; Back to post window
        </div>
        <div className="overflow_a scrollbar">
          <div className="add_pics_wrap">
            <div className="add_pics_inside1 p0">
              <div className="preview_actions"></div>
              <div
                className="small_white_circle"
                onClick={handleClearUploadVideo}
              >
                <i className="close-i-p-s">
                  <CloseIcon />
                </i>
              </div>
              <div className="preview1">
                {videoUrl && (
                  <div>
                    <video src={videoUrl} controls muted autoPlay />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="i-p-p-clear-images">
          <div className="i-p-p-clear-images" onClick={handleClearUploadVideo}>
            Would you like to clear the selected video?
          </div>
        </div>
      </div>
    </div>
  );
}
