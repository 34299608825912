import "../../styles/style.css";
import "../../styles/general.css";
import "../../styles/left.css";
import "../../styles/mediaquery.css";
import "../../styles/navbar.css";
import "../../styles/right.css";
import "../../styles/theme.css";
import "../../styles/postcontainer.css";
import Header from "../../components/header";
import LeftContainer from "../../components/left/LeftContainer";
import MiddleContainer from "../../components/middle/MiddleContainer";
import RightContainer from "../../components/right/RightContainer";
import { useState, useEffect } from "react";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import { useSelector } from "react-redux";
import BottomMenu from "../../components/navigation/BottomMenu";
import CircleMenu from "../../components/navigation/CircleMenu";
import { Helmet } from "react-helmet";

export default function Home({ setVisible, posts, loading, getAllPosts }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }

    if (user) {
      getAllPosts();
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  return (
    <div>
      <Helmet>
        <title>Namrolink</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan connections, Home"
        />
      </Helmet>
      <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      <div className="main">
        <div className="element_container">
          <div className="left-container-section">
            <LeftContainer
              onMenuItemClick={handleMenuItemClick}
              onThemeClick={() => handleThemeVisibility(true)}
              setVisible={setVisible}
            />
          </div>
          <div className="middle-container-section">
            <MiddleContainer
              activeMenuItem={activeMenuItem}
              setVisible={setVisible}
              posts={posts}
              loading={loading}
            />
          </div>
          <div className="right-container-section">
            <RightContainer />
          </div>
        </div>
      </div>

      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
      <div className="extras-options-home-page">
        <BottomMenu />
      </div>
      <div className="extras-options-circle-home-page">
        <CircleMenu />
      </div>
    </div>
  );
}
