import { useEffect, useRef, useState } from "react";
import SearchIcon from "../../components/icons/SearchIcon";
import { HashLoader } from "react-spinners";
import useClickOutside from "../../helpers/clickOutside";

export default function NotLoggedInExploreSearch() {
  const [showExploreSearchMenu, setShowExploreSearchMenu] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const esmenu = useRef(null);

  const exploreTextRef = useRef(null);
  useClickOutside(esmenu, () => {
    setShowExploreSearchMenu(false);
  });

  useEffect(() => {
    if (showExploreSearchMenu) {
      setShowLoader(true);
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showExploreSearchMenu]);

  useEffect(() => {
    if (showExploreSearchMenu && exploreTextRef.current) {
      exploreTextRef.current.focus();
    }
  }, [showExploreSearchMenu]);

  return (
    <div>
      <div>
        <div className="help-explore-search">
          <i className="uil uil-search user_search_icon">
            <SearchIcon />
          </i>
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Search Namrolink"
            onFocus={() => {
              setShowExploreSearchMenu(true);
              if (exploreTextRef.current) {
                exploreTextRef.current.focus();
              }
            }}
            onClick={() => {
              setShowExploreSearchMenu(true);
              if (exploreTextRef.current) {
                exploreTextRef.current.focus();
              }
            }}
          />
          <select id="filter">
            <option value="creator">Users</option>
          </select>
          {showExploreSearchMenu && (
            <div className="blur">
              <div className="u-explore-search-menu-container" ref={esmenu}>
                <div className="u-explore-search-bar-s-container">
                  <i className="explore-s-search-icon-s cursor-pointer-element-style">
                    <SearchIcon />
                  </i>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search Namrolink"
                    ref={exploreTextRef}
                  />
                  <select>
                    <option value="creator">Users</option>
                  </select>
                </div>
                <div className="u-explore-search-users-list-container">
                  <div className="u-eplore-s-s-content-c">
                    <div className="u-explore-search-menu-c-c">
                      <div className="u-search-menu-heading">
                        <div className="u-search-menu-title">
                          Recent searches
                        </div>
                        <div className="u-search-menu-close">Close</div>
                      </div>
                      {showLoader ? (
                        <div className="seach-menu-loader-container">
                          <HashLoader color="#1876f2" size={50} />
                        </div>
                      ) : (
                        <div className="not-logged-in-header-search-menu-prompt hover1-m-p">
                          Please log in to your Namrolink account to view your
                          search history.
                        </div>
                      )}
                      <div className="search-menu-l-s-s"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
