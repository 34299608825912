import "./style.css";
import LoginForm from "../../components/login/LoginForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export default function Login() {
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const [visible, setVisible] = useState(false);
  return (
    <div>
      <Helmet>
        <title>Namrolink - Login</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan connections, Login"
        />
      </Helmet>
      <div className="user_login_page">
        <style jsx>{`
          :root {
            --first-color: hsl(244, 75%, 57%);
            --second-color: hsl(249, 64%, 47%);
            --title-color: hsl(244, 12%, 12%);
            --text-color: hsl(244, 4%, 36%);
            --body-color: hsl(208, 97%, 85%);
            --body-font: "Poppins", sans-serif;
            --h2-font-size: 1.25rem;
            --small-font-size: 0.813rem;
            --smaller-font-size: 0.75rem;
            --font-medium: 500;
            --font-semi-bold: 600;
          }
          body {
            background-color: hsl(252, 30%, 95%);
            font-family: var(--body-font);
            color: var(--text-color);
          }
          .login__title span {
            color: var(--color-primary);
          }
          .login__input {
            border: 2px solid var(--color-primary) !important;
          }
          .login__button {
            background: var(--color-primary);
          }
          .login__button-ghost {
            background: hsla(244, 16%, 92%, 0.6);
            border: 2px solid var(--color-primary);
            color: var(--color-primary);
          }
          .login__forgot {
            color: var(--color-primary);
          }
        `}</style>
        <LoginForm setVisible={setVisible} />
      </div>
    </div>
  );
}
