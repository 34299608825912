import "./style.css";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/header";
import { followstatus } from "../../functions/reducers";
import { getFollowStatusInfos, getAllUsers } from "../../functions/user";
import UserCard from "./UserCard";
import LeftContainer from "../../components/left/LeftContainer";
import RightContainer from "../../components/right/RightContainer";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import FriendsIcon from "../../components/icons/FriendsIcon";
import FriendGroupIcon from "../../components/icons/FriendGroupIcon";
import ExploreSearchBar from "../../components/middle/ExploreSearchBar";
import FriendGroupSolidIcon from "../../components/icons/FriendGroupSolidIcon";
import FollowersIcon from "../../components/icons/FollowersIcon";
import FollowersSolidIcon from "../../components/icons/FollowersSolidIcon";
import FollowingIcon from "../../components/icons/FollowingIcon";
import FollowingSolidIcon from "../../components/icons/FollowingSolidIcon";
import NewspaperIcon from "../../components/icons/NewspaperIcon";
import NewspaperSolidIcon from "../../components/icons/NewspaperSolidIcon";
import AllUserCard from "./AllUserCard";
import DiscoverFeed from "./DiscoverFeed";
import BottomMenu from "../../components/navigation/BottomMenu";
import CircleMenu from "../../components/navigation/CircleMenu";
import { Helmet } from "react-helmet";
import { ClipLoader } from "react-spinners";

export default function Discover({ setVisible }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);
  const { category } = useParams();

  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  const [{ loading, error, data }, dispatch] = useReducer(followstatus, {
    loading: false,
    data: {},
    error: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch({ type: "FOLLOWSTATUS_REQUEST" });
    const followData = await getFollowStatusInfos(user.token);
    const allUsersData = await getAllUsers(user.token);
    if (followData.status === "ok" && allUsersData.status === "ok") {
      dispatch({
        type: "FOLLOWSTATUS_SUCCESS",
        payload: {
          followers: followData.data.followers,
          following: followData.data.following,
          allUsers: allUsersData.data,
        },
      });
    } else {
      dispatch({
        type: "FOLLOWSTATUS_ERROR",
        payload: followData.data || allUsersData.data,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Namrolink - Discover</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Discover"
        />
      </Helmet>
      <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      <div className="main">
        <div className="element_container">
          <div className="left-container-section">
            <LeftContainer
              onMenuItemClick={handleMenuItemClick}
              onThemeClick={() => handleThemeVisibility(true)}
              setVisible={setVisible}
            />
          </div>
          <div className="middle-container-section">
            <div className="middle activeitem">
              <ExploreSearchBar activeMenuItem={activeMenuItem} />
              <div>
                <div className="feeds">
                  <div className="feed zero-margin-top-i-s">
                    <div className="friends-links-wrapper">
                      <div className="friends-links-container">
                        <Link to="/discover" className="friends-link-s">
                          <div className="friends-link-text">
                            {category === undefined ? (
                              <FriendGroupSolidIcon />
                            ) : (
                              <FriendGroupIcon />
                            )}
                            Home
                          </div>
                        </Link>
                      </div>
                      <Link
                        to="/discover/followers"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {category === "followers" ? (
                              <FollowersSolidIcon />
                            ) : (
                              <FollowersIcon />
                            )}
                            Followers
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/discover/following"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {category === "following" ? (
                              <FollowingSolidIcon />
                            ) : (
                              <FollowingIcon />
                            )}
                            Following
                          </div>
                        </div>
                      </Link>
                      <Link to="/friends" className="friends-links-container">
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            <FriendsIcon />
                            Friends
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/discover/feed"
                        className="friends-links-container"
                      >
                        <div className="friends-link-s">
                          <div className="friends-link-text">
                            {category === "feed" ? (
                              <NewspaperSolidIcon />
                            ) : (
                              <NewspaperIcon />
                            )}
                            Feed
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {(category === undefined || category === "all") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>Find friends</h4>
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.allUsers && data.allUsers.length === 0 && (
                          <div className="n-friends-a-a-p-s">
                            You are the only user so far
                          </div>
                        )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.allUsers &&
                          data.allUsers.map((user) => (
                            <AllUserCard userr={user} key={user._id} />
                          ))}
                      </div>
                    </div>
                  )}
                  {(category === undefined || category === "followers") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>Your followers</h4>
                        {category === undefined && (
                          <Link to="/discover/followers">See all</Link>
                        )}
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.followers && data.followers.length === 0 && (
                          <div className="n-friends-a-a-p-s">
                            No followers yet
                          </div>
                        )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.followers &&
                          data.followers.map((user) => (
                            <UserCard
                              userr={user}
                              key={user._id}
                              category="followers"
                            />
                          ))}
                      </div>
                    </div>
                  )}
                  {(category === undefined || category === "following") && (
                    <div>
                      <div className="friends-t-h-s">
                        <h4>People you follow</h4>
                        {category === undefined && (
                          <Link to="/discover/following">See all</Link>
                        )}
                        {loading && (
                          <div className="n-friends-a-a-p-s">
                            <ClipLoader
                              size={18}
                              color="var(--color-primary)"
                            />
                          </div>
                        )}
                        {data.following && data.following.length === 0 && (
                          <div className="n-friends-a-a-p-s">
                            You are not following anyone
                          </div>
                        )}
                      </div>
                      <div className="friends-card-wrapper-s">
                        {data.following &&
                          data.following.map((user) => (
                            <UserCard
                              userr={user}
                              key={user._id}
                              category="following"
                            />
                          ))}
                      </div>
                    </div>
                  )}
                  {category === "feed" && <DiscoverFeed />}
                </div>
              </div>
            </div>
          </div>
          <div className="right-container-section">
            <RightContainer />
          </div>
        </div>
      </div>
      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
      <div className="extras-options-home-page">
        <BottomMenu />
      </div>
      <div className="extras-options-circle-home-page">
        <CircleMenu />
      </div>
    </>
  );
}
