import { useSelector } from "react-redux";
import "../../styles/postcontainer.css";
import PhotoIcon from "../icons/PhotoIcon";
import "../../styles/stories.css";
import AttachmentIcon from "../icons/AttachmentIcon";
export default function CreatePostContainer({ setVisible }) {
  const { user } = useSelector((user) => ({ ...user }));
  return (
    <div>
      <div className="create-post" id="submit-post">
        <div className="input-container">
          <div className="profile-photo create-p-c-p-s">
            <img src={user?.picture} alt="" />
          </div>
          <input
            type="text"
            id="create-post"
            placeholder={`What is on your mind, ${user?.first_name}`}
            onClick={() => {
              setVisible(true);
            }}
          />
          <input type="file" id="add-image" />
          <label className="add-image">
            <i
              className="uil uil-image-plus imported_icon"
              onClick={() => {
                setVisible(true);
              }}
            >
              <AttachmentIcon />
            </i>
          </label>
          <input
            className="btn btn-primary"
            type="submit"
            value="Post"
            onClick={() => {
              setVisible(true);
            }}
          />
        </div>
        <div className="img-thumbnail"></div>
      </div>
    </div>
  );
}
