import React from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import useOnCLickOutside from "../../helpers/clickOutside";
import { useRef } from "react";

export default function TileCard({
  tileImgCover,
  tileFirstName,
  tileLastName,
  tileFriendsCount,
  tileFollowersCount,
  tileImgPic,
  tileFollowingCount,
  tileJob,
  tileUsername,
  setTileVisible,
}) {
  const navigate = useNavigate();
  const goToProfile = () => {
    navigate(`/profile/${tileUsername}`);
  };
  const tilemodal = useRef(null);
  useOnCLickOutside(tilemodal, () => setTileVisible(false));
  return (
    <div className="blur">
      <style jsx>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: "Poppins", sans-serif;
        }

        .user-tile-c-body {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
        }
      `}</style>
      <div className="user-tile-c-body">
        <div className="u-card-t" ref={tilemodal}>
          <div className="u-t-imgBx">
            <img src={tileImgPic} alt="" />
          </div>
          <div className="u-t-content">
            <div className="u-t-details">
              <h2>
                {tileFirstName} {tileLastName}
                <br />
                {tileJob ? (
                  <span className="job-p-u-t-d">{tileJob}</span>
                ) : (
                  <span className="job-p-u-t-d">No info yet</span>
                )}
              </h2>
              <div className="u-t-data">
                <h3>
                  {tileFriendsCount}
                  <br />
                  <span>Friends</span>
                </h3>
                <h3>
                  {tileFollowersCount}
                  <br />
                  <span>Followers</span>
                </h3>
                <h3>
                  {tileFollowingCount}
                  <br />
                  <span>Following</span>
                </h3>
              </div>
              <div className="u-t-actionBtn">
                <Link to={`/profile/${tileUsername}`} className="u-t-c-f-a-b-s">
                  View
                </Link>
                <div
                  className="u-t-c-f-a-b-s"
                  onClick={() => {
                    setTileVisible(false);
                  }}
                >
                  Close
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
