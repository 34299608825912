import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { postsReducer } from "../../functions/reducers";
import UserFeedPost from "../post/UserFeedPost";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AdvertisementDefault from "./AdvertisementDefault";

export default function UserFeedDefault() {
  const { user } = useSelector((user) => ({ ...user }));
  const skeletonItems = Array(3).fill(null);
  const [{ loading, error, posts }, dispatch] = useReducer(postsReducer, {
    loading: false,
    posts: [],
    error: "",
  });

  const getEveryPost = async () => {
    try {
      dispatch({
        type: "POSTS_REQUEST",
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getEveryPost`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch({
        type: "POSTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({
        type: "POSTS_ERROR",
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getEveryPost();
    }
  }, [user]);
  return (
    <>
      {loading ? (
        <>
          {skeletonItems.map((_, index) => (
            <div className="feed">
              <div className="head">
                <div className="user">
                  <div className="profile-photo">
                    <div className="skeleton-middle-u-f-d-p-p-s">
                      <Skeleton
                        height="100%"
                        width="100%"
                        containerClassName="avatar-skeleton"
                        style={{
                          backgroundSize: "cover",
                          borderRadius: "70%",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ingo">
                    <h3 className="c-p-user-feed-post">
                      <Skeleton
                        height="26px"
                        width="140px"
                        containerClassName="avatar-skeleton"
                      />
                    </h3>
                    <small>
                      <Skeleton
                        height="20px"
                        width="170px"
                        containerClassName="avatar-skeleton"
                        style={{ marginTop: "20px" }}
                      />
                    </small>
                  </div>
                </div>
                <span className="more">
                  <i className="uil uil-ellipsis-h user-feed-icons">
                    <Skeleton
                      height="20px"
                      width="22px"
                      containerClassName="avatar-skeleton"
                    />
                  </i>
                </span>
              </div>
              <div className="caption">
                <p className="caption-p-t-l-t-c">
                  <Skeleton
                    height="20px"
                    width="290px"
                    containerClassName="avatar-skeleton"
                  />
                </p>
              </div>

              <div className="photo">
                <div className="photo">
                  <div className="r-s-l-p-p-s">
                    <Skeleton
                      height="260px"
                      width="100%"
                      containerClassName="avatar-skeleton"
                      style={{ maxWidth: "100%", overflow: "hidden" }}
                    />
                  </div>
                </div>
              </div>

              <div className="action-buttons s-l-m-action-buttons">
                <div className="interaction-buttons">
                  <span className="like">
                    <i className="uil uil-heart user-feed-icons">
                      <Skeleton
                        height="24px"
                        width="24px"
                        containerClassName="avatar-skeleton"
                      />
                    </i>
                  </span>
                  <span>
                    <i className="uil uil-comment-dots user-feed-icons">
                      <Skeleton
                        height="24px"
                        width="24px"
                        containerClassName="avatar-skeleton"
                      />
                    </i>
                  </span>
                  <span>
                    <i className="uil uil-share-alt user-feed-icons">
                      <Skeleton
                        height="24px"
                        width="24px"
                        containerClassName="avatar-skeleton"
                      />
                    </i>
                  </span>
                </div>
                <div className="bookmark">
                  <span>
                    <i className="uil uil-bookmark user-feed-icons">
                      <Skeleton
                        height="24px"
                        width="24px"
                        containerClassName="avatar-skeleton"
                      />
                    </i>
                  </span>
                </div>
              </div>

              <div className="r-l-l-b-c-s">
                <div className="react-s-l-liked-by-s">
                  <div className="liked-by">
                    <span>
                      <Skeleton
                        height="100%"
                        width="100%"
                        containerClassName="avatar-skeleton"
                      />
                    </span>
                    <span>
                      <Skeleton
                        height="100%"
                        width="100%"
                        containerClassName="avatar-skeleton"
                      />
                    </span>
                    <span>
                      <Skeleton
                        height="100%"
                        width="100%"
                        containerClassName="avatar-skeleton"
                      />
                    </span>
                  </div>
                </div>
                <div>
                  <Skeleton
                    height="18px"
                    width="180px"
                    containerClassName="avatar-skeleton"
                  />
                </div>
              </div>
              <div>
                <Skeleton
                  height="16px"
                  width="190px"
                  containerClassName="avatar-skeleton"
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {posts.slice(0, 40).map((post, i) => (
            <UserFeedPost key={i} post={post} />
          ))}
        </>
      )}
      {posts.length === 0 && (
        <div>
          <AdvertisementDefault />
          <div>
            <h4 className="no-posts-y-u-f">No posts yet</h4>
          </div>
        </div>
      )}
    </>
  );
}
