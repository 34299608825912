import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "../../components/icons/HomeIcon";
import InfoIcon from "../../components/icons/InfoIcon";
import JobOutlineIcon from "../../components/icons/JobOutlineIcon";
import SkillsIcon from "../../components/icons/SkillsIcon";
import PortfolioIcon from "../../components/icons/PortfolioIcon";
import SendOutlineIcon from "../../components/icons/SendOutlineIcon";
import WebsiteIcon from "../../components/icons/WebsiteIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import ThemeIcon from "../../components/icons/ThemeIcon";
import AllAppsIcon from "../../components/icons/AllAppsIcon";
import LinkedInIcon from "../../components/icons/LinkedInIcon";
import GitHubIcon from "../../components/icons/GitHubIcon";
import FacebookIcon from "../../components/icons/FacebookIcon";
import PhoneIcon from "../../components/icons/PhoneIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import DownloadIcon from "../../components/icons/DownloadIcon";
import VSCodeIcon from "../../components/icons/VSCodeIcon";
import ChartBarIcon from "../../components/icons/ChartBarIcon";
import RepairToolIcon from "../../components/icons/RepairToolIcon";
import CodeBracketIcon from "../../components/icons/CodeBracketIcon";
import StudiedIcon from "../../components/icons/StudiedIcon";
import ReactIcon from "../../components/icons/ReactIcon";
import PCSetIcon from "../../components/icons/PCSetIcon";
import PhotoIcon from "../../components/icons/PhotoIcon";
import AndroidIcon from "../../components/icons/AndroidIcon";
import WhatsAppIcon from "../../components/icons/WhatsAppIcon";
import EmailIcon from "../../components/icons/EmailIcon";
import LocationIcon from "../../components/icons/LocationIcon";
import InstagramIcon from "../../components/icons/InstagramIcon";
import UserCircleIcon from "../../components/icons/UserCircleIcon";
import UserIcon from "../../components/icons/UserIcon";
import CalenderIcon from "../../components/icons/CalendarIcon";
import MouseOutlineIcon from "../../components/icons/MouseOutlineIcon";
import ScrollDownArrow from "../../components/icons/ScrollDownArrow";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoggedInUsersDeveloperForm from "./LoggedInUsersDeveloperForm";
import ArrowBadgeDown from "../../components/icons/ArrowBadgeDown";

export default function DeveloperContent({ onThemeClick }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(null);
  const modalRef = useRef(null);

  const openModal = (index) => {
    setActiveModal(index);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (activeModal !== null) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [activeModal]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("education");
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const skillsRef = useRef([]);

  useEffect(() => {
    const toggleSkills = (e) => {
      const itemClass = e.currentTarget.parentNode.className;

      skillsRef.current.forEach((content) => {
        content.className = "skills__content skills__close";
      });

      if (itemClass === "skills__content skills__close") {
        e.currentTarget.parentNode.className = "skills__content skills__open";
      }
    };

    skillsRef.current.forEach((header) => {
      if (header) {
        header
          .querySelector(".skills__header")
          .addEventListener("click", toggleSkills);
      }
    });

    return () => {
      skillsRef.current.forEach((header) => {
        if (header) {
          header
            .querySelector(".skills__header")
            .removeEventListener("click", toggleSkills);
        }
      });
    };
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="portfolio-body">
      <header className="header-p" id="header">
        <div className="nav-p container-p">
          <Link to="#" className="nav__logo">
            Sayiga Norman Haq
          </Link>
          <div
            className={`nav__menu ${menuOpen ? "show-menu" : ""}`}
            id="nav-menu"
          >
            <ul className="nav__list grid">
              <li className="nav__item">
                <a href="#home" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-estate nav__icon">
                    <HomeIcon />
                  </i>
                  Home
                </a>
              </li>
              <li className="nav__item">
                <a href="#about" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-user nav__icon">
                    <InfoIcon />
                  </i>
                  About
                </a>
              </li>
              <li className="nav__item">
                <a href="#skills" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-file-alt nav__icon">
                    <SkillsIcon />
                  </i>
                  Skills
                </a>
              </li>
              <li className="nav__item">
                <a href="#services" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-briefcase-alt nav__icon">
                    <JobOutlineIcon />
                  </i>
                  Services
                </a>
              </li>
              <li className="nav__item">
                <a href="#portfolio" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-scenery nav__icon">
                    <PortfolioIcon />
                  </i>
                  Portfolio
                </a>
              </li>
              <li className="nav__item">
                <a href="#contact" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-message nav__icon">
                    <SendOutlineIcon />
                  </i>
                  Contact
                </a>
              </li>
              <li className="nav__item">
                <Link to="/" className="nav__link" onClick={closeMenu}>
                  <i className="uil uil-brackets-curly nav__icon">
                    <WebsiteIcon />
                  </i>
                  Namrolink
                </Link>
              </li>
            </ul>
            <i
              className="uil uil-times nav__close"
              id="nav-close"
              onClick={closeMenu}
            >
              <CloseIcon />
            </i>
          </div>
          <div className="nav__btns">
            <i
              className="uil uil-moon change-theme"
              id="theme-button"
              onClick={onThemeClick}
            >
              <ThemeIcon />
            </i>

            <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
              <i className="uil uil-apps">
                <AllAppsIcon />
              </i>
            </div>
          </div>
        </div>
      </header>

      <div className="main-p">
        <section className="home-p section" id="home">
          <div className="home__container container-p grid">
            <div className="home__content grid">
              <div className="home__social">
                <a
                  href="https://www.linkedin.com/in/sayiga-norman-haq-4a47432a9/"
                  target="_blank"
                  className="home__social-icon"
                >
                  <i className="uil uil-linkedin-alt">
                    <LinkedInIcon />
                  </i>
                </a>
                <a
                  href="https://github.com/SayigaNormanHaq"
                  target="_blank"
                  className="home__social-icon"
                >
                  <i className="uil uil-github-alt">
                    <GitHubIcon />
                  </i>
                </a>
                <a
                  href="https://www.facebook.com/Sayiga.Norman"
                  target="_blank"
                  className="home__social-icon"
                >
                  <i className="uil uil-facebook">
                    <FacebookIcon />
                  </i>
                </a>
                <a
                  href="tel:+256789980531"
                  target="_blank"
                  className="home__social-icon"
                >
                  <i className="uil uil-phone-alt">
                    <PhoneIcon />
                  </i>
                </a>
              </div>

              <div className="home__img">
                <img
                  src="../../../developer/images/pras-1.jpg"
                  class="home__blob-img"
                  alt=""
                />
              </div>

              <div className="home__data">
                <h2>Hello, I am</h2>
                <h1 className="home__title">Sayiga Norman Haq</h1>
                <h3 className="home__subtitle">Software Developer</h3>
                <p className="home__description">
                  Extensive experience in software development and a proven
                  track record of effective teamwork, I bring a high level of
                  expertise and collaboration to every project.
                </p>
                <a href="#contact" className="button button--flex home__button">
                  Contact Me
                  <i className="uil uil-message button__icon developer-u-p-i-s">
                    <SendOutlineIcon />
                  </i>
                </a>
              </div>
            </div>

            <div className="home__scroll">
              <a href="#about" className="home__scroll-button button--flex">
                <i className="uil uil-mouse-alt home__scroll-mouse">
                  <MouseOutlineIcon />
                </i>
                <span className="home__scroll-name">Scroll Down</span>
                <i className="uil uil-arrow-down home__scroll-arrow">
                  <ScrollDownArrow />
                </i>
              </a>
            </div>
          </div>
        </section>

        <section className="about section" id="about">
          <h2 className="section__title">About Me</h2>
          <span className="section__subtitle">My Introduction</span>

          <div className="about__container container-p grid">
            <img
              src="../../../developer/images/about-pic.jpg"
              alt=""
              className="about__img"
            />
            <div className="about__data">
              <p className="important-about-part">Namrolink developer</p>
              <p className="about__description">
                I offer comprehensive technical skills and diverse IT solutions
                to guarantee smooth operations. My approachable and friendly
                nature helps build strong client relationships and effective
                team collaboration. Browse my portfolio to discover how my
                commitment and expertise can support your requirements.
              </p>
              <div className="about__info">
                <div>
                  <span className="about__info-title">03+</span>
                  <span className="about__info-name">
                    Years <br /> experience
                  </span>
                </div>
                <div>
                  <span className="about__info-title">4+</span>
                  <span className="about__info-name">
                    Completed <br /> projects
                  </span>
                </div>
                <div>
                  <span className="about__info-title">10+</span>
                  <span className="about__info-name">
                    Happy
                    <br />
                    clients
                  </span>
                </div>
              </div>

              <div className="about__buttons">
                <div
                  onClick={() =>
                    window.open(
                      "../../../developer/documents/resume-example.pdf"
                    )
                  }
                  className="button button--flex"
                >
                  Download CV
                  <i className="uil uil-download-alt button__icon developer-u-p-i-s">
                    <DownloadIcon />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="skills section" id="skills">
          <h2 className="section__title">Skills</h2>
          <span className="section__subtitle">My technical level</span>

          <div className="skills__container container-p grid">
            <div>
              <div
                ref={(el) => (skillsRef.current[0] = el)}
                className="skills__content skills__open"
              >
                <div className="skills__header">
                  <i className="uil uil-apps skills__icon">
                    <VSCodeIcon />
                  </i>
                  <div>
                    <h1 className="skills__title">Software development</h1>
                    <span className="skills__subtitle">More than 3 years</span>
                  </div>

                  <i className="uil uil-angle-down skills__arrow">
                    <ArrowBadgeDown />
                  </i>
                </div>
                <div className="skills__list grid">
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">React</h3>
                      <span className="skills__number">80%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__bank"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Python</h3>
                      <span className="skills__number">70%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__tab"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">C++</h3>
                      <span className="skills__number">60%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__python"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">C</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={(el) => (skillsRef.current[1] = el)}
                className="skills__content skills__close"
              >
                <div className="skills__header">
                  <i className="uil uil-analytics skills__icon">
                    <ChartBarIcon />
                  </i>
                  <div>
                    <h1 className="skills__title">Data analysis</h1>
                    <span className="skills__subtitle">More than 2 years</span>
                  </div>

                  <i className="uil uil-angle-down skills__arrow">
                    <ArrowBadgeDown />
                  </i>
                </div>
                <div className="skills__list grid">
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Database management</h3>
                      <span className="skills__number">80%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__bank"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">
                        Data preparation and cleaning
                      </h3>
                      <span className="skills__number">80%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__counter"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Statistical analysis</h3>
                      <span className="skills__number">70%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__google"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Data Ethics and Privacy</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__office"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                ref={(el) => (skillsRef.current[2] = el)}
                className="skills__content skills__open"
              >
                <div className="skills__header">
                  <i className="uil uil-wrench skills__icon">
                    <RepairToolIcon />
                  </i>
                  <div>
                    <h1 className="skills__title">Repair and maintenance</h1>
                    <span className="skills__subtitle">More than 4 years</span>
                  </div>

                  <i className="uil uil-angle-down skills__arrow">
                    <ArrowBadgeDown />
                  </i>
                </div>
                <div className="skills__list grid">
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">
                        Hardware repair and maintenance
                      </h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Software troubleshooting</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Network troubleshooting</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">
                        Installations and upgrades
                      </h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={(el) => (skillsRef.current[3] = el)}
                className="skills__content skills__close"
              >
                <div className="skills__header">
                  <i className="uil uil-brackets-curly skills__icon">
                    <CodeBracketIcon />
                  </i>
                  <div>
                    <h1 className="skills__title">Website Development</h1>
                    <span className="skills__subtitle">More than 3 years</span>
                  </div>

                  <i className="uil uil-angle-down skills__arrow">
                    <ArrowBadgeDown />
                  </i>
                </div>
                <div className="skills__list grid">
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">HTML</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">CSS</h3>
                      <span className="skills__number">90%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__html"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">JavaScript</h3>
                      <span className="skills__number">80%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__bank"></span>
                    </div>
                  </div>
                  <div className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">Next.js</h3>
                      <span className="skills__number">70%</span>
                    </div>
                    <div className="skills__bar">
                      <span className="skills__percentage skills__wordpress"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="qualification__section">
          <h2 className="section__title">Qualification</h2>
          <span className="section__subtitle">My personal journey</span>

          <div className="qualification__container container-p">
            <div className="qualification__tabs">
              <div
                className={`qualification__button button--flex ${
                  activeTab === "education" ? "qualification__active" : ""
                }`}
                onClick={() => handleTabClick("education")}
              >
                <i className="uil uil-graduation-cap qualification__icon">
                  <StudiedIcon />
                </i>
                Knowledge
              </div>
            </div>

            <div className="qualification__sections">
              <div
                className={`qualification__content ${
                  activeTab === "education" ? "qualification__active" : ""
                }`}
                id="education"
              >
                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">Ordinary Level </h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2018
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

                <div className="qualification__data">
                  <div></div>
                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">Advanced Level</h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2022
                    </div>
                  </div>
                </div>

                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">
                      Training and certification
                    </h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2023
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

                <div className="qualification__data">
                  <div></div>
                  <div>
                    <span className="qualification__rounder"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">University</h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2021
                    </div>
                  </div>
                </div>
              </div>

              <div className="qualification__tabs">
                <div
                  className={`qualification__button button--flex ${
                    activeTab === "work" ? "qualification__active" : ""
                  }`}
                  onClick={() => handleTabClick("work")}
                >
                  <i className="uil uil-graduation-cap qualification__icon">
                    <StudiedIcon />
                  </i>
                  Work
                </div>
              </div>

              <div
                className={`qualification__content ${
                  activeTab === "work" ? "qualification__active" : ""
                }`}
                id="work"
              >
                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">
                      Website development
                    </h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2018 to present day
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>
                </div>

                <div className="qualification__data">
                  <div></div>
                  <div>
                    <span className="qualification__rounder"></span>
                    <span className="qualification__line"></span>
                  </div>

                  <div>
                    <h3 className="qualification__title">
                      Software development
                    </h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2020 to present day
                    </div>
                  </div>
                </div>

                <div className="qualification__data">
                  <div>
                    <h3 className="qualification__title">
                      Repair and management
                    </h3>
                    <span className="qualification__subtitle">
                      Kampala, Uganda
                    </span>
                    <div className="qualification__calendar">
                      <i className="uil uil-calendar-alt">
                        <CalenderIcon />
                      </i>
                      2017 to present day
                    </div>
                  </div>

                  <div>
                    <span className="qualification__rounder"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="services section" id="services">
          <h2 className="section__title">Services</h2>
          <span className="section__subtitle">What I offer</span>

          <div className="services__container container-p grid">
            <div className="services__content">
              <div>
                <i className="uil uil-atom skills__icon">
                  <ReactIcon />
                </i>
                <h3 className="services__title">
                  Consultancy and teaching
                  <br />
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(0)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>

              {activeModal === 0 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Consultancy and teaching <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>I provide expert advisory services.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Identify and address issues.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Guide and support learners who take up IT.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Assiting both personal and commercial clients with
                          financial questions and needs.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="services__content">
              <div>
                <i className="uuil uil-desktop skills__icon">
                  <PCSetIcon />
                </i>
                <h3 className="services__title">
                  Installations and upgrades
                  <br />{" "}
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(1)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>

              {activeModal === 1 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Installations and upgrades
                      <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Connect and configure devices.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          I install and upgrade user applications and Operating
                          Systems.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Boot and configure new systems.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Performance enhancement and system migration.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="services__content">
              <div>
                <i className="uil uil-wrench skills__icon">
                  <RepairToolIcon />
                </i>
                <h3 className="services__title">
                  Repair and maintenance
                  <br />{" "}
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(2)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>
              {activeModal === 2 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Repair and maintenance
                      <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Train staff on proper IT usage and troubleshooting
                          techniques.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Perform regular software updates and patches to ensure
                          security and stability.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Implement cybersecurity measures such as firewalls and
                          antivirus.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Troubleshoot and resolve hardware malfunctions.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="services__content">
              <div>
                <i className="uil uil-image-resize-square skills__icon">
                  <PhotoIcon />
                </i>
                <h3 className="services__title">
                  Graphics design and creation
                  <br />{" "}
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(3)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>
              {activeModal === 3 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Graphics design and creation
                      <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Edit and manipulate images to achieve desired effects.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Present designs and presentations effectively to
                          clients or teams.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>Iterate designs based on feedback and testing.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>I design business cards, posters, banners.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="services__content">
              <div>
                <i className="uil uil-android skills__icon">
                  <AndroidIcon />
                </i>
                <h3 className="services__title">
                  Application development
                  <br />{" "}
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(4)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>

              {activeModal === 4 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Application development
                      <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>I make mobile and desktop applications.</p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Test applications thoroughly for bugs and usability
                          issues.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Implement backend functionality and database
                          integration.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Systematically applying statistical and logical
                          techniques.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="services__content">
              <div>
                <i className="uil uil-arrow services__icon">
                  <CodeBracketIcon />
                </i>
                <h3 className="services__title">
                  Website development
                  <br />
                </h3>
              </div>
              <span
                className="button button--flex button--small button--link services__button"
                onClick={() => openModal(5)}
              >
                View more
                <i className="uil uil-arrow-right button__icon"></i>
              </span>
              {activeModal === 5 && (
                <div className="services__modal active-modal">
                  <div className="services__modal-content" ref={modalRef}>
                    <h4 className="services__modal-title">
                      Website development
                      <br />
                    </h4>
                    <i
                      className="uil uil-times services__modal-close"
                      onClick={closeModal}
                    >
                      <CloseIcon />
                    </i>

                    <ul className="services__modal-services grid">
                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          I use appropriate technologies and frameworks to build
                          websites.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Test and debug website functionality across different
                          environments.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Design responsive layouts for various devices and
                          screen sizes.
                        </p>
                      </li>

                      <li className="services__modal-service">
                        <i className="uil uil-check-circle services__modal-icon"></i>
                        <p>
                          Optimize website performance for speed and loading
                          times.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="portfolio section" id="portfolio">
          <h2 className="section__title">Portfolio</h2>
          <span className="section__subtitle">Most recent projects</span>

          <div className="portfolio__container container-p swiper-container">
            <div className="swiper-wrapper project-preview-s-d-s">
              <div className="portfolio__content grid swiper-slide">
                <img
                  src="../../../developer/images/portfolio1.png"
                  alt=""
                  className="portfolio__img"
                />

                <div className="portfolio_">
                  <h3 className="portfolio__title">Namrolink - Social media</h3>
                  <p className="portfolio__description">
                    Namrolink: Your go-to social platform for vibrant
                    connections and inspiring conversations.
                  </p>
                  <a
                    href="https://www.namrolink.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--flex button--small portfolio__button"
                  >
                    View
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              </div>

              <div className="portfolio__content grid swiper-slide">
                <img
                  src="../../../developer/images/portfolio2.png"
                  alt=""
                  className="portfolio__img"
                />

                <div className="portfolio_">
                  <h3 className="portfolio__title">Namrolink Portfolio</h3>
                  <p className="portfolio__description">
                    A gateway to showcasing some of my best work and unlocking
                    new opportunities.
                  </p>
                  <a
                    href="https://www.namrolink.com/developer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--flex button--small portfolio__button"
                  >
                    View
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              </div>

              <div className="portfolio__content grid swiper-slide">
                <img
                  src="../../../developer/images/portfolio3.jpg"
                  alt=""
                  className="portfolio__img"
                />

                <div className="portfolio_">
                  <h3 className="portfolio__title">Namronet - E-Commerce</h3>
                  <p className="portfolio__description">
                    Your premier e-commerce destination for quality products and
                    seamless shopping.
                  </p>
                  <a
                    href="https://www.namronet.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--flex button--small portfolio__button"
                  >
                    View
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              </div>

              <div className="portfolio__content grid swiper-slide">
                <img
                  src="../../../developer/images/portfolio4.png"
                  alt=""
                  className="portfolio__img"
                />

                <div className="portfolio_">
                  <h3 className="portfolio__title">Namronet Admin</h3>
                  <p className="portfolio__description">
                    Streamline business operations an intuitive and efficient
                    admin platform. Simplifying workflow.
                  </p>
                  <a
                    href="https://admin.namronet.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button--flex button--small portfolio__button"
                  >
                    View
                    <i className="uil uil-arrow-right button__icon"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="swiper-button-next">
              <i className="uil uil-angle-right-b swiper-portfolio-icon"></i>
            </div>

            <div className="swiper-button-prev">
              <i className="uil uil-angle-left-b swiper-portfolio-icon"></i>
            </div>

            <div className="swiper-pagination"></div>
          </div>
        </section>

        <section className="project section">
          <div className="project__bg idea-home-section">
            <div className="project__container container-p grid">
              <div className="project__data">
                <h2 className="project__title">
                  You have a project idea or just need help?
                </h2>
                <p className="project__description">
                  Contact me now and help and guidance for your business or
                  personal interest.
                </p>
                <a
                  href="#contact"
                  className="button button--flex button--white"
                >
                  Contact me
                  <i className="uil uil-message project__icon button__icon">
                    <SendOutlineIcon />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="contact section" id="contact">
          <h2 className="section__title">Contact me</h2>
          <span className="section__subtitle">Get in touch</span>

          <div className="contact__container container-p grid">
            <div>
              <div className="contact__information">
                <i className="uil uil-phone-alt contact__icon">
                  <PhoneIcon />
                </i>

                <div>
                  <h3 className="contact__title">Phone number</h3>
                  <span className="contatc__subtitle">+256789980531</span>
                </div>
              </div>

              <div className="contact__information">
                <i className="uil uil-whatsapp contact__icon">
                  <WhatsAppIcon />
                </i>

                <div>
                  <h3 className="contact__title">WhatsApp</h3>
                  <span className="contatc__subtitle">+256789980531</span>
                </div>
              </div>

              <div className="contact__information">
                <i className="uil uil-chat-bubble-user contact__icon">
                  <MessagesIcon />
                </i>

                <div>
                  <h3 className="contact__title">Namrolink</h3>
                  <span className="contact__subtitle">@SayigaNorman</span>
                </div>
              </div>

              <div className="contact__information">
                <i className="uil uil-envelope contact__icon">
                  <EmailIcon />
                </i>

                <div>
                  <h3 className="contact__title">E-mail</h3>
                  <span className="contact__subtitle">
                    sayiganormanhaq22@gmail.com
                  </span>
                </div>
              </div>

              <div className="contact__information">
                <i className="uil uil-map-marker contact__icon">
                  <LocationIcon />
                </i>

                <div>
                  <h3 className="contact__title">Location</h3>
                  <span className="contatc__subtitle">Kampala, Uganda</span>
                </div>
              </div>
            </div>
            {user ? (
              <LoggedInUsersDeveloperForm />
            ) : (
              <form action="" className="contact__form grid">
                <div className="contact__inputs grid">
                  <div className="contact__content">
                    <label for="" className="contact__label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="contact__input"
                      onClick={() => navigate("/login")}
                    />
                  </div>

                  <div className="contact__content">
                    <label for="" className="contact__label">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="contact__input"
                      onClick={() => navigate("/login")}
                    />
                  </div>
                </div>

                <div className="contact__content">
                  <label for="" className="contact__label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="contact__input"
                    onClick={() => navigate("/login")}
                  />
                </div>

                <div className="contact__content">
                  <label for="" className="contact__label">
                    Description
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="0"
                    rows="7"
                    className="contact__input"
                    style={{ resize: "none" }}
                    onClick={() => navigate("/login")}
                  ></textarea>
                </div>

                <div>
                  <a
                    href="#"
                    className="button button--flex"
                    onClick={() => navigate("/login")}
                  >
                    Send message
                    <i className="uil uil-message button__icon developer-c-s-m-i-s">
                      <SendOutlineIcon />
                    </i>
                  </a>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>

      <footer className="footer">
        <div className="footer__bg">
          <div className="footer__container container-p grid">
            <div>
              <h1 className="footer__title">Sayiga Norman Haq</h1>
              <span className="footer__subtitle">Software developer</span>
            </div>

            <ul className="footer__links">
              <li>
                <a href="#services" className="footer__link">
                  Services
                </a>
              </li>
              <li>
                <a href="#portfolio" className="footer__link">
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#contact" className="footer__link">
                  Contact
                </a>
              </li>
            </ul>

            <div className="footer__socials">
              <a
                href="https://www.facebook.com/Sayiga.Norman"
                target="_blank"
                className="footer__social"
              >
                <i className="uil uil-facebook">
                  <FacebookIcon />
                </i>
              </a>
              <a
                href="https://www.instagram.com/sayiganorman/"
                target="_blank"
                className="footer__social"
              >
                <i className="uil uil-instagram">
                  <InstagramIcon />
                </i>
              </a>
              <a
                href="https://namrolink.com/developer?external"
                target="_blank"
                className="footer__social"
              >
                <i className="uil uil-chat-bubble-user">
                  <UserCircleIcon />
                </i>
              </a>
              <a
                href="https://namrolink.com/developer?sayiganormanhaq"
                target="_blank"
                className="footer__social"
              >
                <i className="uil uil-user">
                  <UserIcon />
                </i>
              </a>
            </div>
          </div>
          <p className="footer__copy">
            &#169; <span id="currentYear">{currentYear}</span> Sayiga Norman
            Haq. All rights reserved.
          </p>
        </div>
      </footer>

      <a href="#" className="scrollup" id="scroll-up">
        <i className="uil uil-arrow-up scrollup__icon"></i>
      </a>
    </div>
  );
}
