import { useEffect, useState } from "react";
import Bio from "./Bio";
import "./style.css";
import axios from "axios";
import { useSelector } from "react-redux";
import EditDetails from "./EditDetails";
import InfoIcon from "../icons/InfoIcon";
import HeartSolidIcon from "../icons/HeartSolidIcon";
import WorkIcon from "../icons/WorkIcon";
import WorkplaceIcon from "../icons/WorkplaceIcon";
import StudiedIcon from "../icons/StudiedIcon";
import StudiedSolidIcon from "../icons/StudiedSolidIcon";
import FromIcon from "../icons/FromIcon";
import LivesInIcon from "../icons/LivesInIcon";
import GlobeIcon from "../icons/GlobeIcon";
import BioButtonSwap from "./BioButtonSwap";
import IdentificationSolidIcon from "../icons/IdentificationSolidIcon";
import GlobeQuestion from "../icons/GlobeQuestion";

export default function Intro({ detailss, visitor, setOthername }) {
  const { user } = useSelector((state) => ({ ...state }));
  const [details, setDetails] = useState(detailss);
  const [visible, setVisible] = useState(false);

  // Initialize state for bio details
  const initial = {
    bio: detailss?.bio || "",
    otherName: detailss?.otherName || "",
    job: detailss?.job || "",
    workplace: detailss?.workplace || "",
    highSchool: detailss?.highSchool || "",
    college: detailss?.college || "",
    currentCity: detailss?.currentCity || "",
    hometown: detailss?.hometown || "",
    relationship: detailss?.relationship || "",
    website: detailss?.website || "",
  };

  const [infos, setInfos] = useState(initial);
  const [showBio, setShowBio] = useState(false);
  const [max, setMax] = useState(infos?.bio ? 100 - infos?.bio.length : 100);
  const [show, setShow] = useState(false);
  const [showWebsiteError, setShowWebsiteError] = useState(false);
  const [showWebsitePopup, setShowWebsitePopup] = useState(false);

  useEffect(() => {
    setDetails(detailss);
    setInfos(initial);
  }, [detailss]);

  const updateDetails = async () => {
    if (
      infos.website &&
      !infos.website.match(/^(https:\/\/|https:\/\/www\.|http:\/\/www\.)/)
    ) {
      setShowWebsiteError(true);
      return;
    }
    setShowWebsiteError(false);
    try {
      console.log("sent");
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/updateDetails`,
        {
          infos,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setShowBio(false);
      setDetails(data);
      setOthername(data.otherName);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfos((prevInfos) => ({
      ...prevInfos,
      [name]: value,
    }));
    setMax(100 - e.target.value.length);
  };

  // Check if any bio detail is filled in
  const hasBioDetail = Object.values(infos).some((value) => value);

  const handleWebsiteClick = () => {
    setShowWebsitePopup(true);
  };

  const handleWebsiteCancel = () => {
    setShowWebsitePopup(false);
  };

  const handleWebsiteProceed = () => {
    window.open(details.website, "_blank");
    setShowWebsitePopup(false);
  };

  return (
    <div>
      <h4 className="outer-f-t-s">Intro</h4>
      <div className="feed">
        {!hasBioDetail && !visitor && (
          <div className="d-y-infos-m">
            &#128515; Describe yourself to the public
          </div>
        )}
        {!hasBioDetail && visitor && (
          <div className="n-b-d-infos-m">
            &#128532; No bio detail has been added yet
          </div>
        )}

        {details?.otherName && (
          <div className="info-profile">
            <div className="info-icon-container">
              <IdentificationSolidIcon />
            </div>
            <div className="info-text">{details.otherName}</div>
          </div>
        )}

        {details?.bio && (
          <div className="info-profile">
            <div className="info-icon-container">
              <InfoIcon />
            </div>
            <div className="info-text">{details.bio}</div>
          </div>
        )}
        {details?.relationship && (
          <div className="info-profile">
            <div className="info-icon-container">
              <HeartSolidIcon />
            </div>
            {details.relationship}
          </div>
        )}
        {details?.website && (
          <div className="info-profile">
            <div className="info-icon-container">
              <GlobeIcon />
            </div>
            <div>
              <div
                className="info-text website-details-link-p"
                onClick={handleWebsiteClick}
              >
                {details.website}
              </div>
              {showWebsitePopup && (
                <div className="link-open-details-c">
                  <div className="save-changes-left">
                    <i className="u-l-n-p-p-s-i">
                      <GlobeQuestion />
                    </i>
                    Are you sure you trust this website? Please do not open any
                    malicious links.
                  </div>
                  <div className="save-changes-left">({details.website})</div>
                  <div className="save-changes-right">
                    <button
                      className="p-p-blue_btn-cancel"
                      onClick={handleWebsiteCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-p-blue_btn"
                      onClick={handleWebsiteProceed}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {details?.job && (
          <div className="info-profile">
            <div className="info-icon-container">
              <WorkIcon />
            </div>
            Works as a {details.job}
          </div>
        )}
        {details?.workplace && (
          <div className="info-profile">
            <div className="info-icon-container">
              <WorkplaceIcon />
            </div>
            Works at {details.workplace}
          </div>
        )}
        {details?.college && (
          <div className="info-profile">
            <div className="info-icon-container">
              <StudiedSolidIcon />
            </div>
            Studied at {details.college}
          </div>
        )}
        {details?.highSchool && (
          <div className="info-profile">
            <div className="info-icon-container">
              <StudiedIcon />
            </div>
            Studied at {details.highSchool}
          </div>
        )}
        {details?.currentCity && (
          <div className="info-profile">
            <div className="info-icon-container">
              <LivesInIcon />
            </div>
            Lives in {details.currentCity}
          </div>
        )}
        {details?.hometown && (
          <div className="info-profile">
            <div className="info-icon-container">
              <FromIcon />
            </div>
            From {details.hometown}
          </div>
        )}
        {showBio && (
          <Bio
            infos={infos}
            max={max}
            handleChange={handleChange}
            setShowBio={setShowBio}
            updateDetails={updateDetails}
            placeholder="Add bio here"
            name="bio"
            setShow={setShow}
          />
        )}
        <div className="u-info-btn-container">
          <div>
            {details?.bio && !showBio && (
              <div>
                {!visitor && (
                  <button
                    className="btn btn-primary u-p-i-btn"
                    onClick={() => setShowBio(true)}
                  >
                    Edit Bio
                  </button>
                )}
              </div>
            )}
            {!details?.bio && !showBio && !visitor && (
              <button
                className="btn btn-primary u-p-i-btn"
                onClick={() => setShowBio(true)}
              >
                Add Bio
              </button>
            )}
            {showBio && (
              <BioButtonSwap
                infos={infos}
                max={max}
                handleChange={handleChange}
                setShowBio={setShowBio}
                updateDetails={updateDetails}
                placeholder="Add Bio"
                name="bio"
                setShow={setShow}
              />
            )}
          </div>
          <div>
            {!visitor && (
              <button
                className="btn u-p-i-btn"
                onClick={() => setVisible(true)}
              >
                Edit Details
              </button>
            )}
            {visible && !visitor && (
              <EditDetails
                details={details}
                handleChange={handleChange}
                updateDetails={updateDetails}
                infos={infos}
                setVisible={setVisible}
              />
            )}
          </div>
        </div>
        {showWebsiteError && (
          <div className="website-error">
            Please use a valid website link like https://www.namronnect.com
          </div>
        )}
      </div>
    </div>
  );
}
