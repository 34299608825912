import { useState } from "react";
import ProfilePhotosPreview from "../../components/ImagesPreview/ProfilePhotosPreview";

export default function Photos({ username, token, photos }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = () => setIsPreviewOpen(true);
  const closePreview = () => setIsPreviewOpen(false);

  return (
    <div>
      <h4 className="outer-f-t-s">Photos</h4>
      <div className="feed">
        <p>
          {photos.total_count === 0 ? (
            <b>No photos yet &#128532;</b>
          ) : photos.total_count === 1 ? (
            <b>1 photo</b>
          ) : (
            <b>{photos.total_count} photos</b>
          )}
        </p>
        <div className="photo">
          <div className="profile-card-grid">
            {photos.resources &&
              photos.resources.slice(0, 9).map((img) => (
                <div className="profile-photo-card" key={img.public_id}>
                  <img
                    src={img.secure_url}
                    alt=""
                    onClick={openPreview}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}
          </div>
        </div>
        {photos.total_count > 0 && (
          <div className="see-a-photos" onClick={openPreview}>
            See all photos
          </div>
        )}
      </div>
      {isPreviewOpen && (
        <ProfilePhotosPreview
          photos={photos.resources}
          onClose={closePreview}
        />
      )}
    </div>
  );
}
