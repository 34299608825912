import React, { useState } from "react";
import PreviousImageIcon from "../icons/PreviousImageIcon";
import NextImageIcon from "../icons/NextImageIcon";
import GlobeIcon from "../icons/GlobeIcon";
import { useNavigate } from "react-router-dom";

export default function RightImagesSlider() {
  const navigate = useNavigate();
  const images = [
    "../../../images/imagesPreviewImages/social-media-example-1.jpg",
    "../../../images/imagesPreviewImages/social-media-example-3.jpg",
    "../../../images/imagesPreviewImages/social-media-example-2.jpg",
    "../../../images/imagesPreviewImages/social-media-example-4.jpg",
    "../../../images/imagesPreviewImages/social-media-example-5.jpg",
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setActiveIndex((activeIndex - 1 + images.length) % images.length);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <div className="w-o-c-r-s">
      <div className="right-images-slider-container">
        <div className="right-i-p-c-heading">
          <h4>Connect</h4>
          <i className="r-s-i-c-pointer" onClick={() => navigate("/help")}>
            <GlobeIcon />
          </i>
        </div>
        <div className="right-images-slider">
          <div className="list-right-s">
            {images.map((image, index) => (
              <div
                key={index}
                className={`useritem ${
                  index === activeIndex ? "useractivei" : ""
                }`}
              >
                <img src={image} alt="" />
              </div>
            ))}
          </div>
          <div className="userarrows-r">
            <button id="prevarrowr" onClick={handlePrev}>
              <PreviousImageIcon />
            </button>
            <button id="nextarrowr" onClick={handleNext}>
              <NextImageIcon />
            </button>
          </div>

          <div className="right-userthumbnail">
            {images.map((image, index) => (
              <div
                key={index}
                className={`useritem ${
                  index === activeIndex ? "useractivei" : ""
                }`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
