import { useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";
import "./style.css";
import EmojiIcon from "../icons/EmojiIcon";
import PhotoIcon from "../icons/PhotoIcon";
import BackgroundIcon from "../icons/BackgroundIcon";
import VideoCameraIcon from "../icons/VideoCameraIcon";
import { ClipLoader } from "react-spinners";

export default function EmojiPickerBackgrounds({
  text,
  setText,
  textRef,
  imageInputRef,
  background,
  setBackground,
  bgRef,
  loadingVideo,
}) {
  const [picker, setPicker] = useState(false);
  const [showBgs, setShowBgs] = useState(false);
  const [cursorPosition, setCursorPosition] = useState();

  useEffect(() => {
    textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const handleEmoji = (e, { emoji }) => {
    const ref = textRef.current;
    ref.focus();
    const start = text.substring(0, ref.selectionStart);
    const end = text.substring(ref.selectionStart);
    const newText = start + emoji + end;
    setText(newText);
    setCursorPosition(start.length + emoji.length);
  };

  const removeBackground = () => {
    if (bgRef.current) {
      bgRef.current.style.backgroundImage = "";
      setBackground("");
      bgRef.current.classList.remove("bgHandler");
    }
  };

  const postBackgrounds = [
    "../../../images/postBackgrounds/1.jpg",
    "../../../images/postBackgrounds/2.jpg",
    "../../../images/postBackgrounds/3.jpg",
    "../../../images/postBackgrounds/4.jpg",
    "../../../images/postBackgrounds/5.jpg",
    "../../../images/postBackgrounds/6.jpg",
    "../../../images/postBackgrounds/7.jpg",
    "../../../images/postBackgrounds/8.jpg",
    "../../../images/postBackgrounds/9.jpg",
  ];

  const backgroundHandler = (i) => {
    if (bgRef.current) {
      bgRef.current.style.backgroundImage = `url(${postBackgrounds[i]})`;
      setBackground(postBackgrounds[i]);
      bgRef.current.classList.add("bgHandler");
    }
  };

  return (
    <div className="post_emojis_wrap">
      {picker && (
        <div className="comment_emoji_picker rlmove">
          <Picker onEmojiClick={handleEmoji} />
        </div>
      )}
      <i
        className="c-p-p-post-bg-i-s cursor-pointer-element-style"
        onClick={() => {
          setShowBgs((prev) => !prev);
        }}
      >
        <BackgroundIcon />
      </i>
      {showBgs && (
        <div className="post_backgrounds">
          <div
            className="no_bg"
            onClick={() => {
              removeBackground();
            }}
          ></div>
          {postBackgrounds.map((bg, i) => (
            <img
              src={bg}
              key={i}
              alt=""
              onClick={() => {
                backgroundHandler(i);
              }}
            />
          ))}
        </div>
      )}
      <div className="post-popup-l-icons-holder">
        {loadingVideo && (
          <div className="c-p-p-photo-icon-s">
            <div>
              <ClipLoader size={18} color="var(--color-primary)" />
            </div>
          </div>
        )}
        <div
          className="c-p-p-photo-icon-s"
          onClick={() => {
            imageInputRef.current.click();
          }}
        >
          <PhotoIcon />
        </div>
        <div
          className="c-p-p-photo-icon-s"
          onClick={() =>
            document.getElementById("uploadPostVideoInput").click()
          }
        >
          <VideoCameraIcon />
        </div>
        <i
          className="c-p-p-emoji-i-s"
          onClick={() => {
            setPicker((prev) => !prev);
          }}
        >
          <EmojiIcon />
        </i>
      </div>
    </div>
  );
}
