import { useSelector } from "react-redux";
import SearchIcon from "../../components/icons/SearchIcon";
import useClickOutside from "../../helpers/clickOutside";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  addToSearchHistory,
  getSearchHistory,
  removeFromSearch,
  search,
} from "../../functions/user";
import CloseIcon from "../../components/icons/CloseIcon";
import { HashLoader } from "react-spinners";

export default function SearchUsers({ setShowSearchUsersMenu }) {
  const { user } = useSelector((user) => ({ ...user }));
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const usmenu = useRef(null);
  const searchTextRef = useRef(null);

  useClickOutside(usmenu, () => {
    setShowSearchUsersMenu(false);
  });

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    const res = await getSearchHistory(user.token);
    setSearchHistory(res);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.trim() === "") {
        setResults([]);
        setNoResults(false);
        return;
      }

      setLoading(true);
      const res = await search(searchTerm, user.token);
      setLoading(false);

      if (res.length === 0) {
        setNoResults(true);
        setResults([]);
      } else {
        setNoResults(false);
        setResults(res);
      }
    }, 1000); // Delay of 1 second

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, user.token]);

  const addToSearchHistoryHandler = async (searchUser) => {
    const res = await addToSearchHistory(searchUser, user.token);
    getHistory();
  };
  const handleRemove = async (searchUser) => {
    removeFromSearch(searchUser, user.token);
    getHistory();
  };

  useEffect(() => {
    searchTextRef.current.focus();
  }, []);

  return (
    <div className="blur">
      <div className="search-u-m-p-c-s" ref={usmenu}>
        <div className="m-p-search-container-s">
          <div className="u-m-p-search-i-s">
            <SearchIcon />
          </div>
          <input
            ref={searchTextRef}
            type="search"
            name="search"
            id="users-search"
            placeholder="Search Namrolink"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <select id="users-filter">
            <option value="creator">Users</option>
          </select>
        </div>
        <div className="m-p-search-results-c-s">
          <div className="searched-contents-m-p-s">
            <div>
              {loading && searchTerm.trim() !== "" && (
                <div className="seach-menu-loader-container">
                  <HashLoader color="#1876f2" size={50} />
                </div>
              )}
              {results.length === 0 && !noResults && (
                <div className="u-search-menu-heading">
                  <div className="u-search-menu-title">Recent searches</div>
                  <div
                    className="u-search-menu-close"
                    onClick={() => {
                      setShowSearchUsersMenu(false);
                    }}
                  >
                    Close
                  </div>
                </div>
              )}
              <div>
                {searchHistory &&
                  results.length === 0 &&
                  !noResults &&
                  searchHistory
                    .sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    })
                    .map((user) => (
                      <div
                        className="recent-u-search-h-item hover1-m-p"
                        key={user?._id}
                      >
                        <Link
                          className="recent-u-search-h-l"
                          to={`/chat/${user.user?._id}`}
                          onClick={() => {
                            addToSearchHistoryHandler(user.user?._id);
                            setShowSearchUsersMenu(false);
                          }}
                        >
                          <img src={user.user?.picture} alt="" />
                          <div>
                            <h5>
                              {user.user?.first_name} {user.user?.last_name}{" "}
                            </h5>
                            <p className="text-muted">
                              <small>@{user.user?.username}</small>
                            </p>
                          </div>
                        </Link>
                        <i
                          className="search-m-exit-i-s"
                          onClick={() => {
                            handleRemove(user.user?._id);
                          }}
                        >
                          <CloseIcon />
                        </i>
                      </div>
                    ))}
              </div>
              <div>
                {results.length > 0 &&
                  results.map((user) => (
                    <div
                      className="u-search-r-h-item-c-s hover1-m-p"
                      key={user?._id}
                    >
                      <Link
                        to={`/chat/${user?._id}`}
                        className="u-search-r-h-item"
                        onClick={() => {
                          addToSearchHistoryHandler(user?._id);
                          setShowSearchUsersMenu(false);
                        }}
                      >
                        <img src={user?.picture} alt="" />
                        <div>
                          <h5>
                            {user?.first_name} {user?.last_name}
                          </h5>
                          <p className="text-muted">
                            <small>@{user?.username}</small>
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}
                {noResults && searchTerm.trim() !== "" && (
                  <h4>No user found</h4>
                )}
              </div>
              <div className="search-menu-l-s-s"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
