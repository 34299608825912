import { useSelector } from "react-redux";
import EditIcon from "../icons/EditIcon";
import SearchIcon from "../icons/SearchIcon";
import { Link, useNavigate } from "react-router-dom";
import FeedbackPopup from "../feedbackPopup";
import { useState } from "react";

export default function RightMessagesBox() {
  const { user } = useSelector((user) => ({ ...user }));
  const navigate = useNavigate();
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false);
  const openFeedbackPopup = () => {
    setIsFeedbackPopupOpen(true);
  };
  const closeFeedbackPopup = () => {
    setIsFeedbackPopupOpen(false);
  };

  return (
    <div className="messages">
      <div className="heading_messages">
        <h4 className="right-heading-hover-s">Messages</h4>
        <i
          className="uil uil-edit right-section-icon r-s-i-c-pointer"
          onClick={() => navigate("/chat")}
        >
          <EditIcon />
        </i>
      </div>

      <div className="search-bar">
        <i className="uil uil-search right-section-icon r-s-i-c-pointer">
          <SearchIcon />
        </i>
        <input
          id="messages"
          type="search"
          placeholder="Search messages"
          onClick={() => navigate("/chat")}
          onFocus={() => navigate("/chat")}
        />
      </div>

      <div className="category">
        <h6 className="activeitem" onClick={() => navigate("/chat")}>
          Recent
        </h6>
        <h6 onClick={() => navigate("/chat")}>Friends</h6>
        <h6 className="messages-requests" onClick={() => navigate("/chat")}>
          All
        </h6>
      </div>

      <div className="msg-list">
        <Link to={`/chat/${user?.id}`} className="message">
          <div className="profile-photo">
            <img src={user?.picture} className="user-profile-img" alt="" />
          </div>
          <div className="message-body">
            <h5>
              {user?.first_name} {user?.last_name}
            </h5>
            <p className="text-muted">Message yourself</p>
          </div>
        </Link>
        <div className="message" onClick={() => openFeedbackPopup()}>
          <div className="profile-photo">
            <img
              src="../../../images/defaultUserImages/user-img-2.jpg"
              className="user-profile-img"
              alt=""
            />
          </div>
          <div className="message-body">
            <h5>Namrolink Support</h5>
            <p className="text-muted">Need some help?</p>
          </div>
        </div>
        {isFeedbackPopupOpen && (
          <FeedbackPopup
            subject={`Right side section feedback or query from: ${user?.id} (${user?.username})`}
            onClose={closeFeedbackPopup} // Pass a function to close the popup
          />
        )}
        <Link to="/chat" className="text-muted message-home-view-more">
          Show all messages
        </Link>
      </div>
    </div>
  );
}
