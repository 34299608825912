import "../../styles/advertisement.css";
import { useSelector } from "react-redux";
import PostOptionsIcon from "../icons/PostOptionsIcon";
import HeartIcon from "../icons/HeartIcon";
import CommentIcon from "../icons/CommentIcon";
import ShareIcon from "../icons/ShareIcon";
import SavePostIcon from "../icons/SavePostIcon";
import CheckBadgeSolidIcon from "../icons/CheckBadgeSolidIcon";
import { useEffect, useRef, useState } from "react";
import PreviousImageIcon from "../icons/PreviousImageIcon";
import NextImageIcon from "../icons/NextImageIcon";
import { Link, useNavigate } from "react-router-dom";
import InfoIcon from "../icons/InfoIcon";
import EcommerceIcon from "../icons/EcommerceIcon";
import HelpIcon from "../icons/HelpIcon";
import MusicIcon from "../icons/MusicIcon";
import NewspaperIcon from "../icons/NewspaperIcon";
import useOnClickOutside from "../../helpers/clickOutside";
import ImagesPreviewPopup from "../ImagesPreview/ImagesPreviewPopup";
import LinkIcon from "../icons/LinkIcon";
import MessageOutlineIcon from "../icons/MessageOutlineIcon";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import TelegramIcon from "../icons/TelegramIcon";
import FacebookIcon from "../icons/FacebookIcon";

export default function AdvertisementDefault() {
  const { user } = useSelector((user) => ({ ...user }));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAdMenu, setShowAdMenu] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const link = `${process.env.REACT_APP_FRONTEND_URL}`;
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const admenu = useRef(null);
  const shareIconRef = useRef(null);

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  useOnClickOutside(admenu, () => setShowAdMenu(false));
  useOnClickOutside(shareIconRef, () => setIsSharePopupOpen(false));

  const [isThumbnailVisible, setIsThumbnailSectionVisible] = useState(true);
  const images = [
    "../../../images/imagesPreviewImages/social-media-example-1.jpg",
    "../../../images/imagesPreviewImages/social-media-example-2.jpg",
    "../../../images/imagesPreviewImages/social-media-example-3.jpg",
    "../../../images/imagesPreviewImages/social-media-example-4.jpg",
    "../../../images/imagesPreviewImages/social-media-example-5.jpg",
  ];

  const adimages = [
    "../../../images/imagesPreviewImages/e-commerce-example-6.jpg",
    "../../../images/imagesPreviewImages/e-commerce-example-1.jpg",
    "../../../images/imagesPreviewImages/e-commerce-example-2.jpg",
    "../../../images/imagesPreviewImages/e-commerce-example-4.jpg",
  ];

  const adtexts = [
    "Connect with fun new people.",
    "Have safe and respectful interactions.",
    "Dive into Namrolink music for fun.",
    "Post, share, like and comment freely!",
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setActiveIndex((activeIndex - 1 + images.length) % images.length);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const toggleThumbnailSection = () => {
    setIsThumbnailSectionVisible(!isThumbnailVisible);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Get the current date
  const currentDate = new Date();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % adimages.length);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1400); // Hide message after 1 second
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const handleLinkCopy = () => {
    copyToClipboard(link); // Copy link to clipboard
  };

  return (
    <>
      <div className="feed post-middle-p-r">
        <div className="head">
          <div className="user">
            <Link to="/help" className="profile-photo ad-p-i-c-s">
              <img
                src="../../../images/defaultUserImages/user-img-2.jpg"
                className="ad-profile-a-img-s"
                alt=""
              />
            </Link>
            <div className="ingo">
              <h3
                className="c-p-user-feed-post as-u-t-f-l-n-s"
                onClick={() => navigate("/help")}
              >
                Namrolink Admin <CheckBadgeSolidIcon />
              </h3>
              <small>Kampala, Uganda, {formatDate(currentDate)}</small>
            </div>
          </div>
          <span className="more" onClick={() => setShowAdMenu((prev) => !prev)}>
            <i className="uil uil-ellipsis-h user-feed-icons">
              <PostOptionsIcon />
            </i>
          </span>
        </div>
        <div className="caption">
          <p>
            Hello{" "}
            <span className="hash-tag">
              {user?.first_name} {user?.last_name}
            </span>
            , create a post to see it on your feed. {adtexts[currentIndex]}
          </p>
        </div>
        <div className="photo">
          <div className="photo">
            <div className="advertisement-preview3">
              <div className="ad-img-p-container-m">
                <div className="advertisement-i-slider">
                  <div className="list">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className={`useritem ${
                          index === activeIndex ? "useractivei" : ""
                        }`}
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                  </div>

                  <i className="prev-ad-m-u-a" onClick={handlePrev}>
                    <PreviousImageIcon />
                  </i>
                  <i className="next-ad-m-u-a" onClick={handleNext}>
                    <NextImageIcon />
                  </i>

                  <div className="advertisement-m-userthumbnail">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className={`useritem ${
                          index === activeIndex ? "useractivei" : ""
                        }`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img src={image} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="changing-ad-img-d-s">
                <img src={adimages[currentIndex]} alt="" />
              </div>
              <div className="p-i-z-o-m-s">
                <img
                  src="../../../images/postBackgrounds/5.jpg"
                  alt=""
                  className="popup-m-img-p-o-a-s"
                  onClick={handleOpenPopup}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="action-buttons">
          <div className="interaction-buttons">
            <span className="like" onClick={() => navigate("/help")}>
              <i className="uil uil-heart user-feed-icons">
                <InfoIcon />
              </i>
            </span>
            <span onClick={() => navigate("/discover/feed")}>
              <i className="uil uil-comment-dots user-feed-icons">
                <NewspaperIcon />
              </i>
            </span>

            <span
              ref={shareIconRef}
              className="share-icon-wrapper"
              onClick={toggleSharePopup}
            >
              <i className="uil uil-share-alt user-feed-icons">
                <ShareIcon />
                {copied && <div className="link-c-m-s copied-o-s">Copied</div>}
              </i>
              {isSharePopupOpen && (
                <div className="share-popup">
                  <div className="share-icons-container">
                    <div
                      className="share-icon link-container-s"
                      onClick={() => copyToClipboard(link)}
                    >
                      <LinkIcon />
                    </div>
                    <Link
                      to="/chat"
                      className="share-icon"
                      onClick={handleLinkCopy}
                    >
                      <MessageOutlineIcon />
                    </Link>
                    <div
                      className="share-icon"
                      onClick={() =>
                        openInNewTab(`https://wa.me/?text=${link}`)
                      }
                    >
                      <WhatsAppIcon />
                    </div>
                    <div
                      className="share-icon"
                      onClick={() =>
                        openInNewTab(`https://t.me/share/url?url=${link}`)
                      }
                    >
                      <TelegramIcon />
                    </div>
                    <div
                      className="share-icon"
                      onClick={() =>
                        openInNewTab(
                          `https://www.facebook.com/sharer/sharer.php?u=${link}`
                        )
                      }
                    >
                      <FacebookIcon />
                    </div>
                  </div>
                </div>
              )}
            </span>
          </div>
          <div className="bookmark" onClick={() => navigate("/music")}>
            <span>
              <i className="uil uil-bookmark user-feed-icons">
                <MusicIcon />
              </i>
            </span>
          </div>
        </div>
        <div
          className="liked-by cursor-pointer-element-style"
          onClick={() => navigate("/discover")}
        >
          <span>
            <img src={user?.picture} alt="" />
          </span>
          <span>
            <img
              src="../../../images/defaultUserImages/user-img-6.jpg"
              alt=""
            />
          </span>
          <span>
            <img
              src="../../../images/defaultUserImages/user-img-4.jpg"
              alt=""
            />
          </span>
          <span>
            <img
              src="../../../images/defaultUserImages/user-img-5.jpg"
              alt=""
            />
          </span>
          <p>
            Used by <b>numerous</b> active users
          </p>
        </div>

        {showAdMenu && (
          <ul className="post_menu" ref={admenu}>
            <div
              onClick={() => {
                window.open("https://namronet.com/", "_blank");
              }}
            >
              <li className="hover1-m-p">
                <i className="menu-item-i-s">
                  <EcommerceIcon />
                </i>
                <div className="post_menu_text">
                  <span>Namronet Ecommerce</span>
                  <span className="menu_post_col">Find more, spend less</span>
                </div>
              </li>
            </div>
            <div className="line"></div>
            <div onClick={() => navigate("/help")}>
              <li className="hover1-m-p">
                <i className="menu-item-i-s">
                  <HelpIcon />
                </i>
                <div className="post_menu_text">
                  <span>Give feedback</span>
                  <span className="menu_post_col">
                    Chat with out support team
                  </span>
                </div>
              </li>
            </div>
          </ul>
        )}
        {isPopupVisible && <ImagesPreviewPopup onClose={handleClosePopup} />}
      </div>
    </>
  );
}
