import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PostOptionsIcon from "../icons/PostOptionsIcon";
import HeartIcon from "../icons/HeartIcon";
import CommentIcon from "../icons/CommentIcon";
import ShareIcon from "../icons/ShareIcon";
import SavePostIcon from "../icons/SavePostIcon";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import RefreshIcon from "../icons/RefreshIcon";
import "./style.css";
import ShareIcons from "./ShareIcons";
import PostMenu from "./PostMenu";
import {
  comment,
  getReacts,
  reactPost,
  savePost,
  deleteComment,
} from "../../functions/post";
import Comment from "./Comment";
import NewspaperIcon from "../icons/NewspaperIcon";
import LikedHeartSolidIcon from "../icons/LikedHeartSolidIcon";
import CreateComment from "./CreateComment";
import UnSavePostIcon from "../icons/UnSavePostIcon";
import UserPostImagesPreview from "../ImagesPreview/UserPostImagesPreview";
import BackgroundImagesPreviewPopup from "../ImagesPreview/BackgroundImagesPreviewPopup";

export default function UserFeedPost({ post, profile }) {
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const shareIconRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [reacts, setReacts] = useState([]);
  const [check, setCheck] = useState(null);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(4);
  const [checkSaved, setCheckSaved] = useState(false);
  const [comments, setComments] = useState([]);
  const [liked, setLiked] = useState(check === "like");
  const [isCreateCommentVisible, setIsCreateCommentVisible] = useState(false);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [areAllCommentsVisible, setAreAllCommentsVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    getPostReacts();
  }, [post]);

  useEffect(() => {
    setComments(post?.comments);
  }, [post]);

  const getPostReacts = async () => {
    const res = await getReacts(post._id, user.token);
    setReacts(res.reacts);
    setCheck(res.check);
    setTotal(res.total);
    setCheckSaved(res.checkSaved);
    setLiked(res.check === "like"); // Update the liked state based on the response
  };

  const reactHandler = async (type) => {
    await reactPost(post._id, type, user.token);
    if (check === type) {
      setCheck(null);
      setLiked(false); // Update the liked state
      const updatedReacts = reacts
        .map((react) =>
          react.react === type ? { ...react, count: react.count - 1 } : react
        )
        .filter((react) => react.count > 0);
      setReacts(updatedReacts);
      setTotal((prev) => prev - 1);
    } else {
      const updatedReacts = reacts
        .map((react) =>
          react.react === check ? { ...react, count: react.count - 1 } : react
        )
        .filter((react) => react.count > 0);

      if (check) setTotal((prev) => prev - 1);

      if (type) {
        const reactExists = updatedReacts.find((react) => react.react === type);
        if (reactExists) {
          reactExists.count += 1;
        } else {
          updatedReacts.push({ react: type, count: 1 });
        }
        setCheck(type);
        setLiked(true); // Update the liked state
        setTotal((prev) => prev + 1);
      } else {
        setCheck(null);
        setLiked(false); // Update the liked state
      }
      setReacts(updatedReacts);
    }
  };

  const showMore = () => {
    setCount((prev) => prev + 6);
  };

  const postRef = useRef(null);

  const navigateToProfile = () => {
    navigate(`/profile/${post.user.username}`);
  };

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const handleClickOutside = (event) => {
    if (shareIconRef.current && !shareIconRef.current.contains(event.target)) {
      setIsSharePopupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleCreateComment = () => {
    setIsCreateCommentVisible(!isCreateCommentVisible);
  };
  const toggleComments = () => {
    setAreAllCommentsVisible((prev) => !prev);
    setIsCommentsVisible((prev) => !prev);
  };

  const saveHandler = async () => {
    savePost(post._id, user.token);
    if (checkSaved) {
      setCheckSaved(false);
    } else {
      setCheckSaved(true);
    }
  };

  const handleDeleteComment = async (commentId) => {
    await deleteComment(post._id, commentId, user.token);
    setComments(comments.filter((comment) => comment._id !== commentId));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1200); // Hide message after 1 second
  };

  const handleImageClick = (images) => {
    setPreviewImages(images);
    setIsImagePreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsImagePreviewOpen(false);
    setPreviewImages([]);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="feed post-middle-p-r" ref={postRef}>
      <div className="head">
        <div className="user">
          <Link to={`/profile/${post.user.username}`} className="profile-photo">
            <img src={post.user.picture} alt="" />
          </Link>
          <div className="ingo">
            <h3 onClick={navigateToProfile} className="c-p-user-feed-post">
              {post.user.first_name} {post.user.last_name} &nbsp;
              {post.type === "profilePicture" && (
                <span className="refresh-icon-u-f-s">
                  <NewspaperIcon />
                </span>
              )}
              {post.type === "coverPicture" && (
                <span className="refresh-icon-u-f-s">
                  <NewspaperIcon />
                </span>
              )}
            </h3>
            <small>
              <Moment format="h:mm A, DD/MM/YYYY">{post.createdAt}</Moment>
            </small>
          </div>
        </div>
        <span className="more" onClick={() => setShowMenu((prev) => !prev)}>
          <i className="uil uil-ellipsis-h user-feed-icons">
            <PostOptionsIcon />
          </i>
        </span>
      </div>
      <div className="caption">
        <p>
          {post.background ? <></> : post.text}
          <span>
            {post.type === "profilePicture" && (
              <>
                &nbsp;<span className="hash-tag">@{post.user.username}</span>{" "}
                added a new profile picture
              </>
            )}
            {post.type === "coverPicture" && (
              <>
                &nbsp;<span className="hash-tag">@{post.user.username}</span>{" "}
                added a new cover picture
              </>
            )}
          </span>
        </p>
      </div>
      <div className="photo">
        <div className="photo">
          {post.background ? (
            <div className="bg-post-image-container">
              <img
                src={post.background}
                alt="Post background"
                onClick={openPopup}
              />
              <div className="post-bg-text-overlay">{post.text}</div>
            </div>
          ) : post.videoUrl ? (
            <div className="posted-video-container">
              <video src={post.videoUrl} controls />
            </div>
          ) : post.type === null ? (
            <>
              {post.images && post.images.length && (
                <div
                  className={
                    post.images.length === 1
                      ? "grid_1"
                      : post.images.length === 2
                      ? "grid_2"
                      : post.images.length === 3
                      ? "grid_3"
                      : post.images.length === 4
                      ? "grid_4"
                      : post.images.length >= 5 && "grid_5"
                  }
                >
                  {post.images.slice(0, 5).map((image, i) => (
                    <img
                      src={image.url}
                      key={i}
                      alt=""
                      className={`img-${i}`}
                      onClick={() =>
                        handleImageClick(post.images.map((img) => img.url))
                      }
                    />
                  ))}
                  {post.images.length > 5 && (
                    <div
                      className="more-pics-shadow"
                      onClick={() =>
                        handleImageClick(post.images.map((img) => img.url))
                      }
                    >
                      +{post.images.length - 5}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : post.type === "profilePicture" ? (
            <div className="post-profile-wrap">
              <div className="post-updated-bg">
                <img
                  src={post.user.cover}
                  alt=""
                  className="cover-post-p-bg"
                  onClick={() =>
                    handleImageClick([post.user.cover, post.images[0].url])
                  }
                />
              </div>
              <img
                src={post.images[0].url}
                alt=""
                className="post-updated-picture"
                onClick={() =>
                  handleImageClick([post.user.cover, post.images[0].url])
                }
              />
            </div>
          ) : (
            <div className="post-cover-wrap">
              <img
                src={post.images[0].url}
                alt=""
                onClick={() => handleImageClick([post.images[0].url])}
              />
            </div>
          )}
        </div>
      </div>
      <div className="action-buttons">
        <div className="interaction-buttons">
          <span
            className="like"
            onClick={() => {
              reactHandler("like");
              setLiked(!liked); // Toggle the liked state
            }}
          >
            <i className="uil uil-heart user-feed-icons">
              {liked ? <LikedHeartSolidIcon /> : <HeartIcon />}
            </i>
          </span>
          <span onClick={toggleCreateComment}>
            <i className="uil uil-comment-dots user-feed-icons">
              <CommentIcon />
            </i>
          </span>
          <span
            ref={shareIconRef}
            className="share-icon-wrapper"
            onClick={toggleSharePopup}
          >
            <i className="uil uil-share-alt user-feed-icons">
              <ShareIcon />
              {copied && <div className="link-c-m-s copied-o-s">Copied</div>}
            </i>
            {isSharePopupOpen && (
              <div className="share-popup">
                <ShareIcons
                  username={post.user.username}
                  link={`${process.env.REACT_APP_FRONTEND_URL}/discover/feed?postId=${post._id}`}
                  copyToClipboard={copyToClipboard}
                  copied={copied}
                />
              </div>
            )}
          </span>
        </div>
        <div className="bookmark" onClick={saveHandler}>
          <span>
            <i className="uil uil-bookmark user-feed-icons">
              {checkSaved ? <UnSavePostIcon /> : <SavePostIcon />}
            </i>
          </span>
        </div>
      </div>
      <>
        {total === 0 && (
          <div className="liked-by">
            <p>
              &#128513; Be the first to <b className="number-of-likes">like</b>
            </p>
          </div>
        )}

        {total === 1 && (
          <div className="liked-by">
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-2.jpg"
                alt=""
              />
            </span>
            <p>
              Liked by <b className="number-of-likes">1 person</b>
            </p>
          </div>
        )}

        {total === 2 && (
          <div className="liked-by">
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-3.jpg"
                alt=""
              />
            </span>
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-2.jpg"
                alt=""
              />
            </span>
            <p>
              Liked by <b className="number-of-likes">{total} people</b>
            </p>
          </div>
        )}

        {total >= 3 && (
          <div className="liked-by">
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-2.jpg"
                alt=""
              />
            </span>
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-3.jpg"
                alt=""
              />
            </span>
            <span>
              <img
                src="../../../images/defaultUserImages/user-img-6.jpg"
                alt=""
              />
            </span>
            <p>
              Liked by <b className="number-of-likes">{total} people</b>
            </p>
          </div>
        )}
      </>
      {isCreateCommentVisible && (
        <CreateComment
          user={user}
          postId={post._id}
          setComments={setComments}
          setCount={setCount}
        />
      )}
      <div className="spacing-for-comments"></div>
      {comments.length === 0 && (
        <div className="show-comments text-muted">No comments added</div>
      )}
      {comments.length === 1 && (
        <div className="show-comments text-muted" onClick={toggleComments}>
          {areAllCommentsVisible ? `Hide comment` : `View posted comment`}
        </div>
      )}
      {comments.length > 1 && (
        <div className="show-comments text-muted" onClick={toggleComments}>
          {areAllCommentsVisible
            ? `Hide comments`
            : `View all ${comments.length} comments`}
        </div>
      )}
      {isCommentsVisible && (
        <>
          {comments &&
            comments
              .sort((a, b) => {
                return new Date(b.commentAt) - new Date(a.commentAt);
              })
              .slice(0, count)
              .map((comment, i) => (
                <Comment
                  comment={comment}
                  key={i}
                  userId={user.id}
                  postUserId={post.user._id}
                  onDelete={() => handleDeleteComment(comment._id)}
                />
              ))}
          {count < comments.length && (
            <div
              className="show-comments text-muted view-more-comments-s"
              onClick={() => showMore()}
            >
              View more comments
            </div>
          )}
        </>
      )}
      {showMenu && (
        <PostMenu
          userId={user.id}
          postUserId={post.user._id}
          imagesLength={post?.images?.length}
          setShowMenu={setShowMenu}
          postId={post._id}
          token={user.token}
          checkSaved={checkSaved}
          setCheckSaved={setCheckSaved}
          images={post.images}
          postRef={postRef}
          post={post}
        />
      )}
      {isImagePreviewOpen && (
        <UserPostImagesPreview
          images={previewImages}
          onClose={handleClosePreview}
        />
      )}
      {isPopupOpen && (
        <BackgroundImagesPreviewPopup post={post} onClose={closePopup} />
      )}
    </div>
  );
}
