import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EcommerceIcon from "../icons/EcommerceIcon";
import StarIcon from "../icons/StarIcon";

const images = [
  "../../../images/imagesPreviewImages/e-commerce-example-2.jpg",
  "../../../images/imagesPreviewImages/e-commerce-example-3.jpg",
  "../../../images/imagesPreviewImages/e-commerce-example-4.jpg",
  "../../../images/imagesPreviewImages/e-commerce-example-6.jpg",
];

const texts = [
  "Shop smarter, live better, discover unbeatable deals today!",
  "Unlock savings, elevate style, explore our online store now!",
  "Find more, spend less, shop your favorite deals and save big!",
  "Discover amazing deals, embrace quality, shop with us today!",
];

export default function AdvertisementRight() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-o-c-r-s">
      <div className="advertisement-right-cntainer">
        <div className="advertisement-heading">
          <h4 className="right-heading-hover-s">Namronet</h4>
          <i
            className="r-s-i-c-pointer"
            onClick={() => {
              window.open("https://namronet.com/", "_blank");
            }}
          >
            <EcommerceIcon />
          </i>
        </div>
        <div className="advertisement-photo-c-s">
          <img src={images[currentIndex]} alt="" />
        </div>
        <div className="ad-c-t-r-s">{texts[currentIndex]}</div>
        <div className="shop-now-r-btn-c">
          <button
            onClick={() => {
              window.open("https://namronet.com/", "_blank");
            }}
          >
            <StarIcon />
            Interested
          </button>
        </div>
      </div>
    </div>
  );
}
