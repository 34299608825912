import { useRef } from "react";
import { useSelector } from "react-redux";
import useClickOutside from "../../helpers/clickOutside";
import CloseIcon from "../../components/icons/CloseIcon";

export default function OldCovers({ photos, setCoverPicture, setShow }) {
  const { user } = useSelector((state) => ({ ...state }));
  const Ref = useRef(null);
  useClickOutside(Ref, () => setShow(false));

  const coverPhotos = photos?.filter(
    (img) =>
      img.folder === `namrolink-social-media/${user.username}/cover_pictures`
  );
  const otherPhotos = photos?.filter(
    (img) =>
      img.folder !== `namrolink-social-media/${user.username}/post_images`
  );

  return (
    <div className="blur">
      <div className="postBox pictureBox-a-s" ref={Ref}>
        <div className="box_header">
          <div
            className="small_circle"
            onClick={() => {
              setShow(false);
            }}
          >
            <i className="close-i-p-s">
              <CloseIcon />
            </i>
          </div>
          <span>Cover photo</span>
        </div>
        <div className="pictureBox">
          <div className="old-pictures-wrap">
            <h4>Your cover pictures</h4>
            {coverPhotos?.length === 0 && (
              <div className="no-pic-yet-p-u-m">
                You do not have any pictures yet &#128546;
              </div>
            )}
            <div className="old-pictures">
              {coverPhotos?.map((photo) => (
                <div className="cover-img-c-s" key={photo.public_id}>
                  <img
                    src={photo.secure_url}
                    alt=""
                    onClick={() => {
                      setCoverPicture(photo.secure_url);
                      setShow(false);
                    }}
                  />
                </div>
              ))}
            </div>
            <h4 className="other-pic-p-t-s">Other pictures</h4>
            {otherPhotos?.length === 0 && (
              <div className="no-pic-yet-p-u-m">
                You do not have any pictures yet &#128546;
              </div>
            )}
            <div className="old-pictures">
              {otherPhotos?.map((photo) => (
                <div className="cover-img-c-s" key={photo.public_id}>
                  <img
                    src={photo.secure_url}
                    alt=""
                    onClick={() => {
                      setCoverPicture(photo.secure_url);
                      setShow(false);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
