import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useEffect, useState } from "react";
import ChatSidebar from "./ChatSidebar";
import MessagesPage from "./MessagesPage";
import { Link, useLocation, useParams } from "react-router-dom";
import MessagesIcon from "../../components/icons/MessagesIcon";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import io from "socket.io-client";
import CircleMenu from "../../components/navigation/CircleMenu";
import BannedMessage from "../../components/banned/BannedMessage";
import { Helmet } from "react-helmet";

export default function Chat() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state }));
  const location = useLocation();
  const [themeVisible, setThemeVisible] = useState(false);
  const { userId } = useParams();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [socketConnection, setSocketConnection] = useState(null);

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: user?.token,
      },
    });

    socket.on("onlineUser", (data) => {
      setOnlineUsers(data);
      setSocketConnection(socket);
      // Assuming you still need to handle setting the socket connection somewhere
    });

    return () => {
      socket.disconnect();
      setSocketConnection(null);
    };
  }, [user?.token]);

  const basePath = location.pathname === "/chat";
  return (
    <div>
      <Helmet>
        <title>Namrolink - Chat</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Chat"
        />
      </Helmet>
      <div className="chat-m-page-c-c-s">
        <div
          className={`chat-sidebar-container-s ${
            !basePath && "hidden-messages-component"
          } chat-sidebar-container-s-d-p`}
        >
          <ChatSidebar
            onUserThemeClick={() => handleThemeVisibility(true)}
            onlineUsers={onlineUsers}
            socketConnection={socketConnection}
            setSocketConnection={setSocketConnection}
          />
        </div>
        <div className={`${basePath && "hidden-messages-component"}`}>
          <MessagesPage
            socketConnection={socketConnection}
            setSocketConnection={setSocketConnection}
          />
        </div>
        <div
          className={`messages-home-u-d-s ${
            !basePath ? "hidden-messages-component" : "messages-home-u-d-s-lg"
          }`}
        >
          <div className="messages-logo-icon-h">
            <MessagesIcon />
            <div>
              <span>Namrolink</span> chat
            </div>
          </div>
          <p className="messages-h-u-u-s">Select a user to send a message</p>
          <BannedMessage />
        </div>
      </div>
      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
      <div className="extras-options-circle-chat-page">
        <CircleMenu />
      </div>
    </div>
  );
}
