export default function ArrowBadgeDown({ className = "w-6 h-6" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-badge-down"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M17 13v-6l-5 4l-5 -4v6l5 4z" />
    </svg>
  );
}
