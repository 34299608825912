import FacebookIcon from "../../components/icons/FacebookIcon";
import JobIcon from "../../components/icons/JobIcon";
import LocationIcon from "../../components/icons/LocationIcon";
import MessagesIcon from "../../components/icons/MessagesIcon";
import PhoneIcon from "../../components/icons/PhoneIcon";
import TelegramIcon from "../../components/icons/TelegramIcon";
import WebsiteIcon from "../../components/icons/WebsiteIcon";
import WhatsAppIcon from "../../components/icons/WhatsAppIcon";
import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from "../icons/ArrowLeftIcon";
import { useState } from "react";

export default function DeveloperProfileCard() {
  const navigate = useNavigate();
  const [isDeveloperBackgroundImage, setIsDeveloperBackgroundImage] =
    useState(true);

  const toggleDeveloperBackgroundImage = () => {
    setIsDeveloperBackgroundImage(!isDeveloperBackgroundImage);
  };

  return (
    <div>
      <style jsx>{`
        ::-webkit-scrollbar {
          display: none;
        }
        .my-developer-card-holder {
          width: 100%;
          min-height: 100vh;
          box-sizing: border-box;
        }
        .my-outer-profile-card-c {
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          letter-spacing: 0.05em;
          line-height: 1.5;
          color: var(--color-dark);
          background-size: cover;
          display: flex;
          justify-items: center;
          justify-content: center;
        }

        .profile-card-inner {
          display: flex;
          margin-top: 8rem;
          margin-bottom: 8rem;
          flex-wrap: wrap;
          align-items: center;
          max-width: 56rem;
          height: auto;

          @media (min-width: 1024px) {
            margin-top: 0;
            margin-bottom: 0;
            height: 100vh;
          }
        }

        .profile-id-sec {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          border-radius: 0.5rem;
          width: 100%;
          background-color: var(--color-white);
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

          @media (min-width: 1024px) {
            margin-left: 0;
            margin-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            width: 60%;
          }
        }

        .outer-profile-img-c {
          padding: 1rem;
          text-align: center;

          @media (min-width: 768px) {
            padding: 3rem;
          }
          @media (min-width: 1024px) {
            text-align: left;
          }
        }

        .img-profile-mobile-v {
          display: block;
          margin-top: -4rem;
          border-radius: 9999px;
          width: 12rem;
          height: 12rem;
          background-position: center;
          background-size: cover;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 10px 10px -5px rgba(0, 0, 0, 0.04);

          @media (min-width: 1024px) {
            display: none;
          }
        }

        .profile-card-name-part {
          padding-top: 2rem;
          font-size: 1.875rem;
          line-height: 2.25rem;
          font-weight: 700;

          @media (min-width: 1024px) {
            padding-top: 0;
          }
        }

        .below-pr-c-name {
          padding-top: 0.75rem;
          border-bottom-width: 2px;
          border-color: #10b981;
          width: 80%;
          opacity: 0.25;

          @media (min-width: 1024px) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        .p-below-p-e-div {
          display: flex;
          padding-top: 1rem;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;

          @media (min-width: 1024px) {
            justify-content: flex-start;
          }
        }

        .p-below-p-e-div svg {
          padding-right: 1rem;
          height: 1rem;
          color: var(--color-dark);
        }

        .profile-card-loaction {
          display: flex;
          padding-top: 0.5rem;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          line-height: 1rem;
          color: var(--color-gray);

          @media (min-width: 1024px) {
            justify-content: flex-start;
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }

        .profile-card-loaction svg {
          padding-right: 1rem;
          height: 1rem;
          color: var(--color-dark);
        }
        .short-profile-about-desc {
          padding-top: 2rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        .text-below-p-desc {
          padding-bottom: 2rem;
          padding-top: 3rem;
        }

        .p-card-g-i-t {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          border-radius: 9999px;
          font-weight: 700;
          color: #ffffff;
          background-color: var(--color-primary);
          border: none;
        }
        .p-card-g-i-t:hover {
          color: var(--color-dark);
          background-color: var(--color-light);
        }

        .profile-c-social-container {
          display: flex;
          padding-bottom: 4rem;
          margin-top: 1.5rem;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          width: 80%;

          @media (min-width: 1024px) {
            padding-bottom: 0;
            width: 100%;
          }
        }

        .profile-card-s-link svg {
          height: 1.5rem;
          color: var(--color-gray);
        }
        .profile-card-s-link svg:hover {
          color: var(--color-primary);
        }
        .small-p-title-mb {
          margin-bottom: 10px;
        }

        .img-profile-col-div {
          @media (min-width: 1024px) {
            width: 40%;
          }
        }

        .img-profile-col-div img {
          @media (min-width: 1024px) {
            width: 100%;
          }
        }

        .big-profile-img-f-s {
          display: none;
          border-radius: 0;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
          @media (min-width: 1024px) {
            display: block;
            border-radius: 0.5rem;
          }
        }
        .img-profile-mobile-v {
          margin-left: auto;
          margin-right: auto;
        }

        .social-p-center-e {
          display: flex;
          justify-content: center;
          align-content: center;
          gap: 20px;
          margin-left: 10px;
        }
        .p-pinned-t-item {
          position: absolute;
          top: 0;
          right: 0;
          padding: 1rem;
          height: 3rem;
        }
        .top-profile-c-button {
          border-radius: 10px;
          padding: 5px;
          background-color: var(--color-white);
          color: var(--color-dark);
          display: flex;
          align-items: center;
          border: none;
        }
        .top-profile-c-button svg {
          height: 1rem;
          width: 1rem;
        }
      `}</style>
      <div
        className="my-outer-profile-card-c my-developer-card-holder"
        style={{
          backgroundImage: isDeveloperBackgroundImage
            ? "url('../../backgrounds/bg-developer.jpg')"
            : "url('../../backgrounds/bg-developer-other.jpg')",
        }}
        onClick={toggleDeveloperBackgroundImage}
      >
        <div className="profile-card-inner">
          <div id="profile" className="profile-id-sec">
            <div className="outer-profile-img-c">
              <div
                className="img-profile-mobile-v"
                style={{
                  backgroundImage:
                    "url('../../developer/images/developer-img.jpg')",
                }}
              ></div>
              <p className="profile-card-loaction small-p-title-mb">
                Namrolink developer
              </p>
              <h1 className="profile-card-name-part">Sayiga Norman Haq</h1>
              <div className="below-pr-c-name"></div>
              <p className="p-below-p-e-div">
                <JobIcon /> Software developer
              </p>
              <p className="profile-card-loaction">
                <LocationIcon /> Kampala, Uganda
              </p>
              <p className="short-profile-about-desc">
                I am a dedicated and approachable software developer, known for
                delivering innovative and high-quality solutions.
              </p>

              <div className="text-below-p-desc">
                <button
                  className="p-card-g-i-t"
                  onClick={() => window.open("/developer", "_blank")}
                >
                  Get in touch
                </button>
              </div>

              <div className="profile-c-social-container social-p-center-e">
                <a
                  href="tel:+256789980531"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <PhoneIcon />
                </a>
                <a
                  href="https://wa.me/256789980531"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <WhatsAppIcon />
                </a>
                <a
                  href="https://t.me/SayigaNormanHaq"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <TelegramIcon />
                </a>
                <a
                  href="https://www.facebook.com/Sayiga.Norman"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.namrolink.com/developer"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <WebsiteIcon />
                </a>
                <a
                  href="https://www.namrolink.com"
                  target="_blank"
                  className="profile-card-s-link"
                >
                  <MessagesIcon />
                </a>
              </div>
            </div>
          </div>

          <div className="img-profile-col-div">
            <img
              src="../../developer/images/developer-img.jpg"
              className="big-profile-img-f-s"
            />
          </div>

          <div className="p-pinned-t-item">
            <button
              className="top-profile-c-button"
              type="button"
              onClick={() => navigate("/")}
            >
              <ArrowIcon />
              &nbsp;Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
