import axios from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { profileReducer } from "../../functions/reducers";
import Header from "../../components/header";
import "./style.css";
import Cover from "./Cover";
import ProfielPictureInfos from "./ProfielPictureInfos";
import Photos from "./Photos";
import Friends from "./Friends";
import Intro from "../../components/intro";
import { useMediaQuery } from "react-responsive";
import CreatePostPopup from "../../components/createPostPopup";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { HashLoader } from "react-spinners";
import LeftContainer from "../../components/left/LeftContainer";
import RightContainer from "../../components/right/RightContainer";
import ThemeCustomization from "../../components/left/ThemeCustomization";
import SearchIcon from "../../components/icons/SearchIcon";
import CreatePostContainer from "../../components/middle/CreatePostContainer";
import UserFeedPost from "../../components/post/UserFeedPost";
import ExploreSearchBar from "../../components/middle/ExploreSearchBar";
import AdvertisementDefault from "../../components/middle/AdvertisementDefault";
import BottomMenu from "../../components/navigation/BottomMenu";
import CircleMenu from "../../components/navigation/CircleMenu";
import { Helmet } from "react-helmet";

export default function Profile({ getAllPosts }) {
  const [visible, setVisible] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [themeVisible, setThemeVisible] = useState(false);
  const handleMenuItemClick = (id) => {
    setActiveMenuItem(id);
  };

  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => ({ ...state }));
  const [photos, setPhotos] = useState({});

  useEffect(() => {
    // Apply saved theme settings from local storage on initial load
    const savedFontSize = localStorage.getItem("fontSize");
    const savedPrimaryColor = localStorage.getItem("primaryColor");
    const savedButtonColor = localStorage.getItem("buttonColor");
    const savedBackground = JSON.parse(localStorage.getItem("background"));

    if (savedFontSize) {
      document.documentElement.style.fontSize = savedFontSize;
    }
    if (savedPrimaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color-hue",
        savedPrimaryColor
      );
    }
    if (savedButtonColor) {
      document.documentElement.style.setProperty(
        "--btn-primary-color-hue",
        savedButtonColor
      );
    }
    if (savedBackground) {
      savedBackground.forEach(([variable, value]) => {
        document.documentElement.style.setProperty(variable, value);
      });
    }

    if (user) {
      getAllPosts();
    }
  }, [user]);

  const handleThemeVisibility = (visible) => {
    setThemeVisible(visible);
  };

  var userName = username === undefined ? user.username : username;
  const [{ loading, error, profile }, dispatch] = useReducer(profileReducer, {
    loading: false,
    profile: {},
    error: "",
  });
  useEffect(() => {
    getProfile();
  }, [userName]);
  useEffect(() => {
    setOthername(profile?.details?.otherName);
  }, [profile]);

  var visitor = userName === user.username ? false : true;
  const [othername, setOthername] = useState();
  const path = `namrolink-social-media/${userName}/*`;
  const max = 30;
  const sort = "desc";

  const getProfile = async () => {
    try {
      dispatch({
        type: "PROFILE_REQUEST",
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getProfile/${userName}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (data.ok === false) {
        navigate("/profile");
      } else {
        try {
          const images = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/listImages`,
            { path, sort, max },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          setPhotos(images.data);
        } catch (error) {
          console.log(error);
        }
        dispatch({
          type: "PROFILE_SUCCESS",
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: "PROFILE_ERROR",
        payload: error.response.data.message,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Namrolink - Profile</title>
        <link rel="icon" href="../../../icons/icon.png" />
        <meta
          name="description"
          content="Connect with people who matter most, share your favorite moments and create lasting memories on a platform designed to bring us all closer together."
        />
        <meta name="author" content="Sayiga Norman Haq" />
        <meta
          name="keywords"
          content="Namrolink, Social media, Togetherness, chatting, Sayiga Norman Haq, Share, BITC, Community, Ugandan connections, Profile"
        />
      </Helmet>
      {visible && (
        <CreatePostPopup
          user={user}
          setVisible={setVisible}
          posts={profile?.posts}
          dispatch={dispatch}
          profile
        />
      )}
      <Header activeMenuItem={activeMenuItem} setVisible={setVisible} />
      <div className="main">
        <div className="element_container">
          <div className="left-container-section">
            <LeftContainer
              onMenuItemClick={handleMenuItemClick}
              onThemeClick={() => handleThemeVisibility(true)}
              setVisible={setVisible}
            />
          </div>
          <div className="middle-container-section">
            <div className="middle">
              <div className="middle activeitem">
                <ExploreSearchBar activeMenuItem={activeMenuItem} />
              </div>
              <div>
                <div className="feeds">
                  <div className="feed profile-t-f-m-s">
                    <div className="photo">
                      <div className="photo">
                        {loading ? (
                          <>
                            <div className="profile-cover-loading-s-s">
                              <Skeleton
                                height="200px"
                                width="100%"
                                containerClassName="avatar-skeleton"
                                style={{ borderRadius: "16px" }}
                              />
                            </div>
                            <div className="profile-image-wrap">
                              <div className="profile-w-left">
                                <div className="profile-w-img">
                                  <div className="profile-w-bg profile-img-loading-s-s">
                                    <Skeleton
                                      circle
                                      height="145px"
                                      width="140px"
                                      containerClassName="avatar-skeleton"
                                      style={{
                                        backgroundSize: "cover",
                                        borderRadius: "16px",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="profile-w-col">
                                  <div className="profile-name">
                                    <Skeleton
                                      height="35px"
                                      width="215px"
                                      containerClassName="avatar-skeleton"
                                      style={{
                                        backgroundSize: "cover",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </div>
                                  <div className="profile-page-count-p-s">
                                    <Skeleton
                                      height="20px"
                                      width="100px"
                                      containerClassName="avatar-skeleton"
                                      style={{
                                        borderRadius: "6px",
                                        marginTop: "9px",
                                      }}
                                    />
                                  </div>
                                  <div className="profile_friend_imgs p-f-i-loading-s-s">
                                    {Array.from(
                                      new Array(6),
                                      (val, i) => i + 1
                                    ).map((id, i) => (
                                      <div key={i}>
                                        <Skeleton
                                          circle
                                          height="32px"
                                          width="32px"
                                          containerClassName="avatar-skeleton"
                                          style={{
                                            transform: `translateX(${
                                              -i * 7
                                            }px)`,
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div className="profile-w-right">
                                    <div className="profile-c-buttons">
                                      <div className="p-p-a-btns-loading-s-s">
                                        <Skeleton
                                          height="100px"
                                          width="100px"
                                          containerClassName="avatar-skeleton"
                                        />
                                      </div>
                                      <div className="p-p-a-btns-loading-s-s">
                                        <Skeleton
                                          height="100px"
                                          width="100px"
                                          containerClassName="avatar-skeleton"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <Cover
                              cover={profile.cover}
                              visitor={visitor}
                              photos={photos.resources}
                              profile={profile}
                            />
                            <ProfielPictureInfos
                              profile={profile}
                              visitor={visitor}
                              photos={photos.resources}
                              othername={othername}
                              loading={loading}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <div>
                        <h4 className="outer-f-t-s">Intro</h4>
                        <div className="feed">
                          <div className="profile-d-loader-c-s">
                            <HashLoader color="#1876f2" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="outer-f-t-s">Photos</h4>
                        <div className="feed">
                          <div className="profile-d-loader-c-s">
                            <HashLoader color="#1876f2" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="outer-f-t-s">Friends</h4>
                        <div className="feed">
                          <div className="profile-d-loader-c-s">
                            <HashLoader color="#1876f2" />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Intro
                        detailss={profile.details}
                        visitor={visitor}
                        setOthername={setOthername}
                      />
                      <Photos
                        username={userName}
                        token={user.token}
                        photos={photos}
                      />
                      <Friends friends={profile.friends} />
                    </>
                  )}
                  {!visitor && (
                    <CreatePostContainer profile setVisible={setVisible} />
                  )}
                  {!visitor && <h4 className="p-y-m-k-h">Posts</h4>}
                  {profile.posts && profile.posts.length ? (
                    <div>{visitor && <h4 className="p-y-m-k-h">Posts</h4>}</div>
                  ) : (
                    ""
                  )}
                  {loading ? (
                    <div className="profile-posts-loader-c">
                      <HashLoader color="#1876f2" />
                    </div>
                  ) : (
                    <>
                      {profile.posts && profile.posts.length ? (
                        profile.posts.map((post, i) => (
                          <UserFeedPost post={post} key={i} profile />
                        ))
                      ) : (
                        <div>
                          {!visitor && <AdvertisementDefault />}
                          <div className="no-posts">
                            No posts available &#128532;
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right-container-section">
            <RightContainer />
          </div>
        </div>
      </div>
      {themeVisible && (
        <ThemeCustomization onClose={() => handleThemeVisibility(false)} />
      )}
      <div className="extras-options-home-page">
        <BottomMenu />
      </div>
      <div className="extras-options-circle-home-page">
        <CircleMenu />
      </div>
    </div>
  );
}
