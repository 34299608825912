import DeleteIcon from "../icons/DeleteIcon";
import DownloadIcon from "../icons/DownloadIcon";
import ReportIcon from "../icons/ReportIcon";
import SavePostIcon from "../icons/SavePostIcon";
import UnSavePostIcon from "../icons/UnSavePostIcon";

const iconComponents = {
  DeleteIcon,
  DownloadIcon,
  ReportIcon,
  SavePostIcon,
  UnSavePostIcon,
};

export default function MenuItem({ icon, title, subtitle, img }) {
  const IconComponent = iconComponents[icon];
  return (
    <li className="hover1-m-p">
      {img ? (
        <img src={img} alt="" />
      ) : (
        <i className="menu-item-i-s">
          <IconComponent />
        </i>
      )}
      <div className="post_menu_text">
        <span>{title}</span>
        {subtitle && <span className="menu_post_col">{subtitle}</span>}
      </div>
    </li>
  );
}
