import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function RightUserInfo() {
  const { user } = useSelector((user) => ({ ...user }));
  const navigate = useNavigate();
  return (
    <div className="friend-requests">
      <h4>Hey there!</h4>
      <div className="requests">
        <div className="request">
          <div className="info">
            <div className="profile-photo">
              <img src={user?.picture} />
            </div>
            <div>
              <h5>
                {user?.first_name} {user?.last_name}
              </h5>
              <p className="text-muted">
                <small>@{user?.username}</small>
              </p>
            </div>
          </div>
          <div className="action centered-action-btns">
            <button
              className="btn btn-primary btn-p-shrinker"
              onClick={() => navigate("/profile")}
            >
              Info
            </button>
            <button
              className="btn btn-p-shrinker"
              onClick={() => navigate("/profile")}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
