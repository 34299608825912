import "./style.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlockIcon from "../icons/BlockIcon";

export default function BannedMessage() {
  const { user } = useSelector((user) => ({ ...user }));
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingUserDetails(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/getUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoadingUserDetails(false);
      }
    };

    fetchUserDetails();
  }, [user.token]);
  return (
    <>
      {userDetails?.banned === true && (
        <div>
          <div className="blur">
            <div className="banned-message-container">
              <i className="block-icon-banned-c popup-m-img-p-o-a-s">
                <BlockIcon />
              </i>
              <h1 className="banned-msg-u-heading">
                Hello {user?.first_name} {user?.last_name}
              </h1>
              <div className="banned-msg-texts-b-heading">
                Your account has been blocked and you will no longer be able to
                use Namrolink social media
              </div>
              <p className="bottom-banned-explanations-msg">
                This could be due to performing malicious actions or being
                flagged as dangerous, immoral, violent or rude by other
                Namrolink users. Other factors could be doing disallowed acts
                like posting explicit content, harassment, profanity, hate,
                racism, amongst others.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
